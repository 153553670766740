import gql from 'graphql-tag';
import {
  TreatmentDefaultFragment,
  TreatmentTreeFragment,
  TreatmentStagesFragment,
  TreatmentStageFragment,
  TreatmentForAddUpdateFragment,
  ResponseShareTreatmentResFragment,
  CommonTreatmentFragment,
} from './fragments';

//get treatment for list tree
export const GET_TREATMENTS_TREE = gql`
  query GetTreatmentsTree($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...TreatmentTree
      }
    }
  }
  ${TreatmentTreeFragment}
`;

export const GET_TREATMENTS_FOR_ADD_TREE = gql`
  query GetTreatmentsForAddTree($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...TreatmentForAddUpdate
        isPrivate
      }
    }
  }
  ${TreatmentForAddUpdateFragment}
`;

export const UPDATE_TREATMENT_TREE = gql`
  mutation UpdateTreatmentTree($params: UpdateTreatmentTreeInput!) {
    updateTreatmentTree(params: $params)
  }
`;

export const COPY_TREATMENT_FOR_SUPER_ADMIN = gql`
  mutation CopyTreatment4SuperAdmin($params: CopyTreatmentInput!) {
    copyTreatment4SuperAdmin(params: $params)
  }
`;

export const BULK_UPDATE_TREATMENT_ORDER = gql`
  mutation BulkUpdateTreatmentOrder($params: [UpdateTreatmentOrderInput!]!) {
    bulkUpdateTreatmentOrder(params: $params)
  }
`;

//get data included treatment detail and list parent for parent select box
export const GET_DATA_FOR_UPDATE_TREE = gql`
  query GetDataForUpdateTree(
    $paramsTreatment: GetTreatmentsForUpdateInput!
    $paramsDetail: GetTreatmentInput!
    $paramsGroup: GetTreatmentRegimenInput!
    $paramsTaskType: GetFollowUpTypesInput!
    $getDataForTreatment: Boolean!
  ) {
    getTreatmentRegimen(params: $paramsGroup)
      @include(if: $getDataForTreatment) {
      nodes {
        _id
        name: regimenName
      }
    }
    getFollowUpTypes(params: $paramsTaskType)
      @include(if: $getDataForTreatment) {
      nodes {
        _id
        name
      }
    }
    getTreatmentsForUpdate(params: $paramsTreatment) {
      nodes {
        ...TreatmentForAddUpdate
        isPrivate
      }
    }
    getTreatment(params: $paramsDetail) {
      ...TreatmentDefault
      content
      isPrivate
      parentId
      surveyData
      label
      isReference
      checklist {
        idChecklist: _id
        name
        ownerId
      }
      parent {
        ...TreatmentForAddUpdate
      }
      treatmentStages {
        ...TreatmentStages
      }
      isHideOnHomePage
      slug
      isDefault
      hideMainMedia
      emrObx
      smsMessage
      tags {
        tag {
          _id
        }
        name
        isAssignTag
      }
    }
  }
  ${TreatmentDefaultFragment}
  ${TreatmentStagesFragment}
  ${TreatmentForAddUpdateFragment}
`;

export const DELETE_TREATMENT_STAGE = gql`
  mutation DeleteTreatmentStage($params: DeleteTreatmentStageInput!) {
    deleteTreatmentStage(params: $params)
  }
`;

export const UPDATE_TREATMENT_STAGE = gql`
  mutation UpdateTreatmentStage($params: UpdateTreatmentStageInput!) {
    updateTreatmentStage(params: $params) {
      ...TreatmentStage
    }
  }
  ${TreatmentStageFragment}
`;

export const CREATE_TREATMENT_STAGE = gql`
  mutation CreateTreatmentStages($params: CreateTreatmentStagesInput!) {
    createTreatmentStages(params: $params) {
      ...TreatmentStage
    }
  }
  ${TreatmentStageFragment}
`;

export const UPDATE_TREATMENT_STAGES_EMBED = gql`
  mutation UpdateTreatmentStagesEmbed(
    $params: UpdateTreatmentStagesEmbedInput!
  ) {
    updateTreatmentStagesEmbed(params: $params) {
      _id
    }
  }
`;

export const SHARE_ASSIGN_TREATMENT = gql`
  mutation ShareAssignTreatment($params: ShareTreatmentInput!) {
    shareAssignTreatment(params: $params) {
      ...ResponseShareTreatmentRes
    }
  }
  ${ResponseShareTreatmentResFragment}
`;

export const LINK_TREATMENT = gql`
  mutation LinkTreatment($params: LinkTreatmentInput!) {
    linkTreatment(params: $params) {
      ...TreatmentStage
    }
  }
  ${TreatmentStageFragment}
`;

export const UNLINK_TREATMENT = gql`
  mutation UnLinkTreatment($params: UnlinkTreatmentInput!) {
    unlinkTreatment(params: $params) {
      ...TreatmentStage
    }
  }
  ${TreatmentStageFragment}
`;

export const UPLOAD_SURVEY_IMAGE = gql`
  mutation UploadSurveyImage($file: Upload!) {
    uploadSurveyImage(file: $file) {
      _id
      name
      thumbNailsUrls
    }
  }
`;

export const GET_TREATMENT_FOR_REFERENCE = gql`
  query GetTreatmentForReference($params: GetTreatmentsForUpdateInput!) {
    getTreatmentsForUpdate(params: $params) {
      nodes {
        ...TreatmentForAddUpdate
      }
    }
  }
  ${TreatmentForAddUpdateFragment}
`;

export const REPLACE_TREATMENT = gql`
  mutation ReplaceTreatment($params: ReplaceTreatmentInput!) {
    replaceTreatment(params: $params)
  }
`;

export const GET_UNDELETED_TREATMENTS = gql`
  query GetUndeletedTreatments($params: GetTreatmentsForUpdateInput!) {
    getUndeletedTreatments(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const HANDLE_FAVORITE_TREATMENT = gql`
  mutation HandleFavoriteTreatment($params: UpdateToFavoriteInput!) {
    handleFavoriteTreatment(params: $params)
  }
`;

export const SET_DEFAULT_TREATMENT = gql`
  mutation SetDefaultTreatment($treatmentId: ObjectId!) {
    setDefaultTreatment(treatmentId: $treatmentId)
  }
`;

export const UPDATE_TAG_FOR_TREATMENT_TREE = gql`
  mutation updateTagForTreatmentTree($params: UpdateTagForTreatmentTreeInput!) {
    updateTagForTreatmentTree(params: $params)
  }
`;
