import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
} from '@material-ui/core';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import set from 'date-fns/set';
import { LoadingTable } from 'components';
import { ScheduleConfigEmbedded, TreatmentType } from 'types.d';
import { sortRegimen } from 'share/utils';
import TableListItem from 'modules/setting_groups/components/TableListItem';
import { EventClickArg } from '@fullcalendar/react';

type Props = {
  currentEvent?: EventClickArg;
  loadingGetGroup: boolean;
  isShowExpand?: boolean;
  watchSendingDate?: string | null;
  watchSendingTime?: string | null;
  watchGroup?: any;
};

export const TableListGroup: React.FC<Props> = ({
  currentEvent,
  loadingGetGroup,
  isShowExpand,
  watchGroup,
  watchSendingTime,
  watchSendingDate,
}) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader style={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Treatment or Survey</TableCell>
              <TableCell>Send after</TableCell>
              <TableCell>Sending date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingGetGroup && <LoadingTable colSpan={3} />}
            {!loadingGetGroup &&
              sortRegimen(watchGroup)?.map(item => {
                return (
                  <TableListItem
                    key={item?.treatment?._id}
                    item={{
                      _id: item?.treatment?._id,
                      task: item?.task?._id,
                      bgColor: item?.task?.color,
                      name: item?.treatment?.name || item?.task?.name || 'N/A',
                      type: item?.treatment?.type || TreatmentType.Treatment,
                      repeat: 'notReapeat',
                      custom: item?.scheduleConfig as ScheduleConfigEmbedded,
                      numberOfDays: item?.specifiedTime,
                      after: item?.specifiedType,
                      time: item?.time
                        ? {
                            hour: item.time?.hour || 0,
                            minute: item.time?.minute || 0,
                          }
                        : undefined,
                    }}
                    isSendingGroup
                    sendingDate={
                      isShowExpand
                        ? new Date(
                            set(new Date(watchSendingDate as string), {
                              hours: getHours(
                                new Date(watchSendingTime as string),
                              ),
                              minutes: getMinutes(
                                new Date(watchSendingTime as string),
                              ),
                            }),
                          ).toISOString()
                        : new Date().toISOString()
                    }
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(TableListGroup);
