import React, { useState, useContext, useMemo } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
  Typography,
  Grid,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import {
  useMutationCustom,
  useFormPhone,
  useOnlyChecked,
  useInputMentions,
  useCustomPermission,
} from 'hooks';
import {
  GET_TREATMENTS_NOT_YET_ASSIGNED,
  SHARE_MULTI_TREATMENT_TO_PATIENT,
} from 'gql';
import {
  GetTreatmentsNotYetAssignedQuery,
  GetTreatmentsNotYetAssignedQueryVariables,
  CommonTreatmentFragment,
  ShareMultiTreatmentToPatientMutation,
  ShareMultiTreatmentToPatientMutationVariables,
  PatientFragment,
} from 'types.d';
import {
  DialogButton,
  ButtonLoading,
  DialogTitleClose,
  TextfieldSelect,
} from 'components';
import { PatientDetailContext } from 'share/context';
import EmailIcon from '@material-ui/icons/Email';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from '@apollo/react-hooks';
import { defaultChecked } from 'share/utils';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  patientDetail?: PatientFragment;
};

enum AssignBy {
  TREATMENT = 'treatment',
  FAVORITES = 'favorites',
}

export const DialogAssignTreatment: React.FC<Props> = ({
  open,
  toogleDialog,
  patientDetail,
}) => {
  const [selectedTreatments, setSelectedTreatments] = useState<
    CommonTreatmentFragment[]
  >([]);

  const patientDetailContext = useContext(PatientDetailContext);

  const { idMe } = useCustomPermission();

  const {
    FormPhoneComponent,
    formPhoneComponentProps,
    isDisplayPhone,
    setIsDisplayPhone,
    ButtonUpdatePhone,
  } = useFormPhone();

  const [assignBy, setAssignBy] = useState<AssignBy>(AssignBy.TREATMENT);

  const { enqueueSnackbar } = useSnackbar();

  const {
    valueCustom,
    defaulInputMentionsProps,
    InputMentions,
  } = useInputMentions(true, '', selectedTreatments);

  const {
    checked,
    setChecked,
    defaulCheckedProps,
    OnlyChecked,
  } = useOnlyChecked();

  const [getListTreatment, { data, loading }] = useLazyQuery<
    GetTreatmentsNotYetAssignedQuery,
    GetTreatmentsNotYetAssignedQueryVariables
  >(GET_TREATMENTS_NOT_YET_ASSIGNED, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    shareMultiTreatment,
    { loading: loadingshareMultiTreatment },
  ] = useMutationCustom<
    ShareMultiTreatmentToPatientMutation,
    ShareMultiTreatmentToPatientMutationVariables
  >({
    refetchQueries: patientDetailContext?.refetchQueriesGetPatient,
    api: SHARE_MULTI_TREATMENT_TO_PATIENT,
    textSuccess: 'Sent successfully',
    callbackSuccess: data => {
      setSelectedTreatments([]);
      toogleDialog(false);
    },
    callbackError: () => {
      setIsDisplayPhone(true);
    },
  });

  const handleSave = () => {
    if (
      !patientDetail?.owner?.phones?.find(item => item.default) &&
      checked.includes('sendSMS')
    ) {
      setIsDisplayPhone(true);
      return;
    }
    customEvent("assignTreatmentToPatientOnPD", "NAVIGATOR")
    shareMultiTreatment({
      variables: {
        params: {
          patientId: patientDetailContext?.patient.owner?.idOwner,
          treatmentIds: selectedTreatments.map(item => item._id) || [],
          sendSMS: checked.includes('sendSMS'),
          sendEmail: checked.includes('sendEmail'),
          smsMessage: valueCustom,
        },
      },
    });
  };

  const enterDialog = () => {
    getListTreatment({
      variables: {
        params: {
          patientId: patientDetailContext?.patient.owner?.idOwner,
          sortByOrder: { name: 1 },
        },
      },
    });
    setSelectedTreatments([]);
    setChecked(
      defaultChecked(
        patientDetailContext?.patient?.owner?.patientConfig?.contactConfig
          ?.method || [],
      ),
    );
  };

  const changeTypeAssign = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssignBy((event.target as HTMLInputElement).value as AssignBy);
    const paramsTreatment = {
      patientId: patientDetailContext?.patient.owner?.idOwner,
      sortByOrder: { name: 1 },
    };
    getListTreatment({
      variables: {
        params:
          (event.target as HTMLInputElement).value === AssignBy.FAVORITES
            ? { ...paramsTreatment, favorites: idMe }
            : { ...paramsTreatment },
      },
    });
  };

  const isDisabledSend = useMemo(() => {
    return selectedTreatments.length === 0;
  }, [selectedTreatments.length]);
  return (
    <Dialog open={open} onEnter={enterDialog} maxWidth="sm" fullWidth>
      <DialogTitleClose
        title={isDisplayPhone ? 'Set Default Phone' : 'Assign Treatments'}
        onClose={() => toogleDialog(false)}
      />
      <DialogContent>
        <Grid container spacing={2}>
          {isDisplayPhone && (
            <FormPhoneComponent {...formPhoneComponentProps} />
          )}
          {!isDisplayPhone && (
            <>
              <Grid item xs={3}>
                <TextfieldSelect
                  label="By"
                  name="by"
                  small
                  callbackChangeValue={changeTypeAssign}
                  value={assignBy}
                >
                  <MenuItem value={AssignBy.TREATMENT}>Treatment</MenuItem>
                  <MenuItem value={AssignBy.FAVORITES}>Favorites</MenuItem>
                </TextfieldSelect>
              </Grid>
              <Grid item xs={9}>
                <Autocomplete
                  value={selectedTreatments}
                  className="w-100"
                  multiple
                  onChange={(event, newValue) => {
                    if (newValue.length <= 10) {
                      setSelectedTreatments(newValue);
                      return;
                    }
                    enqueueSnackbar('Please input no more than 10 items', {
                      variant: 'error',
                    });
                  }}
                  disableCloseOnSelect
                  filterOptions={createFilterOptions({
                    matchFrom: 'any',
                    ignoreCase: true,
                    ignoreAccents: true,
                    stringify: (option: CommonTreatmentFragment) =>
                      option.name || '',
                  })}
                  options={data?.getTreatmentsNotYetAssigned || []}
                  getOptionLabel={option => option.name || ''}
                  renderOption={(option, { selected }) => {
                    return (
                      <>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          className="mr-8"
                          checked={selected}
                        />
                        <Typography variant="subtitle1">
                          {option.name}
                        </Typography>
                      </>
                    );
                  }}
                  disabled={loading}
                  loading={loading}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Treatments"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingLeft: 0 }}>
                <OnlyChecked
                  value={[
                    { value: 'sendEmail', label: 'Send Email' },
                    { value: 'sendSMS', label: 'Send SMS' },
                  ]}
                  {...defaulCheckedProps}
                />
              </Grid>
              {checked.includes('sendSMS') && (
                <InputMentions {...defaulInputMentionsProps} />
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isDisplayPhone && <ButtonUpdatePhone {...formPhoneComponentProps} />}
        {!isDisplayPhone && (
          <>
            <DialogButton isCancel onClickButton={() => toogleDialog(false)} />
            <ButtonLoading
              text="Send"
              disabled={isDisabledSend}
              Icon={<EmailIcon />}
              loading={loadingshareMultiTreatment}
              callbackClick={handleSave}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DialogAssignTreatment);
