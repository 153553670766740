import React from 'react';
import { linkClient } from 'client/client';
import { ApolloProvider } from '@apollo/react-hooks';
import {
  ME_CLIENT,
  MENU_STATUS,
  SHOW_SUB_TREATMENTS_SIDE_BAR,
  SUB_TREATMENTS_SIDE_BAR,
  BANNER_STATUS,
  SHOWING_TREATMENTS_SIDEBAR,
  SHOWING_COMMENT_FROM,
  SHOWING_MENU_ADMINISTRATION,
  SHOWING_QUICK_VIEW,
  SHOWING_MENU_SETTING,
  SHOW_STATUS_HASING_RECORD,
  SHOW_SAVE_RECORDING,
  IS_OPEN_DIALOG_STOP_TIME_RECORD,
  HOMPEPAGE_CLIENT,
} from 'gql';
import {
  checkHasSessionStorage,
  setDataSessionStorage,
  removeSessionStorage,
  getSessionStorage,
} from 'share/utils';
import { KEY_LOCAL_SKIP_BANNER } from 'CONST';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { defaultDataIdFromObject } from 'apollo-boost';
import ApolloClient from 'apollo-client';
import { SubcriptionClientContext } from 'share/context';
import { onError } from 'apollo-link-error';
import { PatientTimeRecordFragment } from 'types.d';
import {
  Phones,
  SecondaryEmails,
  Setting,
  TimezoneSetting,
} from '../gql/local/auth';
type Props = {};

enum StatusRecord {
  LOGOUT,
  REDIRECT,
}

export const ClientProvider: React.FC<Props> = ({ children }) => {
  const { splitLink, subcriptionClient } = linkClient;
  const cacheMemory = new InMemoryCache({
    dataIdFromObject(responseObject) {
      if (responseObject.__typename === 'PatientTimeRecordRes') {
        return `${(responseObject as PatientTimeRecordFragment)._id}${
          (responseObject as PatientTimeRecordFragment).patient
        }`;
      }
      if (responseObject.__typename === 'PatientNoteTypeEmbedded') {
        return `${(responseObject as any).id._id}${
          (responseObject as any).description
        }`;
      }
      return defaultDataIdFromObject(responseObject);
    },
  });
  // write cache client
  const data = {
    meClient: {
      __typename: 'meClient',
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      role: '',
      _id: '',
      organization: '',
      isRegister: false,
      patient: '',
      hasDefaultTreatment: false,
      organizationId: '',
      secondaryEmails: [],
      setting: null,
      phones: [],
      timezone: null,
    },
    menuStatus: false,
    bannerStatus:
      checkHasSessionStorage(KEY_LOCAL_SKIP_BANNER) &&
      getSessionStorage(KEY_LOCAL_SKIP_BANNER) === 'true',
    subTreatmentsSideBar: null,
    showSubTreatmentsSideBar: true,
    showingTreatmentsSidebar: null,
    showingCommentFrom: false,
    showingMenuAdministration: false,
    showingMenuSetting: false,
    showingQuickView: false,
    showStatusHasingRecord: null,
    showSaveRecording: null,
    isOpenDialogStopTimeRecord: false,
    homepageClient: {
      _id: null,
      buttonConfigurations: [],
      dynamicTreatments: [],
      footerConfigurations: [],
      organization: null,
      organizationHomepageSlug: null,
      __typename: 'homepageClient',
    },
  };
  cacheMemory.writeData({ data });

  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (
      graphQLErrors &&
      graphQLErrors[0].extensions?.code === 'UNAUTHENTICATED'
    ) {
      subcriptionClient.unsubscribeAll();
      subcriptionClient.close();
      window.location.href = '/';
    }
  });

  const client = new ApolloClient({
    cache: cacheMemory,
    connectToDevTools: true,
    link: errorLink.concat(splitLink),
    resolvers: {
      Mutation: {
        updateMeClient: (
          _,
          {
            email,
            firstName,
            lastName,
            middleName,
            role,
            _id,
            organization,
            isRegister,
            patient,
            hasDefaultTreatment,
            organizationId,
            secondaryEmails,
            setting,
            timezone,
            phones,
          }: {
            email: string;
            firstName: string;
            lastName: string;
            middleName: string;
            role: string;
            _id: string;
            organization: string;
            isRegister: boolean;
            patient: string;
            hasDefaultTreatment: boolean;
            organizationId: string;
            secondaryEmails: SecondaryEmails;
            setting: Setting;
            phones: Phones;
            timezone: TimezoneSetting;
          },
          { cache },
        ) => {
          const previous = cache.readQuery({ query: ME_CLIENT });
          const newMeClient = {
            meClient: {
              ...previous.meClient,
              email,
              firstName,
              lastName,
              middleName,
              role,
              _id,
              organization,
              isRegister,
              patient,
              hasDefaultTreatment,
              organizationId,
              secondaryEmails,
              setting,
              timezone,
              phones,
            },
          };
          cache.writeQuery({ query: ME_CLIENT, data: newMeClient });
          return null;
        },
        toogleMenuStatus: (_, variables: { status: boolean }, { cache }) => {
          cache.writeQuery({
            query: MENU_STATUS,
            data: { menuStatus: variables.status },
          });
          return null;
        },
        toogleShowSubTreatmentsSideBar: (
          _,
          variables: { input: { status: boolean } },
          { cache },
        ) => {
          cache.writeQuery({
            query: SHOW_SUB_TREATMENTS_SIDE_BAR,
            data: { showSubTreatmentsSideBar: variables.input.status },
          });
        },
        toogleBannerStatus: (_, variables: { status: boolean }, { cache }) => {
          if (variables.status) {
            setDataSessionStorage(KEY_LOCAL_SKIP_BANNER, 'true');
          } else {
            checkHasSessionStorage(KEY_LOCAL_SKIP_BANNER)
              ? setDataSessionStorage(KEY_LOCAL_SKIP_BANNER, 'false')
              : removeSessionStorage(KEY_LOCAL_SKIP_BANNER);
          }
          cache.writeQuery({
            query: BANNER_STATUS,
            data: { bannerStatus: variables.status },
          });
          return null;
        },
        addSubTreatmentsSideBar: (
          _,
          variables: {
            subTreatments:
              | {
                  id: string;
                  name: string;
                  subSectionIds: string[];
                }[]
              | null;
          },
          { cache },
        ) => {
          const subTreatmentsSideBar = variables.subTreatments || [];
          cache.writeQuery({
            query: SUB_TREATMENTS_SIDE_BAR,
            data: {
              subTreatmentsSideBar,
            },
          });
          return null;
        },
        addShowingTreatmentSidebar: (
          _,
          variables: {
            input: string;
          },
          { cache },
        ) => {
          const previous = cache.readQuery({
            query: SHOWING_TREATMENTS_SIDEBAR,
          });

          let newListTreatments: string[] | null = [];
          if (!variables.input) {
            newListTreatments = null;
          } else {
            if (
              (previous.showingTreatmentsSidebar || []).includes(
                variables.input,
              )
            ) {
              newListTreatments = [
                ...((previous.showingTreatmentsSidebar ||
                  []) as string[]).filter(item => item !== variables.input),
              ];
            } else {
              newListTreatments = [
                ...(previous.showingTreatmentsSidebar || []),
                variables.input,
              ];
            }
          }

          cache.writeQuery({
            query: SHOWING_TREATMENTS_SIDEBAR,
            data: {
              showingTreatmentsSidebar: newListTreatments,
            },
          });

          return null;
        },
        toogleShowCommentFrom: (
          _,
          variables: { input: boolean },
          { cache },
        ) => {
          cache.writeQuery({
            query: SHOWING_COMMENT_FROM,
            data: { showingCommentFrom: variables.input },
          });
        },
        toogleShowMenuAdministration: (
          _,
          variables: { input: boolean },
          { cache },
        ) => {
          cache.writeQuery({
            query: SHOWING_MENU_ADMINISTRATION,
            data: { showingMenuAdministration: variables.input },
          });
        },
        toogleShowMenuSetting: (
          _,
          variables: { input: boolean },
          { cache },
        ) => {
          cache.writeQuery({
            query: SHOWING_MENU_SETTING,
            data: { showingMenuSetting: variables.input },
          });
        },
        toogleShowQuickView: (_, variables: { input: boolean }, { cache }) => {
          cache.writeQuery({
            query: SHOWING_QUICK_VIEW,
            data: { showingQuickView: variables.input },
          });
        },
        toogleStatusHasingRecord: (
          _,
          variables: { input: { status: StatusRecord } },
          { cache },
        ) => {
          cache.writeQuery({
            query: SHOW_STATUS_HASING_RECORD,
            data: { showStatusHasingRecord: variables.input.status },
          });
        },
        toogleSaveRecording: (
          _,
          variables: {
            input: {
              _id: String;
              startedAt: String;
              stoppedAt: String;
              idPatient: String;
              idCptCode: String;
            };
          },
          { cache },
        ) => {
          cache.writeQuery({
            query: SHOW_SAVE_RECORDING,
            data: { showSaveRecording: variables.input },
          });
        },
        toogleIsOpenDialogStopTimeRecord: (
          _,
          variables: { input: { status: boolean } },
          { cache },
        ) => {
          cache.writeQuery({
            query: IS_OPEN_DIALOG_STOP_TIME_RECORD,
            data: { isOpenDialogStopTimeRecord: variables.input.status },
          });
          return null;
        },
        updateHomepageClient: (
          _,
          {
            _id,
            buttonConfigurations,
            dynamicTreatments,
            footerConfigurations,
            organization,
            organizationHomepageSlug,
            __typename,
          }: {
            _id: any;
            buttonConfigurations: any;
            dynamicTreatments: any;
            footerConfigurations: any;
            organization: any;
            organizationHomepageSlug: any;
            __typename: any;
          },
          { cache },
        ) => {
          const previous = cache.readQuery({ query: HOMPEPAGE_CLIENT });
          const newHomepageClient = {
            homepageClient: {
              ...previous.homepageClient,
              _id,
              buttonConfigurations,
              dynamicTreatments,
              footerConfigurations,
              organization,
              organizationHomepageSlug,
              __typename,
            },
          };
          cache.writeQuery({
            query: HOMPEPAGE_CLIENT,
            data: newHomepageClient,
          });
          return null;
        },
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <SubcriptionClientContext.Provider value={{ subcriptionClient }}>
        {children}
      </SubcriptionClientContext.Provider>
    </ApolloProvider>
  );
};

export default React.memo(ClientProvider);
