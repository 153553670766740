import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import ForumIcon from '@material-ui/icons/Forum';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import NoteIcon from '@material-ui/icons/Note';
import LabelIcon from '@material-ui/icons/Label';
import {
  Eye,
  AlphaCbox,
  Cog,
  AccountSupervisor,
  Cogs,
  CalendarMultiple,
  Routes
} from 'mdi-material-ui';
import ApartmentIcon from '@material-ui/icons/Apartment';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import InfoIcon from '@material-ui/icons/Info';
import SmsIcon from '@material-ui/icons/Sms';
import BookIcon from '@material-ui/icons/Book';
import CodeIcon from '@material-ui/icons/Code';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import PublicIcon from '@material-ui/icons/Public';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import { HomePageConfig } from 'types.d';
import { Chat, PictureAsPdf } from '@material-ui/icons';
export interface TConfigMenu {
  title: string;
  url: string;
  role?: string;
  icon: React.ComponentType;
  type?: string;
}

export const configMenu: TConfigMenu[] = [
  { title: 'Home', url: '/home/', icon: HomeIcon },
  {
    title: 'All Treatments',
    url: '/all-treatments/',
    icon: ListAltIcon,
    role: 'viewAllTreatments',
    type: HomePageConfig.AllTreatment
  },
  {
    title: 'My Treatments',
    url: '/my-treatments/',
    icon: ViewListIcon,
    role: 'viewMyTreatments',
    type: HomePageConfig.MyTreatment
  },
  {
    title: 'My Favorite Treatments',
    url: '/fav-treatments/',
    icon: FavoriteIcon,
    role: 'viewFavoriteTreatments',
    type: HomePageConfig.MyFavoriteTreatments
  },
  {
    title: 'Public Treatments',
    url: '/pub-treatments/',
    icon: PublicIcon,
    role: 'viewPublicTreatments',
    type: HomePageConfig.PublicTreatments
  },
  {
    title: 'Private Treatments',
    url: '/pvt-treatments/',
    icon: EnhancedEncryptionIcon,
    role: 'viewPrivateTreatments',
    type: HomePageConfig.PrivateTreatments
  },
  {
    title: 'Analytics Dashboard',
    url: '/dashboard/',
    role: 'viewDashboard',
    icon: DashboardIcon,
  },
  {
    title: 'Patient Dashboard',
    url: '/patients/',
    icon: LocalHospitalIcon,
    role: 'viewPatient',
  },
  {
    title: 'Public Surveys',
    url: '/public-surveys/',
    icon: LocalLibraryIcon,
    role: 'viewPatient',
  },
  {
    title: 'My Treatment Modules',
    url: '/assigned-treatments/',
    icon: ListIcon,
    role: 'viewAssignedTreatment',
  },
  {
    title: 'Media',
    url: '/media-management/',
    role: 'viewMedia',
    icon: PermMediaIcon,
  },
  {
    title: 'Treatment Designer',
    url: '/treatment-designer/',
    role: 'viewTreatments',
    icon: ImportExportIcon,
  },
  {
    title: 'Communication',
    url: '/question/',
    role: 'viewComments',
    icon: ForumIcon,
  },
  {
    title: 'Treatment',
    url: '/question-treatment/',
    role: 'viewComments',
    icon: AssignmentIcon,
  },
  {
    title: 'Patient',
    url: '/question-patient/',
    role: 'viewComments',
    icon: LocalHospitalIcon,
  },
  {
    title: 'Quick View',
    url: '/quick-view/',
    role: 'viewQuickView',
    icon: Eye,
  },
  {
    title: 'Administration',
    url: '/administration/',
    role: 'viewAdministration',
    icon: SupervisorAccountIcon,
  },

  {
    title: 'Organizations',
    url: '/organizations/',
    role: 'viewOrganizations',
    icon: ApartmentIcon,
  },
  {
    title: 'Conditions',
    url: '/condition/',
    role: 'viewCondition',
    icon: AlphaCbox,
  },
  {
    title: 'Note Types',
    url: '/note-type/',
    role: 'viewNoteType',
    icon: NoteIcon,
  },
  {
    title: 'Users',
    url: '/users/',
    role: 'viewUser',
    icon: PersonOutlineIcon,
  },
  {
    title: 'Providers',
    url: '/providers/',
    role: 'viewProvider',
    icon: AccountSupervisor,
  },
  {
    title: 'Task Types',
    url: '/task-type/',
    role: 'viewFollowUpType',
    icon: VisibilityIcon,
  },
  {
    title: 'Tags',
    url: '/tag/',
    role: 'viewTag',
    icon: LabelIcon,
  },
  {
    title: 'Emr Logs',
    url: '/administration/emr-logs/',
    role: 'viewEmrLogAdministration',
    icon: FormatListBulletedIcon,
  },
  {
    title: 'Release notes',
    url: '/administration/release-notes/',
    role: 'viewReleaseNotesAdministration',
    icon: BookIcon,
  },

  {
    title: 'Group Settings',
    url: '/group-settings/',
    role: 'viewSettingGroup',
    icon: CalendarMultiple,
  },
  {
    title: 'Chat Analysis',
    url: '/chat-analysis/',
    role: 'viewChatAnalysis',
    icon: Chat,
  },
  {
    title: 'Treatment Flows',
    url: '/treatment-flows/',
    role: 'viewSettingPathway',
    icon: Routes,
  },
  {
    title: 'PDF Conversion',
    url: '/pdf-conversion/',
    role: 'viewPDFConversion',
    icon: PictureAsPdf,
  },
  {
    title: 'Modules',
    url: '/module/',
    role: 'viewModules',
    icon: CodeIcon,
  },
  {
    title: 'Settings',
    url: '/setting/',
    role: 'viewSetting',
    icon: Cogs,
  },
  {
    title: 'Providers',
    url: '/setting/providers/',
    role: 'viewProviderSetting',
    icon: AccountSupervisor,
  },
  {
    title: 'Task Types',
    url: '/setting/task-type/',
    role: 'viewFollowUpTypeSetting',
    icon: VisibilityIcon,
  },
  {
    title: 'Tags',
    url: '/setting/tag/',
    role: 'viewTagSetting',
    icon: LabelIcon,
  },
  {
    title: 'Emr Logs',
    url: '/emr-logs/',
    role: 'viewEmrLog',
    icon: FormatListBulletedIcon,
  },
  {
    title: 'Group Settings',
    url: '/setting/group-settings/',
    role: 'viewSettingGroupSetting',
    icon: CalendarMultiple,
  },
  {
    title: 'Chat Analysis',
    url: '/setting/chat-analysis/',
    role: 'viewSettingChatAnalysis',
    icon: Chat,
  },
  {
    title: 'Treatment Flows',
    url: '/setting/treatment-flows/',
    role: 'viewSettingTreatmentPathway',
    icon: Routes,
  },
  {
    title: 'PDF Conversion',
    url: '/setting/pdf-conversion/',
    role: 'viewSettingPDFConversion',
    icon: PictureAsPdf,
  },
  {
    title: 'Twilio Logs',
    url: '/twilio-logs/',
    role: 'viewTwilioLog',
    icon: SmsIcon,
  },
  {
    title: 'Common Configs',
    url: '/common-configs/',
    role: 'viewCommonConfigs',
    icon: Cog,
  },
  {
    title: 'Release notes',
    url: '/release-notes/',
    role: 'viewReleaseNotes',
    icon: BookIcon,
  },
  {
    title: 'Organization Setup',
    url: '/organization-information/',
    role: 'viewOrgInfo',
    icon: InfoIcon,
  },
  {
    title: 'Release notes',
    url: '/release-note/',
    role: 'viewReleaseNotesUser',
    icon: BookIcon,
  },
];
