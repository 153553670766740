import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { useCheckLogin, useCopyToClipBoard, useEffectOnlyOnce } from 'hooks';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  ChatBotFeedbackArgs,
  ChatbotFeedbackMutation,
  GetChatbotPDFQuery,
  GetChatbotPDFQueryVariables,
} from 'types.d';
import { Chatbot_Feedback, GET_CHATBOT_PDF } from 'gql/server/chatbot';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Download } from 'mdi-material-ui';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { Settings } from '@material-ui/icons';
import StopIcon from '@material-ui/icons/Stop';
import { chunkText, getLocalStorage, getSessionStorage } from 'share/utils';
import { DEFAULT_VOICE_SETTINGS, KEY_SESSION_ORGANIZATION_ID, ORG_LOGO } from 'CONST';
import { useSnackbar } from 'notistack';
import PopoverSocialShare from './PopoverSocialShare';

type Props = {
  inputMessage: string;
  msg: any;
  messages: any;
  setMessages: Function;
  setResponseAdded: Function;
  highlightedIndex: any;
  setHighlightedIndex: Function;
  textWords: any;
  setTextWords: Function;
};

export const PopoverChatbot: React.FC<Props> = (props: Props) => {
  const [like, setLike] = useState(props.msg.like);

  const [disabled, setDisabled] = useState(false);

  const { loadingCopy, handleCopyToClipBoard } = useCopyToClipBoard();

  const isLogin = useCheckLogin();

  var synth = window.speechSynthesis;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [snackbarKey, setSnackbarKey] = useState<
    string | number | null | undefined
  >(null);

  const [cbQuery, setCbQuery] = useState('');

  useEffectOnlyOnce(() => {
    for (let i = 0; i < props.messages.length; i++) {
      if (props.messages[i].text === props.msg.text)
        setCbQuery(props.messages[i - 1].text);
    }
  });

  const [addLikeOrDislike] = useMutation<
    ChatbotFeedbackMutation,
    ChatBotFeedbackArgs
  >(Chatbot_Feedback, {
    onCompleted(data) {
      if (data) {
        props.setResponseAdded(false);
        props?.setMessages(
          props.messages.map((ele: any) => {
            if (ele.responseId == props.msg.responseId) {
              ele.like = like;
              if (like == false) {
                props.msg.feedback = true;
                ele.feedback = true;
              } else {
                props.msg.feedback = false;
                ele.feedback = false;
              }
            }
            return { ...ele };
          }),
        );
      }
    },
    onError(error) { },
  });

  function likeComment(like: boolean | undefined): void {
    if (like !== props.msg.like) {
      setLike(like);
      props.msg.like = like;
      addLikeOrDislike({
        variables: {
          params: {
            messageId: props.msg.responseId,
            like: like,
            comment: props.msg.comment,
          },
        },
      });
    }
  }

  const [exportPDF] = useLazyQuery<
    GetChatbotPDFQuery,
    GetChatbotPDFQueryVariables
  >(GET_CHATBOT_PDF, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (snackbarKey) closeSnackbar(snackbarKey);

      if (data?.getChatbotPDF) {
        downloadPDF(data.getChatbotPDF);
      }
    },
  });

  const handleExportPDF = () => {
    var query;
    const voices = synth.getVoices();
    if (!props.inputMessage) {
      var index = props.messages.findIndex((element: any) => {
        return element.text === props.msg.text;
      });
      query = props.messages[index - 1].text;
    } else {
      query = props.inputMessage;
    }
    setSnackbarKey(
      enqueueSnackbar('PDF Downloading', {
        persist: true,
        variant: 'info',
      }),
    );
    var logo = getLocalStorage(ORG_LOGO)

    var params: any = {
      query: query,
      text: props.msg.text,
    };

    if (!isLogin) {
      params.organization = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)
    }

    if (logo)
      params.logoURL = logo;

    if (voices[props.msg.voice].lang.includes('zh')) {
      if (voices[props.msg.voice].lang == 'zh-HK') {
        params.targetLanguage = 'zh-CN';
      } else {
        params.targetLanguage = voices[props.msg.voice].lang;
      }
    } else {
      params.targetLanguage = voices[props.msg.voice].lang.replace(/-\w+$/, '');
    }

    exportPDF({
      variables: {
        params,
      },
    });
  };

  const downloadPDF = (pdfBuffer: any) => {
    const linkSource = `data:application/pdf;base64,${pdfBuffer}`;
    const link = document.createElement('a');
    const fileName = 'tgps_assistant.pdf';
    link.href = linkSource;
    link.download = fileName;
    link.click();
  };

  function openCommentModal(): void {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.responseId == props.msg.responseId) {
          props.msg.feedback = true;
          ele.feedback = true;
        }
        return { ...ele };
      }),
    );
  }

  function openSettingsModal(open?: boolean): void {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.responseId == props.msg.responseId) {
          if (props.msg.settings == true) {
            props.msg.settings = false;
            ele.settings = false;
          } else if (open) {
            props.msg.settings = true;
            ele.settings = true;
          }
        }
        return { ...ele };
      }),
    );
  }

  function disableSettings() {
    setDisabled(true);
    openSettingsModal();
  }

  function handlePlay(played: any, onStart?: boolean) {
    props.setResponseAdded(false);
    if (onStart) {
      var default_voice_settings = JSON.parse(
        getLocalStorage(DEFAULT_VOICE_SETTINGS)!,
      );
      if (default_voice_settings.rate != props.msg.rate) {
        props.msg.rate = default_voice_settings.rate;
      }
      if (default_voice_settings.pitch != props.msg.rate) {
        props.msg.pitch = default_voice_settings.pitch;
      }
      if (default_voice_settings.voice != props.msg.rate) {
        props.msg.voice = default_voice_settings.voice;
      }
      props?.setMessages(
        props.messages.map((ele: any) => {
          if (ele.type == 'bot') {
            ele.rate = default_voice_settings.rate;
            ele.pitch = default_voice_settings.pitch;
            ele.voice = default_voice_settings.voice;
            if (ele.responseId == props.msg.responseId) {
              props.msg.played = played;
              ele.played = played;
            } else {
              ele.played = undefined;
            }
          }
          return { ...ele };
        }),
      );
    } else {
      props?.setMessages(
        props.messages.map((ele: any) => {
          if (ele.responseId == props.msg.responseId) {
            props.msg.played = played;
            ele.played = played;
          }
          return { ...ele };
        }),
      );
    }
  }

  function handleSpeak(pause?: Boolean) {
    // Using SpeechSynthesis API to speak the message

    const voices = synth.getVoices();
    // const utterance = new SpeechSynthesisUtterance();

    var text = chunkText(props.msg.text.replace(/[^\w\s]|#/g, ''), 30);
    speak(voices, text, 0, pause);
  }

  function speak(voices: any, text: any, ind: any, pause?: Boolean) {
    if (pause) {
      setDisabled(false);
      handlePlay(false);
      synth.pause();
    } else if (pause == false && pause != undefined) {
      disableSettings();
      handlePlay(true);
      synth.resume();
    } else {
      synth.cancel();
      if (ind >= text.length) {
        return;
      }

      const utterance = new SpeechSynthesisUtterance(text[ind]);
      voices.forEach((voc: any, index: any) => {
        if (props.msg.voice == index) {
          utterance.lang = voices[props.msg.voice].lang;
          utterance.voice = voices[props.msg.voice];
          utterance.rate = props.msg.rate;
          utterance.pitch = props.msg.pitch;
          //utterance.volume = props.msg.vol;
        }
      });

      synth.speak(utterance);
      utterance.onstart = () => {
        // var index = props.messages.findIndex((msg: any) => msg.text == props.msg.text)
        // props.setHighlightedIndex(index); // Update the index for highlighting
        disableSettings();
        handlePlay(true, true);
      };

      // utterance.onboundary = (event: any) => {
      //   console.log(event.utterance)
      //   // const textContent = event.utterance.text.substring(0, event.charIndex).trim();
      //   // words = textContent.split(' ');
      //   // props.setHighlightedIndex(props.msg.text); // Update the index for highlighting
      //   // lastCharIndex = event.charIndex
      //   // props.setTextWords(textContent)

      // };
      // utterance.onerror = (event: any) => {
      //   console.log(event.error)
      // }
      utterance.onend = () => {
        // props.setHighlightedIndex(undefined);
        setDisabled(false);
        handlePlay(undefined);
        speak(voices, text, ind + 1, pause);
      };
    }
  }

  return (
    <>
      <Box
        p={0.5}
        display="flex"
        flexDirection="row"
        justifyContent={'space-between'}
        marginY={'5px'}
      >
        <Tooltip title="Like">
          <IconButton
            // disabled={props.msg.history}
            aria-label="like"
            onClick={() => likeComment(props.msg.like ? undefined : true)}
          >
            {props.msg.like ? (
              <ThumbUpIcon fontSize="small" />
            ) : (
              <ThumbUpOutlinedIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Dislike">
          <IconButton
            aria-label="dislike"
            // disabled={props.msg.history}
            onClick={() =>
              likeComment(props.msg.like == false ? undefined : false)
            }
          >
            {props.msg.like == false || like == false ? (
              <ThumbDownIcon fontSize="small" />
            ) : (
              <ThumbDownOutlinedIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={'Copy'}>
          <IconButton
            aria-label="copy"
            onClick={() => handleCopyToClipBoard(props.msg.text)}
          >
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Export to PDF`}>
          <IconButton aria-label="export PDF" onClick={handleExportPDF}>
            <Download fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Comment`}>
          <IconButton
            aria-label="comment"
            // disabled={
            //   (props.msg.comment == null && props.msg.history) ||
            //   props.msg.like == undefined ||
            //   props.msg.like == true
            // }
            onClick={openCommentModal}
          >
            <CommentOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            props.msg.played == undefined
              ? `Listen`
              : props.msg.played
                ? `Pause`
                : `Resume`
          }
        >
          <IconButton
            aria-label="voice"
            onClick={() => {
              if (props.msg.played == undefined) {
                handleSpeak();
              } else if (props.msg.played) {
                handleSpeak(true);
              } else {
                handleSpeak(false);
              }
            }}
          >
            {!props.msg.played ? (
              <VolumeUpIcon fontSize="small" />
            ) : (
              <StopIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={`Settings`}>
          <IconButton
            aria-label="settings"
            disabled={disabled}
            onClick={() => openSettingsModal(true)}
          >
            <Settings fontSize="small" />
          </IconButton>
        </Tooltip>
        <PopoverSocialShare
          data={{
            query: cbQuery,
            resp: props.msg.text,
          }}
        />
      </Box>
    </>
  );
};

export default React.memo(PopoverChatbot);
