import React from 'react';
import { AutoCompleteLoadData } from 'components';
import {
  GetTreatmentRegimenQueryVariables,
  TreatmentRegimenFragment,
} from 'types.d';
import { FormData } from '../DialogSendSurvey';
import { FormContextValues } from 'react-hook-form';
import { QueryLazyOptions } from '@apollo/react-hooks';

type Props = {
  methods: FormContextValues<FormData>;
  loadingGetRegimen: boolean;
  dataRegimen: TreatmentRegimenFragment[];
  getTreatmentRegimen: (
    options?: QueryLazyOptions<GetTreatmentRegimenQueryVariables> | undefined,
  ) => void;
  isDisableUpdate: boolean;
};

export const AutocompleteLoadGroup: React.FC<Props> = ({
  methods,
  loadingGetRegimen,
  dataRegimen,
  getTreatmentRegimen,
  isDisableUpdate,
}) => {
  const handleTextInputGetListGroups = (value: string) => {
    if (value) {
      getTreatmentRegimen({
        variables: {
          params: {
            search: value,
          },
        },
      });
    }
  };

  return (
    <AutoCompleteLoadData
      hasOptionsTextNull
      label="Group"
      nameOption="name"
      required
      loadList={loadingGetRegimen}
      options={
        dataRegimen?.map(item => {
          return {
            ...item,
            name: item?.regimenName,
          };
        }) || []
      }
      small
      defaultValue={null}
      name="group"
      control={methods!.control}
      methods={methods}
      error={!!methods!.errors.group}
      callbackHandleText={value => handleTextInputGetListGroups(value)}
      disabled={isDisableUpdate}
    />
  );
};

export default React.memo(AutocompleteLoadGroup);
