import gql from 'graphql-tag';
import {
  MetaFragment,
  ResponseModifyTreatmentFragment,
  ResponseShareTreatmentResFragment,
  SurveyVersionFragment,
} from './fragments';

export const CREATE_SURVEY = gql`
  mutation CreateSurvey($params: CreateSurveyInput!) {
    createSurvey(params: $params) {
      ...ResponseModifyTreatment
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const UPDATE_SURVEY_INFO = gql`
  mutation UpdateSurveyInfo($params: UpdateSurveyInfoInput!) {
    updateSurveyInfo(params: $params) {
      ...ResponseModifyTreatment
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const CREATE_SURVEY_RESULT = gql`
  mutation CreateSurveyResult($params: CreateSurveyResultInput!) {
    createSurveyResult(params: $params) {
      _id
    }
  }
`;

export const CREATE_ANONYMOUS_SURVEY_RESULT = gql`
  mutation CreateAnonymousSurveyResult($params: CreateSurveyResultInput!) {
    createAnonymousSurveyResult(params: $params) {
      _id
    }
  }
`;

export const CREATE_SURVEY_WITH_TOKEN = gql`
  mutation CreateSurveyWithToken($params: CreateSurveyResultWithTokenInput!) {
    createSurveyWithToken(params: $params) {
      _id
    }
  }
`;

// export const GET_SURVEY_RESULT = gql`
//   query GetSurveyResult($params: IdInputType!) {
//     getSurveyResult(params: $params) {
//       _id
//       data
//     }
//   }
// `;

export const GET_LIST_SURVEY = gql`
  query GetListSurvey(
    $params: GetTreatmentsInput! = { type: SURVEY, sortByOrder: { name: 1 } }
  ) {
    getTreatments(params: $params) {
      nodes {
        _id
        name
        version
      }
    }
  }
`;

export const GET_SURVEY_VERSION = gql`
  query GetSurveyVersion($params: GetSurveyVersionInput!) {
    getSurveyVersion(params: $params) {
      nodes {
        ...SurveyVersion
      }
    }
  }
  ${SurveyVersionFragment}
`;

export const GET_LIST_SURVEY_DATA = gql`
  query GetListSurveyData($params: GetTreatmentsInput! = { type: SURVEY }) {
    getTreatments(params: $params) {
      nodes {
        _id
        name
        surveyData
      }
    }
  }
`;

//get detail survey included survey data and result of survey(apply for create chart)
export const GET_DETAIL_SURVEY = gql`
  query GetDetailSurvey(
    $paramsSurvey: GetSurveyVersionInput!
    $paramsResultsSurvey: GetSurveyResultsInput!
  ) {
    getSurveyVersion(params: $paramsSurvey) {
      nodes {
        _id
        name
        surveyData
        version
        originSurvey
      }
    }
    getSurveyResults(params: $paramsResultsSurvey) {
      _id
      data
      createdAt
      updatedAt
      owner {
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GET_ANONYMOUS_SURVEY_RESULTS = gql`
  query GetAnonymousSurveyResults {
    getAnonymousSurveyResults {
      _id
      surveyName
      data
      createdAt
      updatedAt
      survey {
        _id
      }
      owner {
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GET_SRUVEY_BY_ID = gql`
  query GetSurveyById($params: GetTreatmentInput!) {
    getSurveyById(params: $params) {
      _id
      surveyData
    }
  }
`;

//get detail survey included survey data and result of survey from config(apply for display on dashboard)
export const GET_DETAIL_SURVEY_FROM_CONFIG = gql`
  query GetDetailSurveyFromConfig(
    $paramsSurvey: GetSurveyResultsFromChartConfigInput!
    $paramsResultsSurvey: GetSurveyResultsFromChartConfigInput!
  ) {
    getSurveyFromChartConfig(params: $paramsSurvey) {
      _id
      surveyData
      name
      originSurvey
    }
    getSurveyResultFromChartConfig(params: $paramsResultsSurvey) {
      _id
      data
      createdAt
      owner {
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GET_QUESTIONS = gql`
  query GetQuestions($params: GetSurveyVersionInput!) {
    getSurveyVersion(params: $params) {
      nodes {
        _id
        surveyData
      }
    }
  }
`;

export const RESEND_SURVEY = gql`
  mutation ResendSurvey($params: SurveyResenderInput!) {
    resendSurvey(params: $params) {
      ...ResponseShareTreatmentRes
    }
  }
  ${ResponseShareTreatmentResFragment}
`;

export const EXPORT_EMR_INFO = gql`
  mutation ExportEmrInfo($params: GetEmrInfoInput!, $file: Upload!) {
    exportEmrInfo(params: $params, file: $file)
  }
`;

export const SUBMIT_FORM_DATA = gql`
  mutation SubmitFormData($params: CreateDynamicFormDataInput!) {
    submitFormData(params: $params) {
      _id
    }
  }
`;

export const GET_ALL_FORMS_DATA = gql`
  query GetAllFormsData($params: GetDynamicFormsInput!) {
    getAllFormsData(params: $params){
      nodes {
        _id
        formJson
        answersJson
        status
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
`;