import { Options } from 'qr-code-styling';

export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  ObjectId: any;
  JSON: any;
  JSONObject: any;
  Upload: any;
  Buffer: any;
};

export type ActiveUserInput = {
  accessToken: Scalars['String'];
};

export type ActiveUserOtpInput = {
  otp: Scalars['String'];
  phone: Scalars['String'];
};

export type ActiveUserOTPInputFlow = {
  otp: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  flowId: Scalars['ObjectId'];
};

export type AddMorePatientNoteInput = {
  noteId: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
  description: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  notedAt: Scalars['DateTime'];
  type?: Maybe<Scalars['ObjectId']>;
};

export type AddMoreTreatmentInput = {
  followUpTypeId: Scalars['ObjectId'];
  treatmentIds: Array<Scalars['ObjectId']>;
};

export type AddMoreTreatmentNoteTypeInput = {
  patientNoteTypeId: Scalars['ObjectId'];
  treatmentIds: Array<Scalars['ObjectId']>;
};

export type AddSecondaryEmailInput = {
  secondaryEmail: Scalars['String'];
};

export type AddShowingTreatmentSidebarParams = {
  treatment?: Maybe<Scalars['String']>;
};

export type AddTagRes = {
  __typename?: 'AddTagRes';
  tag: Tag;
  added: Scalars['Boolean'];
  isColorChanged?: Maybe<Scalars['Boolean']>;
};

export type AddTimeRecordInput = {
  cptCode: Scalars['ObjectId'];
  patient: Scalars['ObjectId'];
  startedAt: Scalars['DateTime'];
};

export type AddUserInput = {
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  role: UserRole;
  organization?: Maybe<Scalars['ObjectId']>;
};

export type AddUserSecondaryEmailInput = {
  secondaryEmail: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type AggregateInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  reportType: ReportType;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  surveyId?: Maybe<Scalars['ObjectId']>;
};

export type AnalyticDashboardConfig = {
  __typename?: 'AnalyticDashboardConfig';
  dateRange?: Maybe<ConfigAnalyticData>;
};

export type AnalyticDashboardConfigInput = {
  dateRange?: Maybe<ConfigAnalyticData>;
};

export type AnalyzerOutput = {
  avgAnswerableScore: Scalars['Float'];
  avgSearchScore: Scalars['Float'];
  questionAnalysis: Array<QuestionAnalysisOutput>;
  readabilityscores: ReadabilityScores;
};

export type AssignInput = {
  token: Scalars['String'];
};

export type AssignTreatmentInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type AssignTreatmentRes = {
  __typename?: 'AssignTreatmentRes';
  treatmentId: Scalars['ObjectId'];
  organization?: Maybe<Scalars['ObjectId']>;
  orgName?: Maybe<Scalars['String']>;
  treatmentName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type AssignFlowRes = {
  flowId: Scalars['ObjectId'];
  organization: Scalars['ObjectId'];
  orgName: Scalars['String'];
  flowName: Scalars['String'];
  token: Scalars['String'];
  slug: Scalars['String'];
  references: Array<Scalars['ObjectId']>;
};

export type AttachChecklistInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  checklistIds: Array<Scalars['ObjectId']>;
};

export enum AnalysisStatus {
  Pending = 'PENDING',
  InProcess = 'INPROCESS',
  Done = 'DONE',
  Failed = 'FAILED',
}

export enum AuthenticationSource {
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Register = 'REGISTER',
}

export type AuthInfo = {
  __typename?: 'AuthInfo';
  authenticationSource?: Maybe<AuthenticationSource>;
  authenticationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type BaseAggregateRes = {
  __typename?: 'BaseAggregateRes';
  name?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export enum ByNWeekDay {
  Mo = 'MO',
  Tu = 'TU',
  We = 'WE',
  Th = 'TH',
  Fr = 'FR',
  Sa = 'SA',
  Su = 'SU',
}

export type CalendarConfig = {
  __typename?: 'CalendarConfig';
  _id: Scalars['ObjectId'];
  patient?: Maybe<Patient>;
  owner?: Maybe<User>;
  survey?: Maybe<Treatment>;
  task?: Maybe<FollowUpTypeSetting>;
  taskDesc?: Maybe<Scalars['String']>;
  scheduleConfig: ScheduleConfigEmbedded;
  time?: Maybe<ConfigTimeEmbedded>;
  sendSMS?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  smsMessage?: Maybe<Scalars['String']>;
  configType: ConfigType;
  sendStatus: TreatmentSendStatus;
  organization?: Maybe<Organization>;
  treatmentRegimenId?: Maybe<Scalars['ObjectId']>;
  treatmentType: TreatmentType;
  regimenStart?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSendTime?: Maybe<Scalars['DateTime']>;
  queueId?: Maybe<Scalars['String']>;
};

export type CalendarConfigRes = {
  __typename?: 'CalendarConfigRes';
  nodes: Array<CalendarConfig>;
  meta?: Maybe<MetaRes>;
};

export type ChangePasswordInput = {
  password: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangeSurveyResultInput = {
  noteId: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
  surveyResultId?: Maybe<Scalars['ObjectId']>;
};

export enum ChangingBehavior {
  Move = 'MOVE',
  Copy = 'COPY',
  Ref = 'REF',
}

export type ChatAnalysisRequest = {
  _id: Scalars['ObjectId'];
  request_name: Scalars['String'];
  status: AnalysisStatus;
  start_time: Scalars['DateTime'];
  end_time: Scalars['DateTime'];
  logged_in: Scalars['Boolean'];
  result: Scalars['String'];
  batchId: Scalars['String'];
  creator: User;
  organization: Organization;
};

export type ChartConfig = {
  __typename?: 'ChartConfig';
  _id: Scalars['ObjectId'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: Organization;
  dataSource: DataSource;
  displayName?: Maybe<Scalars['String']>;
  reportType: ReportType;
  chartConfig?: Maybe<Scalars['JSON']>;
  survey?: Maybe<Treatment>;
  checklist?: Maybe<Treatment>;
  surveyVersion?: Maybe<Scalars['Float']>;
  owner: User;
  ownerId: Scalars['ObjectId'];
  order?: Maybe<Scalars['Int']>;
  grid?: Maybe<Scalars['Int']>;
  isCopy?: Maybe<Scalars['Boolean']>;
  treatments?: Maybe<Array<Treatment>>;
};

export type ChartOrder = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export enum ChecklistStatus {
  Completed = 'COMPLETED',
  Uncompleted = 'UNCOMPLETED',
  NotYetStarted = 'NOT_YET_STARTED',
}

export type CheckEmailOrPhoneNumber = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['ObjectId'];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['ObjectId']>;
  organization?: Maybe<Organization>;
  treatment: Treatment;
  treatmentId?: Maybe<Scalars['ObjectId']>;
  parent?: Maybe<Comment>;
  parentId?: Maybe<Scalars['ObjectId']>;
  sendTo?: Maybe<User>;
  readBy?: Maybe<Array<User>>;
  message: Scalars['String'];
  numberOfAnswer?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentAggregateRes = {
  __typename?: 'CommentAggregateRes';
  name?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
  valueOfReply?: Maybe<Scalars['Float']>;
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  node: CommentExt;
  cursor: Scalars['String'];
};

export type CommentExt = {
  __typename?: 'CommentExt';
  _id: Scalars['ObjectId'];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['ObjectId']>;
  organization?: Maybe<Organization>;
  treatment: Treatment;
  treatmentId?: Maybe<Scalars['ObjectId']>;
  parent?: Maybe<Comment>;
  parentId?: Maybe<Scalars['ObjectId']>;
  sendTo?: Maybe<User>;
  readBy?: Maybe<Array<User>>;
  message: Scalars['String'];
  numberOfAnswer?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  numberOfUnreadAnswer?: Maybe<Scalars['Int']>;
};

export type CommentGroupEdge = {
  __typename?: 'CommentGroupEdge';
  node: CommentGroupRes;
  cursor: Scalars['String'];
};

export type CommentGroupRes = {
  __typename?: 'CommentGroupRes';
  _id: Scalars['ObjectId'];
  user?: Maybe<User>;
  treatment?: Maybe<Treatment>;
  organization?: Maybe<Organization>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  unreadCount: Scalars['Float'];
  lastCommentAt?: Maybe<Scalars['DateTime']>;
};

export type CommentGroupsCursorRes = {
  __typename?: 'CommentGroupsCursorRes';
  pageInfo: PageInfo;
  edges: Array<CommentGroupEdge>;
};

export type CommentsCursorRes = {
  __typename?: 'CommentsCursorRes';
  pageInfo: PageInfo;
  edges: Array<CommentEdge>;
};

export enum CompletedStatus {
  Partial = 'PARTIAL',
  Totally = 'TOTALLY',
}

export type ConditionEmbedded = {
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Array<Scalars['String']>>;
  booleanAnswer?: Maybe<Array<Scalars['Boolean']>>;
  surveyId?: Maybe<Scalars['ObjectId']>;
  checklistId?: Maybe<Scalars['ObjectId']>;
  version?: Maybe<Scalars['Float']>;
};

export type ConditionSetting = {
  __typename?: 'ConditionSetting';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  stages?: Maybe<Array<StageEmbedded>>;
  organization?: Maybe<Organization>;
  createdBy: User;
  updatedBy: User;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSettingRes = {
  __typename?: 'ConditionSettingRes';
  nodes: Array<ConditionSetting>;
  meta?: Maybe<MetaRes>;
};

export enum ConfigAnalyticData {
  LastSixMonths = 'LAST_SIX_MONTHS',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
}

export type ConfigEmbedded = {
  __typename?: 'ConfigEmbedded';
  patientDashboard?: Maybe<PatientDashboardConfig>;
  analyticDashboard?: Maybe<AnalyticDashboardConfig>;
  homePage?: Maybe<HomePageTreatmentConfig>;
  leftDrawer?: Array<Maybe<LeftDrawerConfig>>;
  perPage?: Maybe<Scalars['Float']>;
};

export type ConfigEmbeddedInput = {
  patientDashboard?: Maybe<PatientDashboardConfigInput>;
  analyticDashboard?: Maybe<AnalyticDashboardConfigInput>;
  homePage?: Maybe<HomePageTreatmentConfigInput>;
  leftDrawer?: Array<Maybe<LeftDrawerConfigInput>>;
  perPage?: Maybe<Scalars['Float']>;
};

export type ConfigTimeEmbedded = {
  __typename?: 'ConfigTimeEmbedded';
  hour?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
};

export enum ConfigType {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export enum ConversionType {
  Batch = 'BATCH',
  Online = 'ONLINE',
}

export enum ConversionStatus {
  InProcess = 'INPROCESS',
  Failed = 'FAILED',
  Done = 'DONE',
}

export type ContactConfig = {
  __typename?: 'ContactConfig';
  method?: Maybe<Array<ContactMethod>>;
};

export enum ContactMethod {
  Sms = 'SMS',
  Email = 'EMAIL',
}

export type CopyTreatmentInput = {
  treatmentId: Scalars['ObjectId'];
  parent?: Maybe<Scalars['ObjectId']>;
  organization: Scalars['ObjectId'];
  newOwner: Scalars['ObjectId'];
};

export type CptCode = {
  __typename?: 'CPTCode';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  organization: Organization;
  patientNoteTypes?: Maybe<Array<PatientNoteTypeSetting>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CptCodeRes = {
  __typename?: 'CPTCodeRes';
  nodes: Array<CptCode>;
  meta?: Maybe<MetaRes>;
};

export type CreateCalendarConfigByRegimenInput = {
  startDate: Scalars['DateTime'];
  localTimeZone?: Maybe<Scalars['Float']>;
  treatmentRegimenId: Scalars['ObjectId'];
  patientInfo: Array<SharedUser>;
  smsMessage?: Maybe<Scalars['String']>;
};

export type CreateCalendarConfigInput = {
  scheduleConfig: ScheduleConfigEmbeddedInput;
  treatmentId: Scalars['ObjectId'];
  configId?: Maybe<Scalars['ObjectId']>;
  patientInfo: Array<SharedUser>;
  smsMessage?: Maybe<Scalars['String']>;
  resend48h?: Maybe<Scalars['Boolean']>;
};

export type CreateChartConfigInput = {
  dataSource: DataSource;
  reportType: ReportType;
  chartConfig?: Maybe<Scalars['JSON']>;
  surveyId?: Maybe<Scalars['ObjectId']>;
  checklistId?: Maybe<Scalars['ObjectId']>;
  surveyVersion?: Maybe<Scalars['Float']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  displayName?: Maybe<Scalars['String']>;
  dashboardId: Scalars['ObjectId'];
};

export type CreateChartConfigs = {
  dataSource: DataSource;
  reportType: ReportType;
  chartConfig?: Maybe<Scalars['JSON']>;
  surveyId?: Maybe<Scalars['ObjectId']>;
  checklistId?: Maybe<Scalars['ObjectId']>;
  surveyVersion?: Maybe<Scalars['Float']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  displayName?: Maybe<Scalars['String']>;
};

export type CreateChartConfigsInput = {
  dashboardId: Scalars['ObjectId'];
  chartConfigs: Array<CreateChartConfigs>;
};

export type CreateChecklistInput = {
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ObjectId']>;
  surveyData: Scalars['String'];
};

export type CreateCommentInput = {
  treatment: Scalars['ObjectId'];
  parent?: Maybe<Scalars['ObjectId']>;
  message: Scalars['String'];
  sendTo?: Maybe<Scalars['ObjectId']>;
  broadcastQuesFromTreatments?: Maybe<Scalars['Boolean']>;
};

export type CreateConditionSettingInput = {
  condName: Scalars['String'];
  stageName: Array<Scalars['String']>;
};

export type CreateCptCodeInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  patientNoteTypes?: Maybe<Array<Scalars['ObjectId']>>;
};

export type CreateDashboardInput = {
  name: Scalars['String'];
};

export type CreateDynamicFormDataInput = {
  userId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  formJson: Scalars['String'];
  answersJson: Scalars['String'];
  status: FormStatus;
};

export type CreateFolderInput = {
  name: Scalars['String'];
  parent?: Maybe<Scalars['ObjectId']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  tagEmbedded?: Maybe<Array<UpdateTagEmbeddedInput>>;
};

export type CreateFollowUpTypeInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  folderIds?: Maybe<Array<Scalars['ObjectId']>>;
};

export type CreateGroupTreatmentInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  treatmentIds: Array<Scalars['ObjectId']>;
};

export type CreateNewPatientInput = {
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  dob: Scalars['DateTime'];
  gender: Gender;
  race?: Maybe<Race>;
  smsMessage?: Maybe<Scalars['String']>;
  provider?: Maybe<Array<Scalars['ObjectId']>>;
  emrProvider?: Maybe<Array<Scalars['ObjectId']>>;
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mrn?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['JSON']>>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  surveyIds?: Maybe<Array<Scalars['ObjectId']>>;
  ignoreDuplicate?: Maybe<Scalars['Boolean']>;
  sendSMS?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  timezone?: Maybe<TimezoneInput>;
};

export type CreateOrgRes = {
  __typename?: 'CreateOrgRes';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  sftpUser?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  queueId?: Maybe<Scalars['String']>;
  inboundPath?: Maybe<Scalars['String']>;
  outboundPath?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  defaultTreatment?: Maybe<Treatment>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  createdBy: User;
  orgAdmin: User;
};

export type CreatePatientDashboardInput = {
  name: Scalars['String'];
};

export type CreatePatientFollowUpInput = {
  note: Scalars['String'];
  userId: Scalars['ObjectId'];
  followUpDate: Scalars['DateTime'];
  type?: Maybe<Scalars['ObjectId']>;
};

export type CreatePatientNoteInput = {
  description: Scalars['String'];
  userId: Scalars['ObjectId'];
  notedAt: Scalars['DateTime'];
  surveyResultId?: Maybe<Scalars['ObjectId']>;
  color?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ObjectId']>;
};

export type CreatePatientNoteTypeInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  folderIds?: Maybe<Array<Scalars['ObjectId']>>;
};

export type CreatePatientRes = {
  __typename?: 'CreatePatientRes';
  result?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<Patient>;
  data?: Maybe<Array<Patient>>;
  userExists?: Maybe<Array<User>>;
};

export type CreatePatientStageInput = {
  patientId: Scalars['ObjectId'];
  conditionId: Scalars['ObjectId'];
  stageId: Scalars['ObjectId'];
  color?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type CreateProviderInput = {
  provider: Scalars['String'];
  emrProvider?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateSurveyInput = {
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ObjectId']>;
  smsMessage?: Maybe<Scalars['String']>;
  surveyData: Scalars['String'];
  emrObx?: Maybe<Scalars['String']>;
  checklist?: Maybe<Array<Scalars['ObjectId']>>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type CreateSurveyResultInput = {
  data: Scalars['JSON'];
  survey: Scalars['ObjectId'];
  completedStatus: CompletedStatus;
};

export type CreateSurveyResultWithTokenInput = {
  data: Scalars['JSON'];
  survey: Scalars['ObjectId'];
  completedStatus: CompletedStatus;
  token: Scalars['String'];
  surveyVersion: Scalars['Float'];
};

export type CreateTagForPatientInput = {
  patientId?: Maybe<Scalars['ObjectId']>;
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

export type CreateTagInput = {
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

export type CreateTreatmentInput = {
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  shortDescription: Scalars['String'];
  description: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ObjectId']>;
  mainImage?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  mainVideo?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  hideMainMedia?: Maybe<Scalars['Boolean']>;
  isHideOnHomePage?: Maybe<Scalars['Boolean']>;
  followUpType?: Maybe<Array<Scalars['ObjectId']>>;
  groupSettings?: Maybe<Array<Scalars['ObjectId']>>;
  isSurveyInline?: Maybe<Scalars['Boolean']>;
  stages?: Maybe<Array<CreateTreatmentStageInput>>;
  checklist?: Maybe<Array<Scalars['ObjectId']>>;
};

export type CreateTreatmentRegimenInput = {
  regimenName: Scalars['String'];
  regimenConfig: Array<RegimenConfigEmbeddedInput>;
};

export type CreateTreatmentStageInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateTreatmentStagesInput = {
  treatmentId: Scalars['ObjectId'];
  treatmentStages: Array<CreateTreatmentStageInput>;
};

export type CreateChatAnalysisRequestInput = {
  request_name: Scalars['String'];
  start_time: Scalars['DateTime'];
  end_time: Scalars['DateTime'];
  logged_in: Scalars['Boolean'];
};

export type CreateChatAnalysisRequestInput_V2 = {
  start_time: Scalars['DateTime'];
  end_time: Scalars['DateTime'];
  logged_in: Scalars['Boolean'];
};

export type QRCodeSettingsInput = Options;

export type CurrentStageRes = {
  __typename?: 'CurrentStageRes';
  _id: Scalars['ObjectId'];
  rootTreatment?: Maybe<Treatment>;
  currentTreatment?: Maybe<Treatment>;
  stage?: Maybe<TreatmentStageEmbed>;
  status?: Maybe<Scalars['String']>;
  assignedTreatment?: Maybe<Treatment>;
};

export type CursorGetGroupCommentInput = {
  searchKey?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['ObjectId'];
  organization?: Maybe<Organization>;
  charts?: Maybe<Array<ChartConfig>>;
  chartIds?: Maybe<Array<Scalars['ObjectId']>>;
  isShow: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DashboardRes = {
  __typename?: 'DashboardRes';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['ObjectId'];
  organization?: Maybe<Organization>;
  charts?: Maybe<Array<ChartConfig>>;
  chartIds?: Maybe<Array<Scalars['ObjectId']>>;
  isShow: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  chartsOrdered?: Maybe<Array<ChartConfig>>;
};

export enum DashboardType {
  System = 'SYSTEM',
  User = 'USER',
}

export enum DataSource {
  Survey = 'SURVEY',
  Treatment = 'TREATMENT',
  User = 'USER',
  Comment = 'COMMENT',
  Patient = 'PATIENT',
  Checklist = 'CHECKLIST',
}

export enum DateFormat {
  Mdy = 'MDY',
  Dmy = 'DMY',
  Iso8601 = 'ISO8601',
  IsoMdy = 'ISO_MDY',
  IsoDmy = 'ISO_DMY',
}

export type DeleteAllTreatmentStageFromRootInput = {
  treatmentId: Scalars['ObjectId'];
};

export type DeleteCalendarConfigInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
};

export type DeleteCommentInput = {
  commentId: Scalars['ObjectId'];
};

export type DeleteConditionSettingInput = {
  condId: Scalars['ObjectId'];
};

export type DeleteCptCodeInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
};

export type DeleteFollowUpTypeInput = {
  followUpTypeId: Scalars['ObjectId'];
};

export type DeleteGroupTreatmentInput = {
  groupTreatmentId: Scalars['ObjectId'];
};

export type DeleteOrganizationInput = {
  orgId: Scalars['ObjectId'];
};

export type CreateDefaultOrganizationInput = {
  organization: Scalars['ObjectId'];
};

export type DeletePatientFollowInput = {
  _id: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
};

export type DeletePatientInput = {
  patientId: Scalars['ObjectId'];
};

export type DeletePatientNoteInput = {
  noteId: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
  subNoteId?: Maybe<Scalars['ObjectId']>;
};

export type DeletePatientNoteTypeInput = {
  patientNoteTypeId: Scalars['ObjectId'];
};

export type DeletePatientStageInput = {
  patientId: Scalars['ObjectId'];
  condStageId: Scalars['ObjectId'];
  conditionId: Scalars['ObjectId'];
};

export type DeleteProviderInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
};

export type DeleteRecordInput = {
  recordId?: Maybe<Scalars['ObjectId']>;
  cptCodeId?: Maybe<Scalars['ObjectId']>;
  patient?: Maybe<Scalars['ObjectId']>;
};

export type DeleteSurveyResultInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
};

export type DeleteTagInput = {
  tagId: Scalars['ObjectId'];
};

export type DeleteTreatmentRegimenInput = {
  regimenId: Scalars['ObjectId'];
};

export type DeleteTreatmentRes = {
  __typename?: 'DeleteTreatmentRes';
  status: Scalars['Boolean'];
  data?: Maybe<Array<TreatmentsAssignedRes>>;
};

export type DeleteTreatmentStageInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  treatmentId: Scalars['ObjectId'];
};

export type DetachPatientChecklistInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  checklistResultId: Scalars['ObjectId'];
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type DynamicFormData = {
  _id: Scalars['ObjectId'];
  userId: User;
  organization: Organization;
  formJson: Scalars['String'];
  answersJson: Scalars['String'];
  status: FormStatus;
};

export type EmrLog = {
  __typename?: 'EmrLog';
  _id: Scalars['ObjectId'];
  fileName: Scalars['String'];
  newFileName?: Maybe<Scalars['String']>;
  status: LogStatus;
  patient?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Organization>;
  message?: Maybe<Scalars['String']>;
  fieldNote?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmrLogRes = {
  __typename?: 'EmrLogRes';
  nodes: Array<EmrLog>;
  meta?: Maybe<MetaRes>;
};

export enum FollowUpStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  NoResponse = 'NO_RESPONSE',
  Retry_1 = 'RETRY_1',
  Retry_2 = 'RETRY_2',
  Retry_3 = 'RETRY_3',
  Retry_4 = 'RETRY_4',
  Retry_5 = 'RETRY_5',
}

export type FollowUpTypeRes = {
  __typename?: 'FollowUpTypeRes';
  nodes: Array<FollowUpTypeSetting>;
  meta?: Maybe<MetaRes>;
};

export type FollowUpTypeSetting = {
  __typename?: 'FollowUpTypeSetting';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  treatment?: Maybe<Array<Treatment>>;
  folder?: Maybe<Array<Treatment>>;
  organization?: Maybe<Organization>;
  favorites?: Maybe<Array<User>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  createdBy: User;
  status: FollowUpTypeStatus;
};

export enum FollowUpTypeStatus {
  System = 'SYSTEM',
  Treatment = 'TREATMENT',
  Folder = 'FOLDER',
}

export enum FormStatus {
  New = 'NEW',
  Reviewed = 'REVIEWED',
  Deleted = 'DELETED',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
}

export type GetAPatientChecklistInput = {
  patientChecklistId: Scalars['ObjectId'];
  checklistResultId: Scalars['ObjectId'];
};

export type GetAssignTreatmentInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ObjectId']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isHideOnHomePage?: Maybe<Scalars['Boolean']>;
  treatmentIds?: Maybe<Array<AssignTreatmentInput>>;
};

export type GetAssociatedFollowUpTypesInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  treatmentId?: Maybe<Scalars['ObjectId']>;
  favorites?: Maybe<Scalars['Boolean']>;
  isNotFolder?: Maybe<Scalars['Boolean']>;
  isFilterList?: Maybe<Scalars['Boolean']>;
  patientId: Scalars['ObjectId'];
};

export type GetDynamicFormsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  status?: Maybe<FormStatus>;
};

export type IdChatAnalysisRequestInput = {
  _id: Scalars['ObjectId'];
};

export type GetChatAnalysisRequestsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  request_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AnalysisStatus>;
  logged_in?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<Scalars['ObjectId']>;
  organization?: Maybe<Scalars['ObjectId']>;
};

export type GetCalendarConfigInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type GetChecklistForPatientInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
  patientChecklistId?: Maybe<Scalars['ObjectId']>;
  checklistResultId?: Maybe<Scalars['ObjectId']>;
};

export type GetCommentInput = {
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  fromTime?: Maybe<Scalars['DateTime']>;
  treatment: Scalars['ObjectId'];
  parent?: Maybe<Scalars['ObjectId']>;
  cursor?: Maybe<Scalars['String']>;
  fromPatient?: Maybe<Scalars['ObjectId']>;
  broadcastQuesFromTreatments?: Maybe<Scalars['Boolean']>;
};

export type GetConditionSettingInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  conditionName?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type GetCptCodeInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  search?: Maybe<Scalars['String']>;
};

export type GetCptDetailsInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
};

export type GetCurrentPatientStageInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId?: Maybe<Scalars['ObjectId']>;
  conditionId?: Maybe<Scalars['ObjectId']>;
};

export type GetCurrentStageInput = {
  patientId: Scalars['ObjectId'];
  currentTreatmentId?: Maybe<Scalars['ObjectId']>;
};

export type GetEmrInfoInput = {
  filename: Scalars['String'];
  patientId: Scalars['ObjectId'];
  surveyResultId?: Maybe<Scalars['ObjectId']>;
  mrn?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
};

export type GetFollowUpTypesInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  treatmentId?: Maybe<Scalars['ObjectId']>;
  favorites?: Maybe<Scalars['Boolean']>;
  isNotFolder?: Maybe<Scalars['Boolean']>;
  isFilterList?: Maybe<Scalars['Boolean']>;
};

export type GetGroupCommentInput = {
  patientId?: Maybe<Scalars['ObjectId']>;
  isGroupAllInOne?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<CursorGetGroupCommentInput>;
};

export type GetGroupTreatmentInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  treatmentId?: Maybe<Scalars['ObjectId']>;
};

export type GetInboundEmrLogInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<LogStatus>;
  organization?: Maybe<Scalars['ObjectId']>;
};

export type GetListMediaInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  isImage?: Maybe<Scalars['Boolean']>;
  isVideo?: Maybe<Scalars['Boolean']>;
};

export type GetNavigatorsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  search?: Maybe<Scalars['String']>;
  treatmentId: Scalars['ObjectId'];
};

export type GetNodeTreeInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  parentId: Scalars['ObjectId'];
};

export type GetNotificationInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
};

export type GetUnSeenSurveyNotificationInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
};

export type GetOrganizationsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
};

export type GetPatientChecklistByChecklist = {
  checklistId?: Maybe<Scalars['ObjectId']>;
  filterOption?: Maybe<ConditionEmbedded>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type GetPatientChecklistFromChartConfigInput = {
  chartConfigId: Scalars['ObjectId'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  filterOption?: Maybe<ConditionEmbedded>;
};

export type GetPatientChecklistInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
  surveyId?: Maybe<Scalars['ObjectId']>;
  checklistId?: Maybe<Scalars['ObjectId']>;
  resultChecklistId?: Maybe<Scalars['ObjectId']>;
  treatmentId?: Maybe<Scalars['ObjectId']>;
  checklistExists?: Maybe<Scalars['Boolean']>;
};

export type GetPatientDashboardInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  navigatorId?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<DashboardType>;
};

export type GetPatientFollowUpsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
  status?: Maybe<Scalars['JSON']>;
};

export type GetPatientNotesInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
};

export type GetPatientNoteTypesInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type GetPatientsDataInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
};

export type GetPatientsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  dashboardId?: Maybe<Scalars['ObjectId']>;
  condition?: Maybe<Scalars['JSONObject']>;
  order?: Maybe<Scalars['JSONObject']>;
};

export type GetPatientStageInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId?: Maybe<Scalars['ObjectId']>;
  isCurrentStage?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<SortPatientCondStage>;
};

export type AssistantInputType = {
  query: Maybe<Scalars['String']>;
};

export type GetPatientTagsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
};

export type GetPatientTimeRecordInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patient: Scalars['ObjectId'];
};

export type GetPdfsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  pdf_name?: Maybe<Scalars['String']>;
  conversion_type?: Maybe<ConversionType>;
  conversion_status?: Maybe<ConversionStatus>;
};

export type GetProviderInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  providerIds?: Maybe<Array<Scalars['ObjectId']>>;
  provider?: Maybe<Scalars['String']>;
  emrProvider?: Maybe<Scalars['String']>;
};

export type GetPublicTreatment = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ObjectId']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isHideOnHomePage?: Maybe<Scalars['Boolean']>;
};

export type GetReleaseNoteInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  search?: Maybe<Scalars['String']>;
};

export type GetRootTreatmentInputFromNode = {
  currentTreatmentId: Scalars['ObjectId'];
};

export type GetRootTreatmentInputFromPatient = {
  patientId: Scalars['ObjectId'];
};

export type GetShortLinkInput = {
  key: Scalars['String'];
};

export type GetStageChangedLogsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
};

export type GetSurveyDataByPatientInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId?: Maybe<Scalars['ObjectId']>;
  surveyId?: Maybe<Scalars['ObjectId']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  workingStatus?: Maybe<SurveyResultStatus>;
  match?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
};

export type GetSurveyInput = {
  token: Scalars['String'];
};

export type GetSurveyResultGroupInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
};

export type GetSurveyResultsFromChartConfigInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  chartConfigId: Scalars['ObjectId'];
  filterOption?: Maybe<ConditionEmbedded>;
};

export type GetSurveyResultsInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyId: Scalars['ObjectId'];
  surveyVersion?: Maybe<Scalars['Float']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  filterOption?: Maybe<ConditionEmbedded>;
};

export type GetSurveyVersionInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  surveyId?: Maybe<Scalars['ObjectId']>;
  version?: Maybe<Scalars['Float']>;
};

export type GetTagInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  search?: Maybe<Scalars['String']>;
  selectedTag?: Maybe<Array<Scalars['ObjectId']>>;
};

export type GetTagsByPatientInput = {
  tagIds: Array<Scalars['ObjectId']>;
};

export type GetTreatmentInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  slug?: Maybe<Scalars['String']>;
  treatmentIds?: Maybe<Array<AssignTreatmentInput>>;
  isQuickView?: Maybe<Scalars['Boolean']>;
};

export type GetAnalyzerInput = {
  id: Scalars['ObjectId'];
  questions: Array<Scalars['String']>;
};

export type GetAnalyzerInputFromTreatmentDesigner = {
  content: Scalars['String'];
  name: Scalars['String'];
  questions: Array<Scalars['String']>;
};

export type GetTreatmentRegimenInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  search?: Maybe<Scalars['String']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  getTreatment?: Maybe<Scalars['Boolean']>;
};

export type GetTreatmentsForUpdateInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  treatmentId?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<Array<Maybe<TreatmentType>>>;
};

export type GetTreatmentsInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ObjectId']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isHideOnHomePage?: Maybe<Scalars['Boolean']>;
  idNavigator?: Maybe<Scalars['ObjectId']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  favorites?: Maybe<Scalars['ObjectId']>;
  organization?: Maybe<Scalars['ObjectId']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<TreatmentType>>;
  hasSurvey?: Maybe<Scalars['Boolean']>;
  sortByName?: Maybe<OrderBy>;
};

export type GetTreatmentsNotYetAssignedInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  patientId: Scalars['ObjectId'];
  favorites?: Maybe<Scalars['ObjectId']>;
};

export type GetTwilioLogInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  search?: Maybe<Scalars['String']>;
};

export type GetUsersForNavigatorInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  search?: Maybe<Scalars['String']>;
  searchType?: Maybe<SearchType>;
};

export type GetUsersInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
  search?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  organization?: Maybe<Scalars['ObjectId']>;
  status?: Maybe<UserStatus>;
  isUser?: Maybe<Scalars['Boolean']>;
};

export type GetUsersRes = {
  __typename?: 'GetUsersRes';
  nodes: Array<MeRes>;
  meta?: Maybe<MetaRes>;
};

export type GroupTreatmentSetting = {
  __typename?: 'GroupTreatmentSetting';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  treatment?: Maybe<Array<Treatment>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  createdBy: User;
  organization?: Maybe<Organization>;
};

export type GroupTreatmentSettingRes = {
  __typename?: 'GroupTreatmentSettingRes';
  nodes: Array<GroupTreatmentSetting>;
  meta?: Maybe<MetaRes>;
};

export type HideSurveyResultInput = {
  surveyResultId: Scalars['ObjectId'];
  isArchive: Scalars['Boolean'];
  patientId?: Maybe<Scalars['ObjectId']>;
};

export enum HomePageConfig {
  Home = 'Home',
  AllTreatment = 'ALL_TREATMENT',
  MyTreatment = 'MY_TREATMENT',
  MyFavoriteTreatments = 'MY_FAVORITE_TREATMENTS',
  PublicTreatments = 'PUBLIC_TREATMENTS',
  PrivateTreatments = 'PRIVATE_TREATMENTS',
}

export type HomePageTreatmentConfig = {
  __typename?: 'HomePageTreatmentConfig';
  treatmentType?: Maybe<HomePageConfig>;
};

export type HomePageTreatmentConfigInput = {
  treatmentType?: Maybe<HomePageConfig>;
};

export type LeftDrawerConfig = {
  __typename?: 'LeftDrawerConfig';
  treatmentType?: Maybe<HomePageConfig>;
  showInDrawer?: boolean;
};

export type LeftDrawerConfigInput = {
  treatmentType?: Maybe<HomePageConfig>;
  showInDrawer?: boolean;
};

export type IdInputType = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
};

export type TreatmentPDF = {
  _id: Scalars['ObjectId'];
  targetLanguage?: Maybe<Scalars['String']>;
  logoURL?: Maybe<Scalars['String']>;
};

export type UploadedPDF = {
  _id: Scalars['ObjectId'];
  pdf_name: Scalars['String'];
  conversion_status: ConversionStatus;
  conversion_type: ConversionType;
  batchId: Scalars['String'];
  time_took_to_convert: Scalars['String'];
  total_tokens_used: Scalars['Int'];
  number_of_pages: Scalars['Int'];
  content_of_pages: Array<PageContent>;
  sub_docs?: Maybe<Array<SubDoc>>;
  creator: User;
  organization: Organization;
  pdf_url: Scalars['String'];
  fullmarkdown: Scalars['String'];
};

export type UploadedPDFInput = {
  pdf_name: Scalars['String'];
  conversion_type: ConversionType;
  creatorId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type UpdatePdfInput = {
  _id: Scalars['String'];
  pdfData: Pick<
    UploadedPDF,
    | '_id'
    | 'pdf_name'
    | 'conversion_status'
    | 'conversion_type'
    | 'batchId'
    | 'time_took_to_convert'
    | 'total_tokens_used'
    | 'number_of_pages'
    | 'content_of_pages'
    | 'sub_docs'
    | 'pdf_url'
    | 'fullmarkdown'
  > & {
    creator: Scalars['ObjectId'];
    organization: Scalars['ObjectId'];
  };
};

export type ImportPatientInput = {
  treatmentId: Scalars['ObjectId'];
  tags?: Maybe<Array<PatientTagInput>>;
  dateFormat?: Maybe<DateFormat>;
  isInvite?: Maybe<Scalars['Boolean']>;
  delimiter?: Maybe<Scalars['String']>;
};

export type InactiveUserInput = {
  userId: Scalars['ObjectId'];
};

export type InputOpenDialogStopTimeRecord = {
  status?: Maybe<Scalars['Boolean']>;
};

export type InputRecording = {
  _id: Scalars['String'];
  startedAt?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['String']>;
  idPatient: Scalars['String'];
  idCptCode: Scalars['String'];
};

export type InviteContributorInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  userIds: Array<Scalars['ObjectId']>;
};

export type LinkTreatmentInput = {
  subTreatmentId: Scalars['ObjectId'];
  treatmentStageId: Scalars['ObjectId'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPhoneInput = {
  phone: Scalars['String'];
  password: Scalars['String'];
};

export enum LogStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL',
  Pending = 'PENDING',
  Reject = 'REJECT',
}

export type MatchingPatientInput = {
  emrLogId: Scalars['ObjectId'];
  patientId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  ignoreDuplicate?: Maybe<Scalars['Boolean']>;
};

export type Media = {
  __typename?: 'Media';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  thumbNailsUrls?: Maybe<Array<Scalars['String']>>;
  dashUrl?: Maybe<Scalars['String']>;
  hlsUrl?: Maybe<Scalars['String']>;
  srcUrl: Scalars['String'];
  mimeType: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<User>;
  ownerId: Scalars['ObjectId'];
  organization: Organization;
};

export type MediaInfoInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MediaRes = {
  __typename?: 'MediaRes';
  nodes: Array<Media>;
  meta?: Maybe<MetaRes>;
};

export type MeRes = {
  __typename?: 'MeRes';
  _id: Scalars['ObjectId'];
  email: Scalars['String'];
  secondaryEmail?: Maybe<Array<SecondaryEmailInfo>>;
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  dob?: Maybe<Scalars['DateTime']>;
  mrn?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['JSON']>>;
  setting?: Maybe<ConfigEmbedded>;
  middleName?: Maybe<Scalars['String']>;
  authenticationInfo?: Maybe<Array<AuthInfo>>;
  role: UserRole;
  isBlankAccount?: Maybe<Scalars['Boolean']>;
  isNoEmail?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  loginAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  status: UserStatus;
  tags?: Maybe<Array<Maybe<TagEmbedded>>>;
  patient?: Maybe<Patient>;
  remoteUser?: Maybe<User>;
};

export type MetaRes = {
  __typename?: 'MetaRes';
  totalPage: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activeUser: MeRes;
  addMorePatientNote?: Maybe<PatientNote>;
  addMoreTreatment: FollowUpTypeSetting;
  addMoreTreatmentNoteType: PatientNoteTypeSetting;
  addPatientTimeRecord: PatientCptCode;
  addSecondaryEmail: MeRes;
  addShowingTreatmentSidebar?: Maybe<Scalars['Boolean']>;
  addSubTreatmentsSideBar?: Maybe<Array<Maybe<SubTreatmentsSideBar>>>;
  addUser: User;
  addUserSecondaryEmail: User;
  archiveSurveyResult?: Maybe<Scalars['Boolean']>;
  assignTreatment?: Maybe<AssignTreatmentRes>;
  attachChecklist?: Maybe<Treatment>;
  attachPatientChecklist?: Maybe<PatientChecklistMono>;
  bulkUpdateTreatmentOrder: Scalars['Boolean'];
  bulkUpdateTreatmentTree: Scalars['Boolean'];
  changePassword: User;
  changeSurveyResultOfNote?: Maybe<PatientNote>;
  copyTreatment4SuperAdmin: Scalars['Boolean'];
  createCalendarConfig: Array<ShareTreatmentRes>;
  createCalendarConfigByRegimen: Array<ShareTreatmentRes>;
  createChartConfig: ChartConfig;
  createChartConfigs: Array<ChartConfig>;
  createChecklist: Treatment;
  createComment: CommentExt;
  createConditionSetting: ConditionSetting;
  createCPTCode?: Maybe<CptCode>;
  createDashboard: Dashboard;
  createFolder: Treatment;
  createFollowUpType: FollowUpTypeSetting;
  createGroupTreatment: GroupTreatmentSetting;
  createNewPatient: CreatePatientRes;
  createOrganization: CreateOrgRes;
  createPatientDashboard: PatientDashboardInfoRes;
  createPatientFollowUp: PatientFollowUp;
  createPatientNote: PatientNote;
  createPatientNoteType: PatientNoteTypeSetting;
  createPatientStage: PatientCondStage;
  createPatientTag: AddTagRes;
  createProvider: ProviderSetting;
  createQRCodeTreatment?: Maybe<Treatment>;
  createSurvey: Treatment;
  createSurveyResult: SurveyResult;
  createSurveyResultByNavigator: SurveyResult;
  createSurveyWithToken?: Maybe<SurveyResult>;
  createTag: Tag;
  createTreatment: Treatment;
  createTreatmentRegimen: TreatmentRegimen;
  createTreatmentStages: Array<TreatmentStage>;
  deleteAllTreatmentStageFromRoot: Scalars['Boolean'];
  deleteCalendarConfig: Scalars['Boolean'];
  deleteChartConfig: Scalars['Boolean'];
  deleteChecklist?: Maybe<Scalars['Boolean']>;
  deleteComment: Scalars['Boolean'];
  deleteConditionSetting: Scalars['Boolean'];
  deleteCPTCode: Scalars['Boolean'];
  deleteDashboard: Scalars['Boolean'];
  deleteFollowUpType: Scalars['Boolean'];
  deleteGroupTreatment: Scalars['Boolean'];
  deleteMedia: Scalars['Boolean'];
  deleteOrganization: Scalars['Boolean'];
  createDefaultOrganization: Scalars['Boolean'];
  deletePatient: Scalars['Boolean'];
  deletePatientChecklist: Scalars['Boolean'];
  deletePatientDashboard: Scalars['Boolean'];
  deletePatientFollowUp: Scalars['Boolean'];
  deletePatientNote: Scalars['Boolean'];
  deletePatientNoteType: Scalars['Boolean'];
  deletePatientStage: Scalars['Boolean'];
  deletePatientTimeRecord: Scalars['Boolean'];
  deleteProvider: Scalars['Boolean'];
  deleteSurveyResult: Scalars['Boolean'];
  deleteTag: Scalars['Boolean'];
  deleteTreatment?: Maybe<DeleteTreatmentRes>;
  deleteTreatmentRegimen: Scalars['Boolean'];
  deleteTreatmentStage: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  detachChecklist?: Maybe<Treatment>;
  detachPatientChecklist?: Maybe<PatientChecklistMono>;
  emrMatchingPatient: CreatePatientRes;
  exportEmrInfo: Scalars['Boolean'];
  facebookAuth: MeRes;
  googleAuth: MeRes;
  handleFavoriteFollowUpType: FollowUpTypeSetting;
  handleFavoriteTreatment?: Maybe<Scalars['Boolean']>;
  handleRepresentativeInfo: Scalars['Boolean'];
  importPatient: Scalars['Boolean'];
  importPatientDashboard?: Maybe<PatientDashboard>;
  inactiveUser: Scalars['Boolean'];
  inviteContributor: Scalars['Boolean'];
  linkTreatment: TreatmentStage;
  login?: Maybe<MeRes>;
  loginPhone?: Maybe<MeRes>;
  logout: Scalars['Boolean'];
  publishedPatientNote?: Maybe<PatientNote>;
  register: User;
  registerPhoneUser: User;
  removeContributor: Scalars['Boolean'];
  removeOneTreatment?: Maybe<FollowUpTypeSetting>;
  removeOneTreatmentNoteType?: Maybe<PatientNoteTypeSetting>;
  removePatientNote?: Maybe<PatientNote>;
  removeSecondaryEmail: MeRes;
  removeUserSecondaryEmail: MeRes;
  replaceTag: Scalars['Boolean'];
  replaceTreatment?: Maybe<Scalars['Boolean']>;
  resendEmailVerify: Scalars['Boolean'];
  resendOTP: Scalars['Boolean'];
  resendSurvey: Array<ShareTreatmentRes>;
  resetPassword: Scalars['Boolean'];
  seenComment: Scalars['Boolean'];
  seenNotification: Scalars['Float'];
  surveyNotification: Scalars['Boolean'];
  sendTreatmentReminder: Scalars['Boolean'];
  setDefaultTreatment?: Maybe<Scalars['Boolean']>;
  setNavigator?: Maybe<User>;
  setPrimaryEmail: MeRes;
  setShowDashboards: Array<Dashboard>;
  setUserPrimaryEmail: MeRes;
  shareAssignTreatment: Array<ShareTreatmentRes>;
  shareDashboard: Scalars['Boolean'];
  shareMultiTreatmentToPatient: Array<ShareTreatmentRes>;
  signedPatientNote?: Maybe<PatientNote>;
  stopPatientTimeRecord: Scalars['Boolean'];
  syncAssignTreatment: Scalars['Boolean'];
  takeOwnership: Scalars['Boolean'];
  toogleIsOpenDialogStopTimeRecord?: Maybe<Scalars['Boolean']>;
  toogleSaveRecording?: Maybe<TSaveRecording>;
  toogleShowCommentFrom?: Maybe<Scalars['Boolean']>;
  toogleShowMenuAdministration?: Maybe<Scalars['Boolean']>;
  toogleShowMenuSetting?: Maybe<Scalars['Boolean']>;
  toogleShowQuickView?: Maybe<Scalars['Boolean']>;
  toogleShowSubTreatmentsSideBar?: Maybe<Scalars['Boolean']>;
  toogleStatusHasingRecord?: Maybe<StatusRecordStop>;
  unlinkFromTreatment: Scalars['Boolean'];
  unlinkTreatment: TreatmentStage;
  unReferenceTreatment: Scalars['Boolean'];
  unTagOnPatient: Scalars['Boolean'];
  updateChartConfig?: Maybe<ChartConfig>;
  updateChecklist?: Maybe<Treatment>;
  updateChecklistResult?: Maybe<PatientChecklistMono>;
  updateConditionSetting: ConditionSetting;
  updateCPTCode?: Maybe<CptCode>;
  updateDashboard: Dashboard;
  updateEmrLog: EmrLog;
  updateFolderInfo: Treatment;
  updateFollowUpType: FollowUpTypeSetting;
  updateGridChart?: Maybe<ChartConfig>;
  updateGroupTreatment: GroupTreatmentSetting;
  updateMedia?: Maybe<Media>;
  updateOrderDashboard: Scalars['Boolean'];
  updateOrganizationInfo: Organization;
  updatePatientDashboard?: Maybe<PatientDashboardInfoRes>;
  updatePatientFollowUp?: Maybe<PatientFollowUp>;
  updatePatientInfo: Patient;
  updatePatientNote?: Maybe<PatientNote>;
  updatePatientNoteType: PatientNoteTypeSetting;
  updatePatientStage: PatientCondStage;
  updatePatientTimeRecord: Scalars['Boolean'];
  updatePatientTimeRecordStatus: PatientTimeReportRes;
  updateProvider: ProviderSetting;
  updateStageChangedLog?: Maybe<StageChangedLog>;
  updateStageChangedLogTime?: Maybe<StageChangedLog>;
  updateSurveyInfo: Treatment;
  updateSurveyResult?: Maybe<SurveyResult>;
  updateTagForTreatmentTree: Scalars['Boolean'];
  updateTagInfo: Tag;
  updateTreatmentInfo: Treatment;
  updateTreatmentOrder: Treatment;
  updateTreatmentRegimen: TreatmentRegimen;
  updateTreatmentStage: TreatmentStage;
  updateTreatmentStagesEmbed?: Maybe<Treatment>;
  updateTreatmentTree: Scalars['Boolean'];
  updateUserInfo?: Maybe<MeRes>;
  updateUserProfile: MeRes;
  uploadFile?: Maybe<Media>;
  uploadSurveyImage?: Maybe<SurveyImageRes>;
  verifyEmailForgotPassword: Scalars['Boolean'];
  verifyPhoneNumberForgotPassword: Scalars['Boolean'];
  verifyPhoneUser: MeRes;
};

export type MutationActiveUserArgs = {
  params: ActiveUserInput;
};

export type MutationAddMorePatientNoteArgs = {
  params: AddMorePatientNoteInput;
};

export type MutationAddMoreTreatmentArgs = {
  params: AddMoreTreatmentInput;
};

export type MutationAddMoreTreatmentNoteTypeArgs = {
  params: AddMoreTreatmentNoteTypeInput;
};

export type MutationAddPatientTimeRecordArgs = {
  params: AddTimeRecordInput;
};

export type MutationAddSecondaryEmailArgs = {
  params: AddSecondaryEmailInput;
};

export type MutationAddShowingTreatmentSidebarArgs = {
  input?: Maybe<Scalars['String']>;
};

export type MutationAddSubTreatmentsSideBarArgs = {
  input?: Maybe<SubTreatments>;
};

export type MutationAddUserArgs = {
  params: AddUserInput;
};

export type MutationAddUserSecondaryEmailArgs = {
  params: AddUserSecondaryEmailInput;
};

export type MutationArchiveSurveyResultArgs = {
  params: HideSurveyResultInput;
};

export type MutationAssignTreatmentArgs = {
  params: AssignInput;
};

export type MutationAttachChecklistArgs = {
  params: AttachChecklistInput;
};

export type MutationAttachPatientChecklistArgs = {
  params: UpdatePatientChecklistInput;
};

export type MutationBulkUpdateTreatmentOrderArgs = {
  params: Array<UpdateTreatmentOrderInput>;
};

export type MutationBulkUpdateTreatmentTreeArgs = {
  params: Array<UpdateTreatmentTreeInput>;
};

export type MutationChangePasswordArgs = {
  params: ChangePasswordInput;
};

export type MutationChangeSurveyResultOfNoteArgs = {
  params: ChangeSurveyResultInput;
};

export type MutationCopyTreatment4SuperAdminArgs = {
  params: CopyTreatmentInput;
};

export type MutationCreateCalendarConfigArgs = {
  params: CreateCalendarConfigInput;
};

export type MutationCreateCalendarConfigByRegimenArgs = {
  params: CreateCalendarConfigByRegimenInput;
};

export type MutationCreateChartConfigArgs = {
  params: CreateChartConfigInput;
};

export type MutationCreateChartConfigsArgs = {
  params: CreateChartConfigsInput;
};

export type MutationCreateChecklistArgs = {
  params: CreateChecklistInput;
};

export type MutationCreateCommentArgs = {
  params: CreateCommentInput;
};

export type MutationCreateConditionSettingArgs = {
  params: CreateConditionSettingInput;
};

export type MutationCreateCptCodeArgs = {
  params: CreateCptCodeInput;
};

export type MutationCreateDashboardArgs = {
  params: CreateDashboardInput;
};

export type MutationCreateFolderArgs = {
  params: CreateFolderInput;
};

export type MutationCreateFollowUpTypeArgs = {
  params: CreateFollowUpTypeInput;
};

export type MutationCreateGroupTreatmentArgs = {
  params: CreateGroupTreatmentInput;
};

export type MutationCreateNewPatientArgs = {
  params: CreateNewPatientInput;
};

export type MutationCreateOrganizationArgs = {
  params: CreateOrganizationInput;
};

export type MutationCreatePatientDashboardArgs = {
  params: CreatePatientDashboardInput;
};

export type MutationCreatePatientFollowUpArgs = {
  params: CreatePatientFollowUpInput;
};

export type MutationCreatePatientNoteArgs = {
  params: CreatePatientNoteInput;
};

export type MutationCreatePatientNoteTypeArgs = {
  params: CreatePatientNoteTypeInput;
};

export type MutationCreatePatientStageArgs = {
  params: CreatePatientStageInput;
};

export type MutationCreatePatientTagArgs = {
  params: CreateTagForPatientInput;
};

export type MutationCreateProviderArgs = {
  params: CreateProviderInput;
};

export type MutationCreateQrCodeTreatmentArgs = {
  params: IdInputType;
};

export type MutationCreateSurveyArgs = {
  params: CreateSurveyInput;
};

export type MutationCreateSurveyResultArgs = {
  params: CreateSurveyResultInput;
};

export type MutationCreateSurveyResultByNavigatorArgs = {
  params: NavigatorCreateSurveyResultInput;
};

export type MutationCreateSurveyWithTokenArgs = {
  params: CreateSurveyResultWithTokenInput;
};

export type MutationCreateTagArgs = {
  params: CreateTagInput;
};

export type MutationCreateTreatmentArgs = {
  params: CreateTreatmentInput;
};

export type MutationCreateTreatmentRegimenArgs = {
  params: CreateTreatmentRegimenInput;
};

export type MutationCreateTreatmentStagesArgs = {
  params: CreateTreatmentStagesInput;
};

export type MutationDeleteAllTreatmentStageFromRootArgs = {
  params: DeleteAllTreatmentStageFromRootInput;
};

export type MutationDeleteCalendarConfigArgs = {
  params: DeleteCalendarConfigInput;
};

export type MutationDeleteChartConfigArgs = {
  params: IdInputType;
};

export type MutationDeleteChecklistArgs = {
  params: IdInputType;
};

export type MutationDeleteCommentArgs = {
  params: DeleteCommentInput;
};

export type MutationDeleteConditionSettingArgs = {
  params: DeleteConditionSettingInput;
};

export type MutationDeleteCptCodeArgs = {
  params: DeleteCptCodeInput;
};

export type MutationDeleteDashboardArgs = {
  params: IdInputType;
};

export type MutationDeleteFollowUpTypeArgs = {
  params: DeleteFollowUpTypeInput;
};

export type MutationDeleteGroupTreatmentArgs = {
  params: DeleteGroupTreatmentInput;
};

export type MutationDeleteMediaArgs = {
  params: IdInputType;
};

export type MutationDeleteOrganizationArgs = {
  params: DeleteOrganizationInput;
};

export type MutationDeletePatientArgs = {
  params: DeletePatientInput;
};

export type MutationDeletePatientChecklistArgs = {
  params: DetachPatientChecklistInput;
};

export type MutationDeletePatientDashboardArgs = {
  params: IdInputType;
};

export type MutationDeletePatientFollowUpArgs = {
  params: DeletePatientFollowInput;
};

export type MutationDeletePatientNoteArgs = {
  params: DeletePatientNoteInput;
};

export type MutationDeletePatientNoteTypeArgs = {
  params: DeletePatientNoteTypeInput;
};

export type MutationDeletePatientStageArgs = {
  params: DeletePatientStageInput;
};

export type MutationDeletePatientTimeRecordArgs = {
  params: DeleteRecordInput;
};

export type MutationDeleteProviderArgs = {
  params: DeleteProviderInput;
};

export type MutationDeleteSurveyResultArgs = {
  params: DeleteSurveyResultInput;
};

export type MutationDeleteTagArgs = {
  params: DeleteTagInput;
};

export type MutationDeleteTreatmentArgs = {
  params: IdInputType;
};

export type MutationDeleteTreatmentRegimenArgs = {
  params: DeleteTreatmentRegimenInput;
};

export type MutationDeleteTreatmentStageArgs = {
  params: DeleteTreatmentStageInput;
};

export type MutationDeleteUserArgs = {
  params: IdInputType;
};

export type MutationDetachChecklistArgs = {
  params: AttachChecklistInput;
};

export type MutationDetachPatientChecklistArgs = {
  params: DetachPatientChecklistInput;
};

export type MutationEmrMatchingPatientArgs = {
  params: MatchingPatientInput;
};

export type MutationExportEmrInfoArgs = {
  params: GetEmrInfoInput;
  file: Scalars['Upload'];
};

export type MutationFacebookAuthArgs = {
  idToken: Scalars['String'];
};

export type MutationGoogleAuthArgs = {
  idToken: Scalars['String'];
};

export type MutationHandleFavoriteFollowUpTypeArgs = {
  params: UpdateFavoriteFollowUpTypeInput;
};

export type MutationHandleFavoriteTreatmentArgs = {
  params: UpdateToFavoriteInput;
};

export type MutationHandleRepresentativeInfoArgs = {
  params: RepresentativeInput;
};

export type MutationImportPatientArgs = {
  params: ImportPatientInput;
  file: Scalars['Upload'];
};

export type MutationImportPatientDashboardArgs = {
  params: IdInputType;
};

export type MutationInactiveUserArgs = {
  params: InactiveUserInput;
};

export type MutationInviteContributorArgs = {
  params: InviteContributorInput;
};

export type MutationLinkTreatmentArgs = {
  params: LinkTreatmentInput;
};

export type MutationLoginArgs = {
  params: LoginInput;
};

export type MutationLoginPhoneArgs = {
  params: LoginPhoneInput;
};

export type MutationPublishedPatientNoteArgs = {
  params: UpdateStatusPatientNoteInput;
};

export type MutationRegisterArgs = {
  params: RegisterInput;
};

export type MutationRegisterPhoneUserArgs = {
  params: RegisterPhoneUser;
};

export type MutationRemoveContributorArgs = {
  params: TakeOwnershipInput;
};

export type MutationRemoveOneTreatmentArgs = {
  params: RemoveOneTreatmentInput;
};

export type MutationRemoveOneTreatmentNoteTypeArgs = {
  params: RemoveOneTreatmentNoteTypeInput;
};

export type MutationRemovePatientNoteArgs = {
  params: DeletePatientNoteInput;
};

export type MutationRemoveSecondaryEmailArgs = {
  params: RemoveSecondaryEmailInput;
};

export type MutationRemoveUserSecondaryEmailArgs = {
  params: RemoveUserSecondaryEmailInput;
};

export type MutationReplaceTagArgs = {
  params: ReplaceTagInput;
};

export type MutationReplaceTreatmentArgs = {
  params: ReplaceTreatmentInput;
};

export type MutationResendEmailVerifyArgs = {
  params: ResendAccountEmailVerify;
};

export type MutationResendOtpArgs = {
  params: ResendOtpInput;
};

export type MutationResendSurveyArgs = {
  params: SurveyResenderInput;
};

export type MutationResetPasswordArgs = {
  params: ResetPasswordInput;
};

export type MutationSeenCommentArgs = {
  params: SeenCommentInput;
};

export type MutationSeenNotificationArgs = {
  params: SeenNotificationInput;
};

export type MutationSendTreatmentReminderArgs = {
  params: TreatmentReminderInput;
};

export type MutationSetDefaultTreatmentArgs = {
  treatmentId: Scalars['ObjectId'];
};

export type MutationSetNavigatorArgs = {
  params: SetRoleInput;
};

export type MutationSetPrimaryEmailArgs = {
  params: SetPrimaryEmailInput;
};

export type MutationSetShowDashboardsArgs = {
  params: Array<UpdateDashboardInput>;
};

export type MutationSetUserPrimaryEmailArgs = {
  params: SetUserPrimaryEmailInput;
};

export type MutationShareAssignTreatmentArgs = {
  params: ShareTreatmentInput;
};

export type MutationShareDashboardArgs = {
  params: ShareDashboardInput;
};

export type MutationShareMultiTreatmentToPatientArgs = {
  params: SharedMultiTreatmentInput;
};

export type MutationSignedPatientNoteArgs = {
  params: UpdateStatusPatientNoteInput;
};

export type MutationStopPatientTimeRecordArgs = {
  params: StopRecordInput;
};

export type MutationSyncAssignTreatmentArgs = {
  params: Array<AssignTreatmentInput>;
};

export type MutationTakeOwnershipArgs = {
  params: TakeOwnershipInput;
};

export type MutationToogleIsOpenDialogStopTimeRecordArgs = {
  input?: Maybe<InputOpenDialogStopTimeRecord>;
};

export type MutationToogleSaveRecordingArgs = {
  input?: Maybe<InputRecording>;
};

export type MutationToogleShowCommentFromArgs = {
  input?: Maybe<Scalars['Boolean']>;
};

export type MutationToogleShowMenuAdministrationArgs = {
  input?: Maybe<Scalars['Boolean']>;
};

export type MutationToogleShowMenuSettingArgs = {
  input?: Maybe<Scalars['Boolean']>;
};

export type MutationToogleShowQuickViewArgs = {
  input?: Maybe<Scalars['Boolean']>;
};

export type MutationToogleShowSubTreatmentsSideBarArgs = {
  input?: Maybe<StatusShowSubSideBar>;
};

export type MutationToogleStatusHasingRecordArgs = {
  input?: Maybe<StatusRecord>;
};

export type MutationUnlinkFromTreatmentArgs = {
  params: UnlinkFromTreatmentInput;
};

export type MutationUnlinkTreatmentArgs = {
  params: UnlinkTreatmentInput;
};

export type MutationUnReferenceTreatmentArgs = {
  params: UnReferenceTreatmentInput;
};

export type MutationUnTagOnPatientArgs = {
  params: UnTagOnPatientInput;
};

export type MutationUpdateChartConfigArgs = {
  params: UpdateChartConfigInput;
};

export type MutationUpdateChecklistArgs = {
  params: UpdateChecklistInput;
};

export type MutationUpdateChecklistResultArgs = {
  params: UpdateChecklistResultInput;
};

export type MutationUpdateConditionSettingArgs = {
  params: UpdateConditionSettingInput;
};

export type MutationUpdateCptCodeArgs = {
  params: UpdateCptCodeInput;
};

export type MutationUpdateDashboardArgs = {
  params: UpdateDashboardInput;
};

export type MutationUpdateEmrLogArgs = {
  params: IdInputType;
};

export type MutationUpdateFolderInfoArgs = {
  params: UpdateFolderInfoInput;
};

export type MutationUpdateFollowUpTypeArgs = {
  params: UpdateFollowUpTypeInput;
};

export type MutationUpdateGridChartArgs = {
  params: UpdateGridChartInput;
};

export type MutationUpdateGroupTreatmentArgs = {
  params: UpdateGroupTreatmentInput;
};

export type MutationUpdateMediaArgs = {
  params: MediaInfoInput;
};

export type MutationUpdateOrderDashboardArgs = {
  params: UpdateOrderDashboardInput;
};

export type MutationUpdateOrganizationInfoArgs = {
  params: UpdateOrganizationInput;
};

export type MutationUpdatePatientDashboardArgs = {
  params: UpdatePatientDashboardInput;
};

export type MutationUpdatePatientFollowUpArgs = {
  params: UpdatePatientFollowUpInput;
};

export type MutationUpdatePatientInfoArgs = {
  params: UpdatePatientInfoInput;
};

export type MutationUpdatePatientNoteArgs = {
  params: UpdatePatientNoteInput;
};

export type MutationUpdatePatientNoteTypeArgs = {
  params: UpdatePatientNoteTypeInput;
};

export type MutationUpdatePatientStageArgs = {
  params: UpdatePatientStageInput;
};

export type MutationUpdatePatientTimeRecordArgs = {
  params: UpdateRecordsInput;
};

export type MutationUpdatePatientTimeRecordStatusArgs = {
  params: UpdatePatientTimeRecordStatusInput;
};

export type MutationUpdateProviderArgs = {
  params: UpdateProviderInput;
};

export type MutationUpdateStageChangedLogArgs = {
  params: UpdateStageChangedLogInput;
};

export type MutationUpdateStageChangedLogTimeArgs = {
  params: UpdateStageChangedLogTimeInput;
};

export type MutationUpdateSurveyInfoArgs = {
  params: UpdateSurveyInfoInput;
};

export type MutationUpdateSurveyResultArgs = {
  params: UpdateSurveyResultInput;
};

export type MutationUpdateTagForTreatmentTreeArgs = {
  params: UpdateTagForTreatmentTreeInput;
};

export type MutationUpdateTagInfoArgs = {
  params: UpdateTagInput;
};

export type MutationUpdateTreatmentInfoArgs = {
  params: UpdateTreatmentInfoInput;
};

export type MutationUpdateTreatmentOrderArgs = {
  params: UpdateTreatmentOrderInput;
};

export type MutationUpdateTreatmentRegimenArgs = {
  params: UpdateTreatmentRegimenInput;
};

export type MutationUpdateTreatmentStageArgs = {
  params: UpdateTreatmentStageInput;
};

export type MutationUpdateTreatmentStagesEmbedArgs = {
  params: UpdateTreatmentStagesEmbedInput;
};

export type MutationUpdateTreatmentTreeArgs = {
  params: UpdateTreatmentTreeInput;
};

export type MutationUpdateUserInfoArgs = {
  params: UpdateUserInfoInput;
};

export type MutationUpdateUserProfileArgs = {
  params: UpdateUserInput;
};

export type MutationUploadFileArgs = {
  params: UploadInput;
  file: Scalars['Upload'];
};

export type MutationUploadSurveyImageArgs = {
  file: Scalars['Upload'];
};

export type MutationVerifyEmailForgotPasswordArgs = {
  params: VerifyEmailForgotPasswordInput;
};

export type MutationVerifyPhoneNumberForgotPasswordArgs = {
  params: VerifyPhoneNumberForgotPasswordInput;
};

export type MutationVerifyPhoneUserArgs = {
  params: ActiveUserOtpInput;
};

export type NavigatorCreateSurveyResultInput = {
  data: Scalars['JSON'];
  survey: Scalars['ObjectId'];
  completedStatus: CompletedStatus;
  patientId: Scalars['ObjectId'];
};

export type NoteEmbedded = {
  __typename?: 'NoteEmbedded';
  _id: Scalars['ObjectId'];
  description: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  type?: Maybe<PatientNoteTypeSetting>;
  notedAt: Scalars['DateTime'];
  organization: Organization;
  updatedBy: User;
  status: NoteStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export enum NoteStatus {
  Draft = 'DRAFT',
  Signed = 'SIGNED',
  Published = 'PUBLISHED',
}

export type NoteTypeInput = {
  id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ObjectId'];
  receiver: User;
  seen: Scalars['Boolean'];
  msg?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NavigatorNotification = {
  __typename?: 'NavigatorNotification';
  _id: Scalars['ObjectId'];
  owner: Patient;
  survey?: Maybe<Treatment>;
  treatment?: Maybe<Treatment>;
  organization: Organization;
  data?: Maybe<Scalars['JSON']>;
  status?: Maybe<ShareStatus>;
  workingStatus?: Maybe<SurveyResultStatus>;
  sentEmail?: Maybe<Scalars['String']>;
  surveyName?: Maybe<Scalars['String']>;
  isSeen?: Maybe<Scalars['Boolean']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answerDateTime?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  createdBy?: Maybe<User>;
  completedStatus?: Maybe<CompletedStatus>;
  source?: Maybe<SurveySource>;
  surveyVersion?: Maybe<Scalars['Float']>;
  encryptedData?: Maybe<Scalars['ObjectId']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  resultId?: Maybe<SurveyResult>;
};

export type NotificationRes = {
  __typename?: 'NotificationRes';
  nodes: Array<Notification>;
  meta?: Maybe<MetaRes>;
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Organization = {
  __typename?: 'Organization';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  sftpUser?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  queueId?: Maybe<Scalars['String']>;
  inboundPath?: Maybe<Scalars['String']>;
  outboundPath?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  defaultTreatment?: Maybe<Treatment>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  createdBy: User;
};

export type OrganizationRes = {
  __typename?: 'OrganizationRes';
  nodes: Array<Organization>;
  meta?: Maybe<MetaRes>;
};

export type OTPForNonUserFlow = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  otp: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  flowId: Scalars['ObjectId'];
};

export type PageContent = {
  page_no: Scalars['String'];
  content: Scalars['String'];
  tokens_per_page: Scalars['Int'];
  imagePath: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
};

export type PaginateInputType = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
};

export type Patient = {
  __typename?: 'Patient';
  _id: Scalars['ObjectId'];
  user?: Maybe<User>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  address?: Maybe<Scalars['String']>;
  race?: Maybe<Race>;
  provider?: Maybe<Array<ProviderSetting>>;
  emrProvider?: Maybe<Array<ProviderSetting>>;
  dob?: Maybe<Scalars['DateTime']>;
  mrn?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['JSON']>>;
  patientConfig?: Maybe<PatientConfig>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  status: UserStatus;
  tags?: Maybe<Array<Maybe<TagEmbedded>>>;
  organization?: Maybe<Organization>;
};

export type PatientChecklistEmbedded = {
  __typename?: 'PatientChecklistEmbedded';
  _id: Scalars['ObjectId'];
  checklist?: Maybe<Treatment>;
  checklistAvailable?: Maybe<Treatment>;
  originChecklist?: Maybe<Treatment>;
  resultChecklist?: Maybe<Array<ResultChecklist>>;
  status: ChecklistStatus;
  checklistResult?: Maybe<Scalars['JSON']>;
  organization: Organization;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientChecklistMono = {
  __typename?: 'PatientChecklistMono';
  _id: Scalars['ObjectId'];
  user: Patient;
  treatment?: Maybe<Treatment>;
  survey?: Maybe<Treatment>;
  patientChecklists?: Maybe<PatientChecklistEmbedded>;
  organization?: Maybe<Organization>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  patientChecklistDatas?: Maybe<PatientChecklistEmbedded>;
};

export type PatientChecklistRes = {
  __typename?: 'PatientChecklistRes';
  nodes: Array<PatientChecklistMono>;
  meta?: Maybe<MetaRes>;
};

export type PatientCondStage = {
  __typename?: 'PatientCondStage';
  _id: Scalars['ObjectId'];
  patient: Patient;
  condition?: Maybe<ConditionSetting>;
  stageId: Scalars['ObjectId'];
  color?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  createdBy: User;
  updatedBy: User;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type PatientCondStageRes = {
  __typename?: 'PatientCondStageRes';
  nodes: Array<PatientCondStage>;
  meta?: Maybe<MetaRes>;
};

export type PatientConfig = {
  __typename?: 'PatientConfig';
  contactConfig?: Maybe<ContactConfig>;
};

export type PatientCptCode = {
  __typename?: 'PatientCPTCode';
  _id: Scalars['ObjectId'];
  cptCode: CptCode;
  patient: Patient;
  owner: User;
  organization: Organization;
  stoppedAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  state: PatientCptCodeStatus;
  patientNoteTypes?: Maybe<Array<Maybe<PatientNoteTypeEmbedded>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export enum PatientCptCodeStatus {
  Open = 'OPEN',
  Running = 'RUNNING',
  Pause = 'PAUSE',
  Stopped = 'STOPPED',
}

export type PatientDashboard = {
  __typename?: 'PatientDashboard';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['ObjectId'];
  config?: Maybe<Scalars['JSON']>;
  condition?: Maybe<Scalars['JSON']>;
  isShow: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: Organization;
  type: DashboardType;
  isQuickView?: Maybe<Scalars['Boolean']>;
};

export type PatientDashboardConfig = {
  __typename?: 'PatientDashboardConfig';
  sort?: Maybe<Scalars['String']>;
};

export type PatientDashboardConfigInput = {
  sort?: Maybe<Scalars['String']>;
};

export type PatientDashboardInfoRes = {
  __typename?: 'PatientDashboardInfoRes';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['ObjectId'];
  config?: Maybe<Scalars['JSON']>;
  condition?: Maybe<Scalars['JSON']>;
  isShow: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: Organization;
  type: DashboardType;
  isQuickView?: Maybe<Scalars['Boolean']>;
  numberOfPatient?: Maybe<Scalars['Int']>;
};

export type PatientDataRes = {
  __typename?: 'PatientDataRes';
  _id: Scalars['ObjectId'];
  result?: Maybe<Scalars['JSON']>;
  survey?: Maybe<Scalars['ObjectId']>;
  status?: Maybe<ShareStatus>;
  completedStatus?: Maybe<CompletedStatus>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type PatientFollowUp = {
  __typename?: 'PatientFollowUp';
  _id: Scalars['ObjectId'];
  note: Scalars['String'];
  owner: User;
  ownerId: Scalars['ObjectId'];
  type?: Maybe<FollowUpTypeSetting>;
  status: FollowUpStatus;
  user: Patient;
  userId: Scalars['ObjectId'];
  organization?: Maybe<Organization>;
  updatedBy: User;
  followUpDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientFollowUpRes = {
  __typename?: 'PatientFollowUpRes';
  nodes: Array<PatientFollowUp>;
  meta?: Maybe<MetaRes>;
};

export type PatientInfoRes = {
  __typename?: 'PatientInfoRes';
  nodes: Array<Patient>;
  meta?: Maybe<MetaRes>;
};

export type PatientNote = {
  __typename?: 'PatientNote';
  _id: Scalars['ObjectId'];
  notes?: Maybe<Array<NoteEmbedded>>;
  owner?: Maybe<User>;
  ownerId: Scalars['ObjectId'];
  user: Patient;
  userId: Scalars['ObjectId'];
  updatedBy: User;
  surveyResult?: Maybe<SurveyResult>;
  survey?: Maybe<Treatment>;
  organization?: Maybe<Organization>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNoteRes = {
  __typename?: 'PatientNoteRes';
  nodes: Array<PatientNote>;
  meta?: Maybe<MetaRes>;
};

export type PatientNoteTypeEmbedded = {
  __typename?: 'PatientNoteTypeEmbedded';
  id: PatientNoteTypeSetting;
  description?: Maybe<Scalars['String']>;
};

export type PatientNoteTypeRes = {
  __typename?: 'PatientNoteTypeRes';
  nodes: Array<PatientNoteTypeSetting>;
  meta?: Maybe<MetaRes>;
};

export type PatientNoteTypeSetting = {
  __typename?: 'PatientNoteTypeSetting';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  treatments?: Maybe<Array<Treatment>>;
  folder?: Maybe<Array<Treatment>>;
  status: FollowUpTypeStatus;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  createdBy: User;
  organization?: Maybe<Organization>;
};

export type PatientPaginationRes = {
  __typename?: 'PatientPaginationRes';
  nodes: Array<PatientRes>;
  meta?: Maybe<MetaRes>;
};

export type PatientRes = {
  __typename?: 'PatientRes';
  _id?: Maybe<Scalars['ObjectId']>;
  owner?: Maybe<Patient>;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Array<PatientDataRes>>;
  status?: Maybe<Array<StatusRes>>;
  sentEmail?: Maybe<Scalars['String']>;
  patientCPTCodes?: Maybe<PatientCptCode>;
};

export type PatientTagInput = {
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

export type PatientTimeRecordPaginationRes = {
  __typename?: 'PatientTimeRecordPaginationRes';
  nodes: Array<PatientTimeRecordRes>;
  meta?: Maybe<MetaRes>;
};

export type PatientTimeRecordRes = {
  __typename?: 'PatientTimeRecordRes';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  organization: Organization;
  patientNoteTypes?: Maybe<Array<PatientNoteTypeSetting>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  timeReport?: Maybe<Array<PatientTimeReportRes>>;
  totalSecond?: Maybe<Scalars['Float']>;
  lastStartedAt?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<Scalars['ObjectId']>;
  lastStoppedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientTimeReportRes = {
  __typename?: 'PatientTimeReportRes';
  _id: Scalars['ObjectId'];
  cptCode: CptCode;
  patient: Patient;
  owner: User;
  organization: Organization;
  stoppedAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  state: PatientCptCodeStatus;
  patientNoteTypes?: Maybe<Array<Maybe<PatientNoteTypeEmbedded>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  second?: Maybe<Scalars['Float']>;
  patientNoteTypeSettings?: Maybe<Array<PatientNoteTypeSetting>>;
};

export type ProcessedData = {
  time: Scalars['DateTime'];
  query: Scalars['String'];
  response: Scalars['String'];
  keyword: Scalars['String'];
  label: Scalars['Int'];
  label_mapped: Scalars['String'];
};

export type ProviderRes = {
  __typename?: 'ProviderRes';
  nodes: Array<ProviderSetting>;
  meta?: Maybe<MetaRes>;
};

export type ProviderSetting = {
  __typename?: 'ProviderSetting';
  _id: Scalars['ObjectId'];
  provider?: Maybe<Scalars['String']>;
  emrProvider?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  updatedBy: User;
  createdBy: User;
  organization?: Maybe<Organization>;
};

export type Query = {
  __typename?: 'Query';
  aggregateComment: Array<CommentAggregateRes>;
  aggregateSurveyResult: Array<SurveyResultAggregateRes>;
  aggregateTreatment: Array<BaseAggregateRes>;
  aggregateUser: Array<BaseAggregateRes>;
  checkHightLight: Array<TreatmentStage>;
  getAssignSurveys: TreatmentsRes;
  getAssignTreatments: TreatmentsRes;
  getAssociatedFollowUpTypes?: Maybe<FollowUpTypeRes>;
  getCalendarConfigInfo: CalendarConfigRes;
  getChecklistForPatient: TreatmentsRes;
  getChecklistFromChartConfig: Treatment;
  getComments: CommentsCursorRes;
  getConfigByGroup: Array<CalendarConfig>;
  getCPTCode: CptCodeRes;
  getCPTCodeDetails?: Maybe<CptCode>;
  getCurrentPatientStage?: Maybe<ConditionSetting>;
  getCurrentStage: Array<CurrentStageRes>;
  getDashboard?: Maybe<DashboardRes>;
  getDashboards: Array<DashboardRes>;
  getEmrLogs: EmrLogRes;
  getFollowUpType?: Maybe<FollowUpTypeSetting>;
  getFollowUpTypes?: Maybe<FollowUpTypeRes>;
  getGroupComments: CommentGroupsCursorRes;
  getGroupCommentsByPatient: CommentGroupsCursorRes;
  getGroupTreatments: GroupTreatmentSettingRes;
  getGroupTreatments4Assign: GroupTreatmentSettingRes;
  getLatestChecklistData: Array<ResultChecklist>;
  getLinkDownloadFileSample: Scalars['String'];
  getLinkDownloadKeyPair: Scalars['String'];
  getListConditions: ConditionSettingRes;
  getListMedias: MediaRes;
  getListPatientStages: PatientCondStageRes;
  getMedia: Media;
  getNavigators: UsersRes;
  getNavigatorsToInvite: UsersRes;
  getNotifications: NotificationRes;
  getNumberOfNotification: Scalars['Int'];
  getUnseenNotificationsCount: Scalars['Float'];
  getOrganization?: Maybe<Organization>;
  getOrganizationByUser: Organization;
  getOrganizations?: Maybe<OrganizationRes>;
  getPathToTreatment: Array<TreatmentsPathRes>;
  getPatientChecklist?: Maybe<PatientChecklistMono>;
  getPatientChecklistData: Array<PatientChecklistMono>;
  getPatientChecklistFromChartConfig: Array<PatientChecklistMono>;
  getPatientChecklists: PatientChecklistRes;
  getPatientDashboard?: Maybe<PatientDashboardInfoRes>;
  getPatientDashboards: Array<PatientDashboardInfoRes>;
  getPatientData: PatientInfoRes;
  getPatientFollowUp?: Maybe<PatientFollowUp>;
  getPatientFollowUps?: Maybe<PatientFollowUpRes>;
  getPatientNote?: Maybe<PatientNote>;
  getPatientNotes?: Maybe<PatientNoteRes>;
  getPatientNoteType?: Maybe<PatientNoteTypeSetting>;
  getPatientNoteTypes?: Maybe<PatientNoteTypeRes>;
  getPatients: PatientPaginationRes;
  getPatientStage?: Maybe<PatientCondStage>;
  getPatientTags: TagsRes;
  getPatientTagsByIds: Array<Tag>;
  getPatientTimeRecord: PatientTimeRecordPaginationRes;
  getPD4QuickView: Array<PatientDashboardInfoRes>;
  getProvider?: Maybe<ProviderRes>;
  getPublicTreatments: TreatmentsRes;
  getReleaseNote?: Maybe<ReleaseNoteRes>;
  getRootTreatmentFromNode: Treatment;
  getRootTreatmentFromPatient: Array<Treatment>;
  getShortLink: ShortLink;
  getStageChangedLogs: StageChangedLogsRes;
  getSurveyDataByPatient: SurveyResultPaginationRes;
  getSurveyFromChartConfig: SurveyVersion;
  getSurveyResult: SurveyResult;
  getSurveyResultFromChartConfig: Array<SurveyResult>;
  getSurveyResultGroup: SurveyResultGroupPaginationRes;
  getSurveyResults: Array<SurveyResult>;
  getSurveyVersion?: Maybe<SurveyVersionRes>;
  getSurveyWithToken: SurveyWithTokenRes;
  getTagInfo: TagsRes;
  getTreatment?: Maybe<Treatment>;
  getTreatmentRegimen: TreatmentRegimenRes;
  getTreatments: TreatmentsRes;
  getTreatmentsForUpdate: TreatmentsRes;
  getTreatmentsNotYetAssigned: Array<Treatment>;
  getTreeNodes: TreatmentsRes;
  getTwilioLogs: TwilioLogRes;
  getUndeletedTreatments: TreatmentsRes;
  getUnreadAllComment: Array<CommentGroupRes>;
  getUnreadCommentsPatientList: CommentGroupsCursorRes;
  getUser?: Maybe<User>;
  getUsers: GetUsersRes;
  getUsersForNavigator: UsersRes;
  getHomepageConfiguration: Scalars['Boolean'];
  isOpenDialogStopTimeRecord: Scalars['Boolean'];
  me?: Maybe<MeRes>;
  showingCommentFrom: Scalars['Boolean'];
  showingMenuAdministration: Scalars['Boolean'];
  showingMenuSetting: Scalars['Boolean'];
  showingQuickView: Scalars['Boolean'];
  showingTreatmentsSidebar?: Maybe<Array<Maybe<Scalars['String']>>>;
  showSaveRecording?: Maybe<TSaveRecording>;
  showStatusHasingRecord?: Maybe<StatusRecordStop>;
  showSubTreatmentsSideBar?: Maybe<Scalars['Boolean']>;
  subTreatmentsSideBar?: Maybe<Array<Maybe<SubTreatmentsSideBar>>>;
  chatbot: ChatBotRes;
};

export type QueryAggregateCommentArgs = {
  params: AggregateInput;
};

export type QueryAggregateSurveyResultArgs = {
  params: AggregateInput;
};

export type QueryAggregateTreatmentArgs = {
  params: AggregateInput;
};

export type QueryAggregateUserArgs = {
  params: AggregateInput;
};

export type QueryGetAssignSurveysArgs = {
  params: GetTreatmentsInput;
};

export type QueryGetAssignTreatmentsArgs = {
  params: GetAssignTreatmentInput;
};

export type QueryGetAssociatedFollowUpTypesArgs = {
  params: GetAssociatedFollowUpTypesInput;
};

export type QueryGetCalendarConfigInfoArgs = {
  params: GetCalendarConfigInput;
};

export type QueryGetChecklistForPatientArgs = {
  params: GetChecklistForPatientInput;
};

export type QueryGetChecklistFromChartConfigArgs = {
  params: GetPatientChecklistFromChartConfigInput;
};

export type QueryGetCommentsArgs = {
  params: GetCommentInput;
};

export type QueryGetConfigByGroupArgs = {
  params: IdInputType;
};

export type QueryGetCptCodeArgs = {
  params: GetCptCodeInput;
};

export type QueryGetCptCodeDetailsArgs = {
  params: GetCptDetailsInput;
};

export type QueryGetCurrentPatientStageArgs = {
  params: GetCurrentPatientStageInput;
};

export type QueryGetCurrentStageArgs = {
  params: GetCurrentStageInput;
};

export type QueryGetDashboardArgs = {
  params: IdInputType;
};

export type QueryGetEmrLogsArgs = {
  params: GetInboundEmrLogInput;
};

export type QueryGetFollowUpTypeArgs = {
  params: IdInputType;
};

export type QueryGetFollowUpTypesArgs = {
  params: GetFollowUpTypesInput;
};

export type QueryGetGroupCommentsArgs = {
  params: CursorGetGroupCommentInput;
};

export type QueryGetGroupCommentsByPatientArgs = {
  params: IdInputType;
};

export type QueryGetGroupTreatmentsArgs = {
  params: GetGroupTreatmentInput;
};

export type QueryGetGroupTreatments4AssignArgs = {
  params: PaginateInputType;
};

export type QueryGetLatestChecklistDataArgs = {
  params: GetPatientChecklistInput;
};

export type QueryGetListConditionsArgs = {
  params: GetConditionSettingInput;
};

export type QueryGetListMediasArgs = {
  params?: Maybe<GetListMediaInput>;
};

export type QueryGetListPatientStagesArgs = {
  params: GetPatientStageInput;
};

export type QueryGetMediaArgs = {
  params?: Maybe<IdInputType>;
};

export type QueryGetNavigatorsArgs = {
  params: GetUsersInput;
};

export type QueryGetNavigatorsToInviteArgs = {
  params: GetNavigatorsInput;
};

export type QueryGetNotificationsArgs = {
  params: GetNotificationInput;
};

export type QueryGetOrganizationArgs = {
  params: IdInputType;
};

export type QueryGetOrganizationsArgs = {
  params: GetOrganizationsInput;
};

export type QueryGetPathToTreatmentArgs = {
  params: IdInputType;
};

export type QueryGetPatientChecklistArgs = {
  params: GetAPatientChecklistInput;
};

export type QueryGetPatientChecklistDataArgs = {
  params: GetPatientChecklistByChecklist;
};

export type QueryGetPatientChecklistFromChartConfigArgs = {
  params: GetPatientChecklistFromChartConfigInput;
};

export type QueryGetPatientChecklistsArgs = {
  params: GetPatientChecklistInput;
};

export type QueryGetPatientDashboardArgs = {
  params: IdInputType;
};

export type QueryGetPatientDashboardsArgs = {
  params: GetPatientDashboardInput;
};

export type QueryGetPatientDataArgs = {
  params: GetPatientsDataInput;
};

export type QueryGetPatientFollowUpArgs = {
  params: IdInputType;
};

export type QueryGetPatientFollowUpsArgs = {
  params: GetPatientFollowUpsInput;
};

export type QueryGetPatientNoteArgs = {
  params: IdInputType;
};

export type QueryGetPatientNotesArgs = {
  params: GetPatientNotesInput;
};

export type QueryGetPatientNoteTypeArgs = {
  params: IdInputType;
};

export type QueryGetPatientNoteTypesArgs = {
  params: GetPatientNoteTypesInput;
};

export type QueryGetPatientsArgs = {
  params: GetPatientsInput;
};

export type QueryGetPatientStageArgs = {
  params: IdInputType;
};

export type QueryGetPatientTagsArgs = {
  params: GetPatientTagsInput;
};

export type QueryGetPatientTagsByIdsArgs = {
  params: GetTagsByPatientInput;
};

export type QueryGetPatientTimeRecordArgs = {
  params: GetPatientTimeRecordInput;
};

export type QueryGetProviderArgs = {
  params: GetProviderInput;
};

export type QueryGetPublicTreatmentsArgs = {
  params: GetPublicTreatment;
};

export type QueryGetReleaseNoteArgs = {
  params: GetReleaseNoteInput;
};

export type QueryGetRootTreatmentFromNodeArgs = {
  params: GetRootTreatmentInputFromNode;
};

export type QueryGetRootTreatmentFromPatientArgs = {
  params: GetRootTreatmentInputFromPatient;
};

export type QueryGetShortLinkArgs = {
  params: GetShortLinkInput;
};

export type QueryGetStageChangedLogsArgs = {
  params: GetStageChangedLogsInput;
};

export type QueryGetSurveyDataByPatientArgs = {
  params: GetSurveyDataByPatientInput;
};

export type QueryGetSurveyFromChartConfigArgs = {
  params: GetSurveyResultsFromChartConfigInput;
};

export type QueryGetSurveyResultArgs = {
  params: IdInputType;
};

export type QueryGetSurveyResultFromChartConfigArgs = {
  params: GetSurveyResultsFromChartConfigInput;
};

export type QueryGetSurveyResultGroupArgs = {
  params: GetSurveyResultGroupInput;
};

export type QueryGetSurveyResultsArgs = {
  params: GetSurveyResultsInput;
};

export type QueryGetSurveyVersionArgs = {
  params: GetSurveyVersionInput;
};

export type QueryGetSurveyWithTokenArgs = {
  params: GetSurveyInput;
};

export type QueryGetTagInfoArgs = {
  params: GetTagInput;
};

export type QueryGetTreatmentArgs = {
  params: GetTreatmentInput;
};

export type QueryGetTreatmentRegimenArgs = {
  params: GetTreatmentRegimenInput;
};

export type QueryGetTreatmentsArgs = {
  params: GetTreatmentsInput;
};

export type QueryGetTreatmentsForUpdateArgs = {
  params: GetTreatmentsForUpdateInput;
};

export type QueryGetTreatmentsNotYetAssignedArgs = {
  params: GetTreatmentsNotYetAssignedInput;
};

export type QueryGetTreeNodesArgs = {
  params: GetNodeTreeInput;
};

export type QueryGetTwilioLogsArgs = {
  params: GetTwilioLogInput;
};

export type QueryGetUndeletedTreatmentsArgs = {
  params: GetTreatmentsForUpdateInput;
};

export type QueryGetUnreadCommentsPatientListArgs = {
  params: GetGroupCommentInput;
};

export type QueryGetUserArgs = {
  params: IdInputType;
};

export type QueryGetUsersArgs = {
  params: GetUsersInput;
};

export type QueryGetUsersForNavigatorArgs = {
  params: GetUsersForNavigatorInput;
};

export type QuestionAnalysisOutput = {
  answerableScore: Scalars['Float'];
  searchScore: Scalars['Float'];
  question: Scalars['String'];
};

export enum Race {
  White = 'WHITE',
  Black = 'BLACK',
  Asia = 'ASIA',
  NativeIndianOrAlaskaNative = 'NATIVE_INDIAN_OR_ALASKA_NATIVE',
  NativeHawaiianOrOtherPacificIslander = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  Other = 'OTHER',
}

export type ReadabilityScores = {
  dalleChallScore: Scalars['Float'];
  autoreadabilityScore: Scalars['Float'];
  smogScore: Scalars['Float'];
  freGrade: Scalars['Float'];
  collieu: Scalars['Float'];
  linearWrite: Scalars['Float'];
  gunningFog: Scalars['Float'];
  polySyllable: Scalars['Float'];
  fleshGrade: Scalars['Float'];
};

export type RegimenConfigEmbedded = {
  __typename?: 'RegimenConfigEmbedded';
  treatment?: Maybe<Treatment>;
  treatmentType?: Maybe<TreatmentType>;
  task?: Maybe<FollowUpTypeSetting>;
  taskDesc?: Maybe<Scalars['String']>;
  specifiedTime?: Maybe<Scalars['Float']>;
  specifiedType?: Maybe<SpecifiedType>;
  time?: Maybe<RegimenConfigTimeEmbedded>;
  scheduleConfig?: Maybe<ScheduleConfigEmbedded>;
  updatedBy?: Maybe<User>;
  updatedByRole?: Maybe<UserRole>;
};

export type RegimenConfigEmbeddedInput = {
  treatment?: Maybe<Scalars['ObjectId']>;
  task?: Maybe<Scalars['ObjectId']>;
  taskDesc?: Maybe<Scalars['String']>;
  specifiedTime?: Maybe<Scalars['Float']>;
  specifiedType?: Maybe<SpecifiedType>;
  time?: Maybe<RegimenConfigTimeEmbeddedInput>;
  treatmentType?: Maybe<TreatmentType>;
  scheduleConfig?: Maybe<ScheduleConfigEmbeddedInput>;
  updatedBy?: Maybe<Scalars['ObjectId']>;
  updatedByRole?: Maybe<UserRole>;
};

export type RegimenConfigTimeEmbedded = {
  __typename?: 'RegimenConfigTimeEmbedded';
  hour?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
};

export type RegimenConfigTimeEmbeddedInput = {
  hour?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
};

export type RegisterInput = {
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterPhoneUser = {
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
  password: Scalars['String'];
};

export type ReleaseNoteRes = {
  __typename?: 'ReleaseNoteRes';
  nodes: Array<ReleaseNoteSetting>;
  meta?: Maybe<MetaRes>;
};

export type ReleaseNoteSetting = {
  __typename?: 'ReleaseNoteSetting';
  _id: Scalars['ObjectId'];
  releaseDate: Scalars['DateTime'];
  version: Scalars['String'];
  releaseNote: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type RemoveOneTreatmentInput = {
  followUpTypeId: Scalars['ObjectId'];
  treatmentId: Scalars['ObjectId'];
};

export type RemoveOneTreatmentNoteTypeInput = {
  patientNoteTypeId: Scalars['ObjectId'];
  treatmentId: Scalars['ObjectId'];
};

export type RemoveSecondaryEmailInput = {
  secondaryEmail: Scalars['String'];
};

export type RemoveUserSecondaryEmailInput = {
  secondaryEmail: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type ReplaceTagInput = {
  primaryTag?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  selectTags: Array<Scalars['ObjectId']>;
};

export type ReplaceTreatmentInput = {
  treatmentId: Scalars['ObjectId'];
  newTreatmentId: Scalars['ObjectId'];
};

export enum ReportType {
  RegisteredUser = 'REGISTERED_USER',
  UserRole = 'USER_ROLE',
  UserAuthenticationSource = 'USER_AUTHENTICATION_SOURCE',
  SurveyResult = 'SURVEY_RESULT',
  ChecklistResult = 'CHECKLIST_RESULT',
  TreatmentCreated = 'TREATMENT_CREATED',
  Top_10TreatmentOwner = 'TOP_10_TREATMENT_OWNER',
  TotalCommentAskedAndReplied = 'TOTAL_COMMENT_ASKED_AND_REPLIED',
  TotalPatientDoSurveyBySource = 'TOTAL_PATIENT_DO_SURVEY_BY_SOURCE',
}

export type RepresentativeInput = {
  admin?: Maybe<Scalars['ObjectId']>;
  organization?: Maybe<Scalars['ObjectId']>;
};

export type ResendAccountEmailVerify = {
  userId: Scalars['ObjectId'];
};

export type ResendOtpInput = {
  phone: Scalars['String'];
};

export type ResetPasswordInput = {
  accessToken: Scalars['String'];
  password: Scalars['String'];
};

export type ResultChecklist = {
  __typename?: 'ResultChecklist';
  _id: Scalars['ObjectId'];
  status: ChecklistStatus;
  checklistResult?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ScheduleConfigEmbedded = {
  __typename?: 'ScheduleConfigEmbedded';
  freq: Scalars['Float'];
  dtstart?: Maybe<Scalars['DateTime']>;
  until?: Maybe<Scalars['DateTime']>;
  count?: Maybe<Scalars['Float']>;
  interval: Scalars['Float'];
  byweekday?: Maybe<Array<Scalars['Float']>>;
  byNweekDay?: Maybe<WeekdayConfig>;
  bymonth?: Maybe<Array<Scalars['Float']>>;
  bymonthday?: Maybe<Array<Scalars['Float']>>;
};

export type ScheduleConfigEmbeddedInput = {
  freq: Scalars['Float'];
  dtstart?: Maybe<Scalars['DateTime']>;
  until?: Maybe<Scalars['DateTime']>;
  count?: Maybe<Scalars['Float']>;
  interval: Scalars['Float'];
  byweekday?: Maybe<Array<Scalars['Float']>>;
  byNweekDay?: Maybe<WeekdayConfigInput>;
  bymonth?: Maybe<Array<Scalars['Float']>>;
  bymonthday?: Maybe<Array<Scalars['Float']>>;
};

export enum SearchType {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export type SecondaryEmailInfo = {
  __typename?: 'SecondaryEmailInfo';
  email: Scalars['String'];
  isVerified: Scalars['Boolean'];
};

export type SeenCommentInput = {
  ids: Array<Scalars['ObjectId']>;
  treatmentId: Scalars['ObjectId'];
};

export type SeenNotificationInput = {
  ids: Array<Scalars['ObjectId']>;
};

export type MarkNotificationSeenInput = {
  notificationId: Scalars['ObjectId'];
};

export type SetPrimaryEmailInput = {
  secondaryEmail: Scalars['String'];
};

export type SetRoleInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['ObjectId']>;
};

export type SetUserPrimaryEmailInput = {
  secondaryEmail: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type ShareDashboardInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  userIds: Array<Scalars['ObjectId']>;
};

export type SharedMultiTreatmentInput = {
  patientId: Scalars['ObjectId'];
  sendSMS?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  smsMessage?: Maybe<Scalars['String']>;
  treatmentIds: Array<Scalars['ObjectId']>;
};

export type SharedUser = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSMS?: Maybe<Scalars['Boolean']>;
  createNew?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  race?: Maybe<Race>;
  mrn?: Maybe<Scalars['String']>;
  provider?: Maybe<Array<Scalars['ObjectId']>>;
  emrProvider?: Maybe<Array<Scalars['ObjectId']>>;
  address?: Maybe<Scalars['String']>;
};

export type SharedUserRes = {
  __typename?: 'SharedUserRes';
  email?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['JSON']>>;
  id?: Maybe<Scalars['ObjectId']>;
  authenticationInfo?: Maybe<Array<AuthInfo>>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSMS?: Maybe<Scalars['Boolean']>;
};

export enum ShareStatus {
  Sending = 'SENDING',
  Open = 'OPEN',
  NotOpen = 'NOT_OPEN',
}

export type ShareTreatmentInput = {
  sharedUsers: Array<SharedUser>;
  treatmentId: Scalars['ObjectId'];
  smsMessage?: Maybe<Scalars['String']>;
};

export type ShareTreatmentRes = {
  __typename?: 'ShareTreatmentRes';
  user?: Maybe<SharedUserRes>;
  treatment?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  msg: Scalars['String'];
  userError?: Maybe<Array<Scalars['String']>>;
};

export type ShortLink = {
  __typename?: 'ShortLink';
  _id: Scalars['ObjectId'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  link: Scalars['String'];
  expiredDate?: Maybe<Scalars['DateTime']>;
};

export type SortPatientCondStage = {
  startDate?: Maybe<OrderBy>;
  endDate?: Maybe<OrderBy>;
};

export enum SpecifiedType {
  NextHours = 'NEXT_HOURS',
  NextMonths = 'NEXT_MONTHS',
  NextWeek = 'NEXT_WEEK',
  Immediate = 'IMMEDIATE',
}

export type Stage = {
  _id?: Maybe<Scalars['ObjectId']>;
  name: Scalars['String'];
};

export type StageChangedLog = {
  __typename?: 'StageChangedLog';
  _id: Scalars['ObjectId'];
  patient: Patient;
  root?: Maybe<Treatment>;
  treatment?: Maybe<Treatment>;
  treatmentStageEmbed?: Maybe<TreatmentStageEmbed>;
  organization?: Maybe<Organization>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ShareStatus>;
  isAssigned?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  owner?: Maybe<Patient>;
};

export type StageChangedLogsRes = {
  __typename?: 'StageChangedLogsRes';
  nodes: Array<StageChangedLog>;
  meta?: Maybe<MetaRes>;
};

export type StageEmbedded = {
  __typename?: 'StageEmbedded';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  organization: Organization;
  createdBy: User;
  updatedBy: User;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StatusRecord = {
  status?: Maybe<StatusRecordStop>;
};

export enum StatusRecordStop {
  Logout = 'LOGOUT',
  Redirect = 'REDIRECT',
}

export type StatusRes = {
  __typename?: 'StatusRes';
  status?: Maybe<ShareStatus>;
  treatment?: Maybe<Scalars['ObjectId']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StatusShowSubSideBar = {
  status?: Maybe<Scalars['Boolean']>;
};

export type StopRecordInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  stoppedAt: Scalars['DateTime'];
  patientNoteTypes?: Maybe<Array<NoteTypeInput>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  loginOtherDevice: Scalars['Boolean'];
  treatmentCommentSubscription: CommentExt;
  unreadCommentsSubscription: CommentGroupsCursorRes;
  unreadAllCommentsSubscription: Array<CommentGroupRes>;
  unreadCommentsPatientListSubscription: CommentGroupsCursorRes;
  unreadAllCommentsByPatientsSubscription: CommentGroupsCursorRes;
};

export type SubscriptionTreatmentCommentSubscriptionArgs = {
  patientId?: Maybe<Scalars['ObjectId']>;
  topic: Scalars['String'];
};

export type SubscriptionUnreadCommentsSubscriptionArgs = {
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type SubscriptionUnreadCommentsPatientListSubscriptionArgs = {
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type SubDoc = {
  name: Scalars['String'];
  content: Scalars['String'];
};

export type SubTreatments = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subSectionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubTreatmentsSideBar = {
  __typename?: 'SubTreatmentsSideBar';
  _id: Scalars['String'];
  name: Scalars['String'];
  subSectionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<SubTreatmentsSideBar>>>;
  subSections?: Maybe<Array<Maybe<SubTreatmentsSideBar>>>;
  order?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type SurveyImageRes = {
  __typename?: 'SurveyImageRes';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  thumbNailsUrls: Array<Scalars['String']>;
  srcUrl: Scalars['String'];
  mimeType: Scalars['String'];
  owner: Scalars['ObjectId'];
};

export type SurveyResenderInput = {
  surveyId: Scalars['ObjectId'];
  version?: Maybe<Scalars['Float']>;
  patientInfo: Array<SharedUser>;
  smsMessage?: Maybe<Scalars['String']>;
  sendSMS?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  treatmentSendStatus?: Maybe<TreatmentSendStatus>;
};

export type SurveyResult = {
  __typename?: 'SurveyResult';
  _id: Scalars['ObjectId'];
  owner?: Maybe<Patient>;
  survey?: Maybe<Treatment>;
  treatment?: Maybe<Treatment>;
  organization?: Maybe<Organization>;
  data?: Maybe<Scalars['JSON']>;
  status?: Maybe<ShareStatus>;
  workingStatus?: Maybe<SurveyResultStatus>;
  sentEmail?: Maybe<Scalars['String']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answerDateTime?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  createdBy?: Maybe<User>;
  completedStatus?: Maybe<CompletedStatus>;
  source?: Maybe<SurveySource>;
  surveyVersion?: Maybe<Scalars['Float']>;
  encryptedData?: Maybe<Scalars['ObjectId']>;
};

export type SurveyResultAggregateRes = {
  __typename?: 'SurveyResultAggregateRes';
  name?: Maybe<Scalars['String']>;
  fromEmail: Scalars['Float'];
  fromSMS?: Maybe<Scalars['Float']>;
};

export type SurveyResultEmbedded = {
  __typename?: 'SurveyResultEmbedded';
  _id: Scalars['ObjectId'];
  data?: Maybe<Scalars['JSON']>;
  workingStatus?: Maybe<SurveyResultStatus>;
  patientNote?: Maybe<PatientNote>;
  surveyDetail?: Maybe<SurveyVersion>;
  surveyVersion?: Maybe<Scalars['Float']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  answerDateTime?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type SurveyResultGroupPaginationRes = {
  __typename?: 'SurveyResultGroupPaginationRes';
  nodes: Array<SurveyResultGroupRes>;
  meta?: Maybe<MetaRes>;
};

export type SurveyResultGroupRes = {
  __typename?: 'SurveyResultGroupRes';
  _id?: Maybe<Scalars['ObjectId']>;
  survey?: Maybe<Treatment>;
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  smsMessage?: Maybe<Scalars['String']>;
  surveyData?: Maybe<Scalars['String']>;
  surveyResult?: Maybe<Array<SurveyResultEmbedded>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultPaginationRes = {
  __typename?: 'SurveyResultPaginationRes';
  nodes: Array<SurveyResultEmbedded>;
  meta?: Maybe<MetaRes>;
};

export enum SurveyResultStatus {
  New = 'NEW',
  Review = 'REVIEW',
}

export enum SurveySource {
  Sms = 'SMS',
  Email = 'EMAIL',
}

export type SurveyVersion = {
  __typename?: 'SurveyVersion';
  _id: Scalars['ObjectId'];
  checklist?: Maybe<Array<Treatment>>;
  surveyData?: Maybe<Scalars['String']>;
  emrObx?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  originSurvey?: Maybe<Scalars['ObjectId']>;
  organization?: Maybe<Organization>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type SurveyVersionRes = {
  __typename?: 'SurveyVersionRes';
  nodes: Array<SurveyVersion>;
  meta?: Maybe<MetaRes>;
};

export type SurveyWithTokenRes = {
  __typename?: 'SurveyWithTokenRes';
  treatment: SurveyVersion;
  firstName?: Maybe<Scalars['String']>;
};

export type SubmitFlowData = {
  treatments?: Maybe<Scalars['ObjectId']>;
  surveys?: Maybe<Scalars['JSON']>;
  flowId: Scalars['ObjectId'];
};

export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  organization: Organization;
  createdBy: User;
  updatedBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagEmbedded = {
  __typename?: 'TagEmbedded';
  tag: Tag;
  name: Scalars['String'];
  isAssignTag?: Maybe<Scalars['Boolean']>;
};

export type TagsRes = {
  __typename?: 'TagsRes';
  nodes: Array<Tag>;
  meta?: Maybe<MetaRes>;
};

export type TakeOwnershipInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  userId: Scalars['ObjectId'];
};

export type Timezone = {
  __typename?: 'Timezone';
  offset: Scalars['Float'];
  value: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type TimezoneInput = {
  offset: Scalars['Float'];
  value: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type Treatment = {
  __typename?: 'Treatment';
  _id: Scalars['ObjectId'];
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  subSections?: Maybe<Array<Treatment>>;
  subSectionIds?: Maybe<Array<Scalars['ObjectId']>>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<UpdateTagTreatmentTreeEmbedded>>;
  parent?: Maybe<Treatment>;
  parentId?: Maybe<Scalars['ObjectId']>;
  mainImage?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  mainVideo?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  organization?: Maybe<Organization>;
  ownerId: Scalars['ObjectId'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isHideOnHomePage?: Maybe<Scalars['Boolean']>;
  hideMainMedia?: Maybe<Scalars['Boolean']>;
  favorites?: Maybe<Array<User>>;
  order?: Maybe<Scalars['Int']>;
  orderSetting?: Maybe<Scalars['Int']>;
  qrCode?: Maybe<Scalars['String']>;
  urlSharing?: Maybe<Scalars['String']>;
  contributors?: Maybe<Array<User>>;
  contributorIds?: Maybe<Array<Scalars['ObjectId']>>;
  type: TreatmentType;
  isRoot?: Maybe<Scalars['Boolean']>;
  surveyData?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Boolean']>;
  survey?: Maybe<Array<Treatment>>;
  surveyIds?: Maybe<Array<Scalars['ObjectId']>>;
  isSurveyInline?: Maybe<Scalars['Boolean']>;
  treatmentStages?: Maybe<Array<TreatmentStageEmbed>>;
  stageLinkTo?: Maybe<TreatmentStage>;
  checklist?: Maybe<Array<Treatment>>;
  checklists?: Maybe<Array<Treatment>>;
  reference: Array<Treatment>;
  isReference?: Maybe<Scalars['Boolean']>;
  rootRef?: Maybe<Treatment>;
  isDefault?: Maybe<Scalars['Boolean']>;
  emrObx?: Maybe<Scalars['String']>;
  pdfLink?: Maybe<Scalars['String']>;
};

export type TreatmentRegimen = {
  __typename?: 'TreatmentRegimen';
  _id: Scalars['ObjectId'];
  regimenName: Scalars['String'];
  owner: User;
  regimenConfig: Array<RegimenConfigEmbedded>;
  isUsed?: Maybe<Scalars['Boolean']>;
  organization: Organization;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TreatmentRegimenRes = {
  __typename?: 'TreatmentRegimenRes';
  nodes: Array<TreatmentRegimen>;
  meta?: Maybe<MetaRes>;
};

export type TreatmentReminderInput = {
  treatmentId: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
};

export type TreatmentsAssignedRes = {
  __typename?: 'TreatmentsAssignedRes';
  _id: Scalars['ObjectId'];
  numberOfPatient: Scalars['Float'];
  treatment: Treatment;
};

export enum TreatmentSendStatus {
  Send = 'SEND',
  ReSend = 'RE_SEND',
}

export type TreatmentsPathRes = {
  __typename?: 'TreatmentsPathRes';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  mainImage?: Scalars['String'];
  mainVideo?: Scalars['String'];
  shortDescription?: Scalars['String'];
};

export type TreatmentsRes = {
  __typename?: 'TreatmentsRes';
  nodes: Array<Treatment>;
  meta?: Maybe<MetaRes>;
};

export type TreatmentStage = {
  __typename?: 'TreatmentStage';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner: User;
  treatment?: Maybe<Treatment>;
  organization: Organization;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  linkTo?: Maybe<Treatment>;
};

export type TreatmentStageEmbed = {
  __typename?: 'TreatmentStageEmbed';
  treatmentStage?: Maybe<TreatmentStage>;
  name?: Maybe<Scalars['String']>;
  organization: Organization;
};

export enum TreatmentType {
  Treatment = 'TREATMENT',
  Folder = 'FOLDER',
  Survey = 'SURVEY',
  Checklist = 'CHECKLIST',
}

export type TSaveRecording = {
  __typename?: 'TSaveRecording';
  _id: Scalars['String'];
  startedAt?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['String']>;
  idPatient: Scalars['String'];
  idCptCode: Scalars['String'];
};

export type TwilioLog = {
  __typename?: 'TwilioLog';
  _id: Scalars['ObjectId'];
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioLogRes = {
  __typename?: 'TwilioLogRes';
  nodes: Array<TwilioLog>;
  meta?: Maybe<MetaRes>;
};

export type UnlinkFromTreatmentInput = {
  treatmentId: Scalars['ObjectId'];
};

export type UnlinkTreatmentInput = {
  treatmentStageId: Scalars['ObjectId'];
};

export type UnReferenceTreatmentInput = {
  treatmentId: Scalars['ObjectId'];
};

export type UnTagOnPatientInput = {
  tagId: Scalars['ObjectId'];
  tagName?: Maybe<Scalars['String']>;
  patientId: Scalars['ObjectId'];
};

export type UpdateChartConfigInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  chartConfig: CreateChartConfigs;
};

export type UpdateChecklistInput = {
  checklistId: Scalars['ObjectId'];
  name: Scalars['String'];
  surveyData: Scalars['String'];
};

export type UpdateChecklistResultInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  resultId: Scalars['ObjectId'];
  checklistResultId: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
  checklistResult: Scalars['JSON'];
  status: ChecklistStatus;
};

export type UpdateConditionSettingInput = {
  condId: Scalars['ObjectId'];
  stageIds: Array<Stage>;
  condName?: Maybe<Scalars['String']>;
};

export type UpdateCptCodeInput = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  code: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  patientNoteTypes?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateDashboardInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isShow?: Maybe<Scalars['Boolean']>;
};

export type UpdateFavoriteFollowUpTypeInput = {
  followUpTypeId: Scalars['ObjectId'];
  isFavorite: Scalars['Boolean'];
};

export type UpdateFolderInfoInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type UpdateFollowUpTypeInput = {
  followUpTypeId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  folderIds?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateGridChartInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  grid: Scalars['Int'];
};

export type UpdateGroupTreatmentInput = {
  groupTreatmentId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  treatmentIds: Array<Scalars['ObjectId']>;
};

export type UpdateOrderDashboardInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  chartsOrder: Array<ChartOrder>;
};

export type UpdateOrganizationInput = {
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<TimezoneInput>;
};

export type UpdatePatientChecklistInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  checklistResultId?: Maybe<Scalars['ObjectId']>;
  patientId: Scalars['ObjectId'];
  checklistId: Scalars['ObjectId'];
};

export type UpdatePatientDashboardInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isShow?: Maybe<Scalars['Boolean']>;
  config?: Maybe<Scalars['JSON']>;
  condition?: Maybe<Scalars['JSON']>;
  isQuickView?: Maybe<Scalars['Boolean']>;
};

export type UpdatePatientFollowUpInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<FollowUpStatus>;
  followUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['ObjectId']>;
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type UpdatePatientInfoInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  race?: Maybe<Race>;
  provider?: Maybe<Array<Scalars['ObjectId']>>;
  emrProvider?: Maybe<Array<Scalars['ObjectId']>>;
  address?: Maybe<Scalars['String']>;
  mrn?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['JSON']>>;
  sendSMS?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  smsMessage?: Maybe<Scalars['String']>;
};

export type UpdatePatientNoteInput = {
  noteId: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
  subNoteId: Scalars['ObjectId'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  notedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['ObjectId']>;
};

export type UpdatePatientNoteTypeInput = {
  patientNoteTypeId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  treatmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  folderIds?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdatePatientStageInput = {
  condStageId: Scalars['ObjectId'];
  currentStageId?: Maybe<Scalars['ObjectId']>;
  patientId: Scalars['ObjectId'];
  conditionId: Scalars['ObjectId'];
  stageId?: Maybe<Scalars['ObjectId']>;
  color?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type UpdatePatientTimeRecordStatusInput = {
  _id: Scalars['ObjectId'];
  state: PatientCptCodeStatus;
  stoppedAt?: Maybe<Scalars['DateTime']>;
  patientNoteTypes?: Maybe<Array<NoteTypeInput>>;
};

export type UpdateProviderInput = {
  providerId: Scalars['ObjectId'];
  provider?: Maybe<Scalars['String']>;
  emrProvider?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateRecordsInput = {
  records: Array<UpdateRecordsInputItem>;
};

export type UpdateRecordsInputItem = {
  _id: Scalars['ObjectId'];
  status: Scalars['Boolean'];
};

export type UpdateStageChangedLogInput = {
  rootTreatmentId: Scalars['ObjectId'];
  nextStageId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type UpdateStageChangedLogTimeInput = {
  _id: Scalars['ObjectId'];
  patientId?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateStatusPatientNoteInput = {
  noteId: Scalars['ObjectId'];
  patientId: Scalars['ObjectId'];
  subNoteIds: Array<Scalars['ObjectId']>;
};

export type UpdateSurveyInfoInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  surveyData?: Maybe<Scalars['String']>;
  checklist?: Maybe<Array<Scalars['ObjectId']>>;
  emrObx?: Maybe<Scalars['String']>;
};

export type UpdateSurveyResultInput = {
  surveyResultId: Scalars['ObjectId'];
  patientId?: Maybe<Scalars['ObjectId']>;
  data: Scalars['JSON'];
  completedStatus: CompletedStatus;
  workingStatus?: Maybe<SurveyResultStatus>;
};

export type UpdateTagEmbeddedInput = {
  tagId: Scalars['ObjectId'];
  name: Scalars['String'];
  isAssignTag?: Maybe<Scalars['Boolean']>;
};

export type UpdateTagForTreatmentTreeInput = {
  tagEmbedded: UpdateTagEmbeddedInput;
  treatmentNodeId: Scalars['ObjectId'];
  isRemove?: Maybe<Scalars['Boolean']>;
  isAddOne?: Maybe<Scalars['Boolean']>;
  isUpdate?: Maybe<Scalars['Boolean']>;
};

export type UpdateTagInput = {
  tagId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type UpdateTagTreatmentTreeEmbedded = {
  __typename?: 'UpdateTagTreatmentTreeEmbedded';
  tag: Tag;
  name: Scalars['String'];
  isAssignTag?: Maybe<Scalars['Boolean']>;
};

export type UpdateToFavoriteInput = {
  treatmentId: Scalars['ObjectId'];
  isFavorite: Scalars['Boolean'];
};

export type UpdateTreatmentInfoInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  mainVideo?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isHideOnHomePage?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isSurveyInline?: Maybe<Scalars['Boolean']>;
  checklist?: Maybe<Array<Scalars['ObjectId']>>;
  followUpType?: Maybe<Array<Scalars['ObjectId']>>;
  groupSettings?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateTreatmentOrderInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  orderSetting?: Maybe<Scalars['Int']>;
};

export type UpdateTreatmentRegimenInput = {
  regimenConfig?: Maybe<Array<RegimenConfigEmbeddedInput>>;
  regimenName?: Maybe<Scalars['String']>;
  regimenId: Scalars['ObjectId'];
};

export type UpdateTreatmentStageInput = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  treatmentId: Scalars['ObjectId'];
};

export type UpdateTreatmentStagesEmbedInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  treatmentStageIds: Array<Scalars['ObjectId']>;
};

export type UpdateTreatmentTreeInput = {
  _id: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Float']>;
  lastCommentAt?: Maybe<Scalars['DateTime']>;
  cursor?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ObjectId']>;
  changingBehavior: ChangingBehavior;
};

export type UpdateUserInfoInput = {
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  userId: Scalars['ObjectId'];
  phone?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  setting?: Maybe<ConfigEmbeddedInput>;
  phone?: Maybe<Scalars['String']>;
};

export type UploadInput = {
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  email: Scalars['String'];
  secondaryEmail?: Maybe<Array<SecondaryEmailInfo>>;
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  dob?: Maybe<Scalars['DateTime']>;
  mrn?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['JSON']>>;
  setting?: Maybe<ConfigEmbedded>;
  middleName?: Maybe<Scalars['String']>;
  authenticationInfo?: Maybe<Array<AuthInfo>>;
  role: UserRole;
  isBlankAccount?: Maybe<Scalars['Boolean']>;
  isNoEmail?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  loginAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  status: UserStatus;
  tags?: Maybe<Array<Maybe<TagEmbedded>>>;
};

export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Navigator = 'NAVIGATOR',
  User = 'USER',
}

export type UsersRes = {
  __typename?: 'UsersRes';
  nodes: Array<User>;
  meta?: Maybe<MetaRes>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export type VerifyEmailForgotPasswordInput = {
  email: Scalars['String'];
};

export type VerifyPhoneNumberForgotPasswordInput = {
  phone: Scalars['String'];
};

export type WeekdayConfig = {
  __typename?: 'WeekdayConfig';
  weekday?: Maybe<ByNWeekDay>;
  n?: Maybe<Scalars['Float']>;
};

export type WeekdayConfigInput = {
  weekday?: Maybe<ByNWeekDay>;
  n?: Maybe<Scalars['Float']>;
};

export type AggregateUserQueryVariables = {
  params: AggregateInput;
};

export type AggregateUserQuery = { __typename?: 'Query' } & {
  aggregateUser: Array<
    { __typename?: 'BaseAggregateRes' } & Pick<
      BaseAggregateRes,
      'name' | 'value'
    >
  >;
};

export type AggregateTreatmentQueryVariables = {
  params: AggregateInput;
};

export type AggregateTreatmentQuery = { __typename?: 'Query' } & {
  aggregateTreatment: Array<
    { __typename?: 'BaseAggregateRes' } & Pick<
      BaseAggregateRes,
      'name' | 'value'
    >
  >;
};

export type AggregateCommentQueryVariables = {
  params: AggregateInput;
};

export type AggregateCommentQuery = { __typename?: 'Query' } & {
  aggregateComment: Array<
    { __typename?: 'CommentAggregateRes' } & Pick<
      CommentAggregateRes,
      'name' | 'value' | 'valueOfReply'
    >
  >;
};

export type AggregateSurveyResultQueryVariables = {
  params: AggregateInput;
};

export type AggregateSurveyResultQuery = { __typename?: 'Query' } & {
  aggregateSurveyResult: Array<
    { __typename?: 'SurveyResultAggregateRes' } & Pick<
      SurveyResultAggregateRes,
      'name' | 'fromEmail' | 'fromSMS'
    >
  >;
};

export type LogoutMutationVariables = {};

export type LogoutMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'logout'
>;

export type LoginMutationVariables = {
  params: LoginInput;
};

export type LoginMutation = { __typename?: 'Mutation' } & {
  login: Maybe<{ __typename?: 'MeRes' } & ResponseLoginFragment>;
};

export type LoginPhoneMutationVariables = {
  params: LoginPhoneInput;
};

export type LoginPhoneMutation = { __typename?: 'Mutation' } & {
  loginPhone: Maybe<{ __typename?: 'MeRes' } & ResponseLoginFragment>;
};

export type GoogleAuthMutationVariables = {
  idToken: Scalars['String'];
};

export type GoogleAuthMutation = { __typename?: 'Mutation' } & {
  googleAuth: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type FacebookAuthMutationVariables = {
  idToken: Scalars['String'];
};

export type FacebookAuthMutation = { __typename?: 'Mutation' } & {
  facebookAuth: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type ActiveUserMutationVariables = {
  params: ActiveUserInput;
};

export type ActiveUserMutation = { __typename?: 'Mutation' } & {
  activeUser: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type VerifyPhoneUserMutationVariables = {
  params: ActiveUserOtpInput;
};

export type VerifyPhoneUserMutation = { __typename?: 'Mutation' } & {
  verifyPhoneUser: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type MeQueryVariables = {};

export type MeQuery = { __typename?: 'Query' } & {
  me: Maybe<{ __typename?: 'MeRes' } & ResponseLoginFragment>;
};

export type VerifyEmailForgotPasswordMutationVariables = {
  params: VerifyEmailForgotPasswordInput;
};

export type VerifyEmailForgotPasswordMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'verifyEmailForgotPassword'>;

export type VerifyPhoneNumberForgotPasswordMutationVariables = {
  params: VerifyPhoneNumberForgotPasswordInput;
};

export type VerifyPhoneNumberForgotPasswordMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'verifyPhoneNumberForgotPassword'>;

export type ResetPasswordMutationVariables = {
  params: ResetPasswordInput;
};

export type ResetPasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resetPassword'
>;

export type ChangePasswordMutationVariables = {
  params: ChangePasswordInput;
};

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
  changePassword: { __typename?: 'User' } & Pick<User, 'email'>;
};

export type RegisterMutationVariables = {
  params: RegisterInput;
};

export type RegisterMutation = { __typename?: 'Mutation' } & {
  register: { __typename?: 'User' } & Pick<
    User,
    'email' | 'firstName' | 'lastName' | '_id'
  >;
};

export type RegisterPhoneUserMutationVariables = {
  params: RegisterPhoneUser;
};

export type RegisterPhoneUserMutation = { __typename?: 'Mutation' } & {
  registerPhoneUser: { __typename?: 'User' } & Pick<
    User,
    'phones' | 'firstName' | 'lastName' | '_id'
  >;
};

export type ResendEmailVerifyMutationVariables = {
  params: ResendAccountEmailVerify;
};

export type ResendEmailVerifyMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resendEmailVerify'
>;

export type ResendOtpMutationVariables = {
  params: ResendOtpInput;
};

export type ResendOtpMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resendOTP'
>;

export type GetCalendarConfigInfoQueryVariables = {
  params: GetCalendarConfigInput;
};

export type GetCalendarConfigInfoQuery = { __typename?: 'Query' } & {
  getCalendarConfigInfo: { __typename?: 'CalendarConfigRes' } & {
    nodes: Array<{ __typename?: 'CalendarConfig' } & CalendarConfigFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type CreateCalendarConfigMutationVariables = {
  params: CreateCalendarConfigInput;
};

export type CreateCalendarConfigMutation = { __typename?: 'Mutation' } & {
  createCalendarConfig: Array<
    { __typename?: 'ShareTreatmentRes' } & ResponseShareTreatmentResFragment
  >;
};

export type DeleteCalendarConfigMutationVariables = {
  params: DeleteCalendarConfigInput;
};

export type DeleteCalendarConfigMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCalendarConfig'
>;

export type GetConfigByGroupQueryVariables = {
  params: IdInputType;
};

export type GetConfigByGroupQuery = { __typename?: 'Query' } & {
  getConfigByGroup: Array<
    { __typename?: 'CalendarConfig' } & CalendarConfigFragment
  >;
};

export type CreateChartConfigMutationVariables = {
  params: CreateChartConfigInput;
};

export type CreateChartConfigMutation = { __typename?: 'Mutation' } & {
  createChartConfig: { __typename?: 'ChartConfig' } & Pick<ChartConfig, '_id'>;
};

export type CreateChartConfigsMutationVariables = {
  params: CreateChartConfigsInput;
};

export type CreateChartConfigsMutation = { __typename?: 'Mutation' } & {
  createChartConfigs: Array<
    { __typename?: 'ChartConfig' } & Pick<ChartConfig, '_id'>
  >;
};

export type UpdateChartConfigMutationVariables = {
  params: UpdateChartConfigInput;
};

export type UpdateChartConfigMutation = { __typename?: 'Mutation' } & {
  updateChartConfig: Maybe<
    { __typename?: 'ChartConfig' } & Pick<ChartConfig, '_id'>
  >;
};

export type DeleteChartConfigMutationVariables = {
  params: IdInputType;
};

export type DeleteChartConfigMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteChartConfig'
>;

export type CreateChecklistMutationVariables = {
  params: CreateChecklistInput;
};

export type CreateChecklistMutation = { __typename?: 'Mutation' } & {
  createChecklist: {
    __typename?: 'Treatment';
  } & ResponseModifyTreatmentFragment;
};

export type UpdateChecklistMutationVariables = {
  params: UpdateChecklistInput;
};

export type UpdateChecklistMutation = { __typename?: 'Mutation' } & {
  updateChecklist: Maybe<
    { __typename?: 'Treatment' } & ResponseModifyTreatmentFragment
  >;
};

export type DeleteChecklistMutationVariables = {
  params: IdInputType;
};

export type DeleteChecklistMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteChecklist'
>;

export type GetChecklistForAttachQueryVariables = {
  params: GetChecklistForPatientInput;
};

export type GetChecklistForAttachQuery = { __typename?: 'Query' } & {
  getChecklistForPatient: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & TreatmentForAddUpdateFragment>;
  };
};

export type AttachChecklistMutationVariables = {
  params: AttachChecklistInput;
};

export type AttachChecklistMutation = { __typename?: 'Mutation' } & {
  attachChecklist: Maybe<{ __typename?: 'Treatment' } & TreatmentTreeFragment>;
};

export type DetachChecklistMutationVariables = {
  params: AttachChecklistInput;
};

export type DetachChecklistMutation = { __typename?: 'Mutation' } & {
  detachChecklist: Maybe<{ __typename?: 'Treatment' } & TreatmentTreeFragment>;
};

export type GetPatientChecklistsQueryVariables = {
  params: GetPatientChecklistInput;
};

export type GetPatientChecklistsQuery = { __typename?: 'Query' } & {
  getPatientChecklists: { __typename?: 'PatientChecklistRes' } & {
    nodes: Array<
      { __typename?: 'PatientChecklistMono' } & PatientChecklistFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetPatientChecklistQueryVariables = {
  params: GetAPatientChecklistInput;
};

export type GetPatientChecklistQuery = { __typename?: 'Query' } & {
  getPatientChecklist: Maybe<
    { __typename?: 'PatientChecklistMono' } & PatientChecklistSurveyFragment
  >;
};

export type AttachPatientChecklistMutationVariables = {
  params: UpdatePatientChecklistInput;
};

export type AttachPatientChecklistMutation = { __typename?: 'Mutation' } & {
  attachPatientChecklist: Maybe<
    { __typename?: 'PatientChecklistMono' } & PatientChecklistFragment
  >;
};

export type UpdateChecklistResultMutationVariables = {
  params: UpdateChecklistResultInput;
};

export type UpdateChecklistResultMutation = { __typename?: 'Mutation' } & {
  updateChecklistResult: Maybe<
    { __typename?: 'PatientChecklistMono' } & PatientChecklistFragment
  >;
};

export type DetachPatientChecklistMutationVariables = {
  params: DetachPatientChecklistInput;
};

export type DetachPatientChecklistMutation = { __typename?: 'Mutation' } & {
  detachPatientChecklist: Maybe<
    { __typename?: 'PatientChecklistMono' } & PatientChecklistFragment
  >;
};

export type DeletePatientChecklistMutationVariables = {
  params: DetachPatientChecklistInput;
};

export type DeletePatientChecklistMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePatientChecklist'
>;

export type GetLatestChecklistDataQueryVariables = {
  params: GetPatientChecklistInput;
};

export type GetLatestChecklistDataQuery = { __typename?: 'Query' } & {
  getLatestChecklistData: Array<
    { __typename?: 'ResultChecklist' } & Pick<
      ResultChecklist,
      '_id' | 'status' | 'checklistResult'
    >
  >;
};

export type GetListChecklistQueryVariables = {
  params?: GetTreatmentsInput;
};

export type GetListChecklistQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>
    >;
  };
};

export type GetTreatmentQueryVariables = {
  params: GetTreatmentInput;
};

export type GetTreatmentQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<
      Treatment,
      '_id' | 'name' | 'surveyData'
    >
  >;
};

export type GetDetailChecklistQueryVariables = {
  paramsChecklist: GetTreatmentInput;
  paramsResultsChecklist: GetPatientChecklistByChecklist;
};

export type GetDetailChecklistQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<
      Treatment,
      '_id' | 'name' | 'surveyData'
    >
  >;
  getPatientChecklistData: Array<
    { __typename?: 'PatientChecklistMono' } & Pick<
      PatientChecklistMono,
      '_id'
    > & {
        user: { __typename?: 'Patient' } & Pick<
          Patient,
          '_id' | 'firstName' | 'middleName' | 'lastName'
        >;
        patientChecklistDatas: Maybe<
          {
            __typename?: 'PatientChecklistEmbedded';
          } & PatientChecklistEmbeddedFragment
        >;
      }
  >;
};

export type GetDetailChecklistFromConfigQueryVariables = {
  paramsChecklist: GetPatientChecklistFromChartConfigInput;
  paramsResultsChecklist: GetPatientChecklistFromChartConfigInput;
};

export type GetDetailChecklistFromConfigQuery = { __typename?: 'Query' } & {
  getChecklistFromChartConfig: { __typename?: 'Treatment' } & Pick<
    Treatment,
    '_id' | 'surveyData' | 'name'
  >;
  getPatientChecklistFromChartConfig: Array<
    { __typename?: 'PatientChecklistMono' } & Pick<
      PatientChecklistMono,
      '_id'
    > & {
        user: { __typename?: 'Patient' } & Pick<
          Patient,
          '_id' | 'firstName' | 'lastName' | 'middleName'
        >;
        patientChecklistDatas: Maybe<
          {
            __typename?: 'PatientChecklistEmbedded';
          } & PatientChecklistEmbeddedFragment
        >;
      }
  >;
};

export type CreateCommentMutationVariables = {
  params: CreateCommentInput;
};

export type CreateCommentMutation = { __typename?: 'Mutation' } & {
  createComment: { __typename?: 'CommentExt' } & CommentFragment;
};

export type GetCommentsQueryVariables = {
  params: GetCommentInput;
};

export type GetCommentsQuery = { __typename?: 'Query' } & {
  getComments: { __typename?: 'CommentsCursorRes' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'hasNextPage' | 'endCursor'
    >;
    edges: Array<{ __typename?: 'CommentEdge' } & CommentEdgeFragment>;
  };
};

export type GetGroupCommentsQueryVariables = {
  params: CursorGetGroupCommentInput;
};

export type GetGroupCommentsQuery = { __typename?: 'Query' } & {
  getGroupComments: { __typename?: 'CommentGroupsCursorRes' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'hasNextPage' | 'endCursor'
    >;
    edges: Array<
      { __typename?: 'CommentGroupEdge' } & {
        node: { __typename?: 'CommentGroupRes' } & GroupCommentsFragment;
      }
    >;
  };
};

export type GetGroupCommentsByPatientQueryVariables = {
  params: IdInputType;
};

export type GetGroupCommentsByPatientQuery = { __typename?: 'Query' } & {
  getGroupCommentsByPatient: { __typename?: 'CommentGroupsCursorRes' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'hasNextPage' | 'endCursor'
    >;
    edges: Array<
      { __typename?: 'CommentGroupEdge' } & {
        node: { __typename?: 'CommentGroupRes' } & GroupCommentsFragment;
      }
    >;
  };
};

export type GetUnreadAllCommentQueryVariables = {};

export type GetUnreadAllCommentQuery = { __typename?: 'Query' } & {
  getUnreadAllComment: Array<
    { __typename?: 'CommentGroupRes' } & Pick<
      CommentGroupRes,
      '_id' | 'unreadCount'
    >
  >;
};

export type SeenCommentMutationVariables = {
  params: SeenCommentInput;
};

export type SeenCommentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'seenComment'
>;

export type GetPatientCommentQueryVariables = {
  params: GetGroupCommentInput;
};

export type GetPatientCommentQuery = { __typename?: 'Query' } & {
  getUnreadCommentsPatientList: { __typename?: 'CommentGroupsCursorRes' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'hasNextPage' | 'endCursor'
    >;
    edges: Array<
      { __typename?: 'CommentGroupEdge' } & {
        node: {
          __typename?: 'CommentGroupRes';
        } & GroupCommentByPatientFragment;
      }
    >;
  };
};

export type GetAllUnreadPatientCommentQueryVariables = {
  params?: GetGroupCommentInput;
};

export type GetAllUnreadPatientCommentQuery = { __typename?: 'Query' } & {
  getUnreadCommentsPatientList: { __typename?: 'CommentGroupsCursorRes' } & {
    edges: Array<
      { __typename?: 'CommentGroupEdge' } & {
        node: { __typename?: 'CommentGroupRes' } & Pick<
          CommentGroupRes,
          '_id' | 'unreadCount' | 'lastCommentAt'
        >;
      }
    >;
  };
};

export type DeleteCommentMutationVariables = {
  params: DeleteCommentInput;
};

export type DeleteCommentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteComment'
>;

export type GetListConditionsQueryVariables = {
  params: GetConditionSettingInput;
};

export type GetListConditionsQuery = { __typename?: 'Query' } & {
  getListConditions: { __typename?: 'ConditionSettingRes' } & {
    nodes: Array<
      { __typename?: 'ConditionSetting' } & ConditionSettingFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type CreateConditionSettingMutationVariables = {
  params: CreateConditionSettingInput;
};

export type CreateConditionSettingMutation = { __typename?: 'Mutation' } & {
  createConditionSetting: { __typename?: 'ConditionSetting' } & {
    idCondition: ConditionSetting['_id'];
  };
};

export type UpdateConditionSettingMutationVariables = {
  params: UpdateConditionSettingInput;
};

export type UpdateConditionSettingMutation = { __typename?: 'Mutation' } & {
  updateConditionSetting: {
    __typename?: 'ConditionSetting';
  } & ConditionSettingFragment;
};

export type DeleteConditionSettingMutationVariables = {
  params: DeleteConditionSettingInput;
};

export type DeleteConditionSettingMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteConditionSetting'
>;

export type GetListPatientStagesQueryVariables = {
  params: GetPatientStageInput;
};

export type GetListPatientStagesQuery = { __typename?: 'Query' } & {
  getListPatientStages: { __typename?: 'PatientCondStageRes' } & {
    nodes: Array<
      { __typename?: 'PatientCondStage' } & PatientCondStageFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type CreatePatientStageMutationVariables = {
  params: CreatePatientStageInput;
};

export type CreatePatientStageMutation = { __typename?: 'Mutation' } & {
  createPatientStage: { __typename?: 'PatientCondStage' } & Pick<
    PatientCondStage,
    '_id'
  >;
};

export type UpdatePatientStageMutationVariables = {
  params: UpdatePatientStageInput;
};

export type UpdatePatientStageMutation = { __typename?: 'Mutation' } & {
  updatePatientStage: { __typename?: 'PatientCondStage' } & Pick<
    PatientCondStage,
    '_id'
  >;
};

export type DeletePatientStageMutationVariables = {
  params: DeletePatientStageInput;
};

export type DeletePatientStageMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePatientStage'
>;

export type GetConditionsForModifyQueryVariables = {
  params: GetConditionSettingInput;
};

export type GetConditionsForModifyQuery = { __typename?: 'Query' } & {
  getListConditions: { __typename?: 'ConditionSettingRes' } & {
    nodes: Array<
      { __typename?: 'ConditionSetting' } & ConditionSettingForModifyFragment
    >;
  };
};

export type GetCurrentPatientStageQueryVariables = {
  params: GetCurrentPatientStageInput;
};

export type GetCurrentPatientStageQuery = { __typename?: 'Query' } & {
  getCurrentPatientStage: Maybe<
    { __typename?: 'ConditionSetting' } & Pick<ConditionSetting, '_id'> & {
        stages: Maybe<
          Array<
            { __typename?: 'StageEmbedded' } & Pick<
              StageEmbedded,
              '_id' | 'name'
            >
          >
        >;
      }
  >;
};

export type GetCptCodeQueryVariables = {
  params: GetCptCodeInput;
};

export type GetCptCodeQuery = { __typename?: 'Query' } & {
  getCPTCode: { __typename?: 'CPTCodeRes' } & {
    nodes: Array<{ __typename?: 'CPTCode' } & CptCodeFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetCptCodeDetailsQueryVariables = {
  params: GetCptDetailsInput;
};

export type GetCptCodeDetailsQuery = { __typename?: 'Query' } & {
  getCPTCodeDetails: Maybe<
    { __typename?: 'CPTCode' } & Pick<CptCode, '_id' | 'code'> & {
        patientNoteTypes: Maybe<
          Array<
            {
              __typename?: 'PatientNoteTypeSetting';
            } & PatientNoteTypeSettingFragment
          >
        >;
      }
  >;
};

export type CreateCptCodeMutationVariables = {
  params: CreateCptCodeInput;
};

export type CreateCptCodeMutation = { __typename?: 'Mutation' } & {
  createCPTCode: Maybe<{ __typename?: 'CPTCode' } & Pick<CptCode, '_id'>>;
};

export type UpdateCptCodeMutationVariables = {
  params: UpdateCptCodeInput;
};

export type UpdateCptCodeMutation = { __typename?: 'Mutation' } & {
  updateCPTCode: Maybe<{ __typename?: 'CPTCode' } & CptCodeFragment>;
};

export type DeleteCptCodeMutationVariables = {
  params: DeleteCptCodeInput;
};

export type DeleteCptCodeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCPTCode'
>;

export type GetPatientTimeRecordQueryVariables = {
  params: GetPatientTimeRecordInput;
};

export type GetPatientTimeRecordQuery = { __typename?: 'Query' } & {
  getPatientTimeRecord: { __typename?: 'PatientTimeRecordPaginationRes' } & {
    nodes: Array<
      { __typename?: 'PatientTimeRecordRes' } & PatientTimeRecordFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type AddPatientTimeRecordMutationVariables = {
  params: AddTimeRecordInput;
};

export type AddPatientTimeRecordMutation = { __typename?: 'Mutation' } & {
  addPatientTimeRecord: {
    __typename?: 'PatientCPTCode';
  } & PatientCptCodeFragment;
};

export type UpdatePatientTimeRecordMutationVariables = {
  params: UpdateRecordsInput;
};

export type UpdatePatientTimeRecordMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'updatePatientTimeRecord'>;

export type StopPatientTimeRecordMutationVariables = {
  params: StopRecordInput;
};

export type StopPatientTimeRecordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'stopPatientTimeRecord'
>;

export type DeletePatientTimeRecordMutationVariables = {
  params: DeleteRecordInput;
};

export type DeletePatientTimeRecordMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'deletePatientTimeRecord'>;

export type UpdatePatientTimeRecordStatusMutationVariables = {
  params: UpdatePatientTimeRecordStatusInput;
};

export type UpdatePatientTimeRecordStatusMutation = {
  __typename?: 'Mutation';
} & {
  updatePatientTimeRecordStatus: {
    __typename?: 'PatientTimeReportRes';
  } & PatientTimeReportFragment;
};

export type GetDashboardsQueryVariables = {};

export type GetDashboardsQuery = { __typename?: 'Query' } & {
  getDashboards: Array<{ __typename?: 'DashboardRes' } & DashboardResFragment>;
};

export type GetDashboardQueryVariables = {
  params: IdInputType;
};

export type GetDashboardQuery = { __typename?: 'Query' } & {
  getDashboard: Maybe<{ __typename?: 'DashboardRes' } & DashboardResFragment>;
};

export type CreateDashboardMutationVariables = {
  params: CreateDashboardInput;
};

export type CreateDashboardMutation = { __typename?: 'Mutation' } & {
  createDashboard: { __typename?: 'Dashboard' } & DashboardFragment;
};

export type UpdateDashboardMutationVariables = {
  params: UpdateDashboardInput;
};

export type UpdateDashboardMutation = { __typename?: 'Mutation' } & {
  updateDashboard: { __typename?: 'Dashboard' } & DashboardFragment;
};

export type DeleteDashboardMutationVariables = {
  params: IdInputType;
};

export type DeleteDashboardMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteDashboard'
>;

export type UpdateOrderChartMutationVariables = {
  params: UpdateOrderDashboardInput;
};

export type UpdateOrderChartMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateOrderDashboard'
>;

export type GetNavigatorsToShareQueryVariables = {
  params: GetUsersInput;
};

export type GetNavigatorsToShareQuery = { __typename?: 'Query' } & {
  getNavigators: { __typename?: 'UsersRes' } & {
    nodes: Array<
      { __typename?: 'User' } & Pick<
        User,
        | '_id'
        | 'email'
        | 'firstName'
        | 'middleName'
        | 'lastName'
        | 'status'
        | 'role'
      >
    >;
  };
};

export type ShareDashboardMutationVariables = {
  params: ShareDashboardInput;
};

export type ShareDashboardMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'shareDashboard'
>;

export type SetShowDashboardsMutationVariables = {
  params: Array<UpdateDashboardInput>;
};

export type SetShowDashboardsMutation = { __typename?: 'Mutation' } & {
  setShowDashboards: Array<
    { __typename?: 'Dashboard' } & Pick<Dashboard, '_id' | 'name' | 'isShow'>
  >;
};

export type GetListFolderQueryVariables = {
  params?: GetTreatmentsInput;
};

export type GetListFolderQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>
    >;
  };
};

export type GetTreeNodeQueryVariables = {
  params: GetNodeTreeInput;
};

export type GetTreeNodeQuery = { __typename?: 'Query' } & {
  getTreeNodes: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        '_id' | 'name' | 'type'
      > & {
          parent: Maybe<{ __typename?: 'Treatment' } & Pick<Treatment, 'type'>>;
        }
    >;
  };
};

export type GetEmrLogsQueryVariables = {
  params: GetInboundEmrLogInput;
};

export type GetEmrLogsQuery = { __typename?: 'Query' } & {
  getEmrLogs: { __typename?: 'EmrLogRes' } & {
    nodes: Array<{ __typename?: 'EmrLog' } & EmrLogFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type EmrMatchingPatientMutationVariables = {
  params: MatchingPatientInput;
};

export type EmrMatchingPatientMutation = { __typename?: 'Mutation' } & {
  emrMatchingPatient: { __typename?: 'CreatePatientRes' } & Pick<
    CreatePatientRes,
    'result'
  > & {
      data: Maybe<Array<{ __typename?: 'Patient' } & SameNewPatientFragment>>;
      userExists: Maybe<Array<{ __typename?: 'User' } & DuplicateUserFragment>>;
    };
};

export type UpdateEmrLogMutationVariables = {
  params: IdInputType;
};

export type UpdateEmrLogMutation = { __typename?: 'Mutation' } & {
  updateEmrLog: { __typename?: 'EmrLog' } & EmrLogFragment;
};

export type CreateFolderMutationVariables = {
  params: CreateFolderInput;
};

export type CreateFolderMutation = { __typename?: 'Mutation' } & {
  createFolder: { __typename?: 'Treatment' } & ResponseModifyTreatmentFragment;
};

export type UpdateFolderInfoMutationVariables = {
  params: UpdateFolderInfoInput;
};

export type UpdateFolderInfoMutation = { __typename?: 'Mutation' } & {
  updateFolderInfo: {
    __typename?: 'Treatment';
  } & ResponseModifyTreatmentFragment;
};

export type GetFollowUpTypesQueryVariables = {
  params: GetFollowUpTypesInput;
};

export type GetFollowUpTypesQuery = { __typename?: 'Query' } & {
  getFollowUpTypes: Maybe<
    { __typename?: 'FollowUpTypeRes' } & {
      nodes: Array<
        { __typename?: 'FollowUpTypeSetting' } & FollowUpTypeSettingFragment
      >;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type CreateFollowUpTypeMutationVariables = {
  params: CreateFollowUpTypeInput;
};

export type CreateFollowUpTypeMutation = { __typename?: 'Mutation' } & {
  createFollowUpType: { __typename?: 'FollowUpTypeSetting' } & {
    idFollowUp: FollowUpTypeSetting['_id'];
  };
};

export type UpdateFollowUpTypeMutationVariables = {
  params: UpdateFollowUpTypeInput;
};

export type UpdateFollowUpTypeMutation = { __typename?: 'Mutation' } & {
  updateFollowUpType: {
    __typename?: 'FollowUpTypeSetting';
  } & FollowUpTypeSettingFragment;
};

export type DeleteFollowUpTypeMutationVariables = {
  params: DeleteFollowUpTypeInput;
};

export type DeleteFollowUpTypeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteFollowUpType'
>;

export type GetAssociatedFollowUpTypesQueryVariables = {
  params: GetAssociatedFollowUpTypesInput;
};

export type GetAssociatedFollowUpTypesQuery = { __typename?: 'Query' } & {
  getAssociatedFollowUpTypes: Maybe<
    { __typename?: 'FollowUpTypeRes' } & {
      nodes: Array<
        { __typename?: 'FollowUpTypeSetting' } & Pick<
          FollowUpTypeSetting,
          '_id' | 'description' | 'name' | 'color'
        >
      >;
    }
  >;
};

export type HandleFavoriteFollowUpTypeMutationVariables = {
  params: UpdateFavoriteFollowUpTypeInput;
};

export type HandleFavoriteFollowUpTypeMutation = { __typename?: 'Mutation' } & {
  handleFavoriteFollowUpType: {
    __typename?: 'FollowUpTypeSetting';
  } & FollowUpTypeSettingFragment;
};

export type RemoveOneTreatmentMutationVariables = {
  params: RemoveOneTreatmentInput;
};

export type RemoveOneTreatmentMutation = { __typename?: 'Mutation' } & {
  removeOneTreatment: Maybe<
    { __typename?: 'FollowUpTypeSetting' } & Pick<
      FollowUpTypeSetting,
      '_id'
    > & {
        treatment: Maybe<
          Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
        >;
        folder: Maybe<
          Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
        >;
      }
  >;
};

export type AddMoreTreatmentMutationVariables = {
  params: AddMoreTreatmentInput;
};

export type AddMoreTreatmentMutation = { __typename?: 'Mutation' } & {
  addMoreTreatment: { __typename?: 'FollowUpTypeSetting' } & Pick<
    FollowUpTypeSetting,
    '_id'
  > & {
      treatment: Maybe<
        Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
      >;
      folder: Maybe<
        Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
      >;
    };
};

export type MetaFragment = { __typename?: 'MetaRes' } & Pick<
  MetaRes,
  'totalPage' | 'limit' | 'page' | 'total'
>;

export type SurveyFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  'name' | 'surveyData' | 'order' | 'createdAt' | 'isPrivate'
> & { idSurvey: Treatment['_id'] };

export type OwnerFragment = { __typename?: 'User' } & Pick<
  User,
  'firstName' | 'middleName' | 'lastName'
> & { idOwner: User['_id'] };

export type OwnerListFragment = { __typename?: 'User' } & Pick<User, '_id'>;

export type SubTreatmentDefaultFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | '_id'
  | 'mainImage'
  | 'mainVideo'
  | 'name'
  | 'description'
  | 'shortDescription'
  | 'createdAt'
  | 'thumbnail'
  | 'isSurveyInline'
  | 'smsMessage'
>;

export type TreatmentDefaultFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | '_id'
  | 'mainImage'
  | 'mainVideo'
  | 'name'
  | 'description'
  | 'shortDescription'
  | 'createdAt'
  | 'thumbnail'
  | 'isSurveyInline'
  | 'smsMessage'
>;

export type UserFragment = { __typename?: 'User' } & Pick<
  User,
  | '_id'
  | 'email'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'fullName'
  | 'role'
  | 'dob'
  | 'mrn'
  | 'phones'
  | 'status'
  | 'isNoEmail'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<Organization, 'name'> & {
          idOrg: Organization['_id'];
        }
    >;
    authenticationInfo: Maybe<
      Array<
        { __typename?: 'AuthInfo' } & Pick<AuthInfo, 'authenticationSource'>
      >
    >;
  };

export type DuplicateUserFragment = { __typename?: 'User' } & Pick<
  User,
  '_id' | 'email' | 'firstName' | 'lastName' | 'middleName' | 'dob' | 'phones'
>;

export type UserListFragment = { __typename?: 'MeRes' } & Pick<
  MeRes,
  | '_id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'middleName'
  | 'role'
  | 'phones'
  | 'isBlankAccount'
  | 'status'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<Organization, 'name'> & {
          idOrg: Organization['_id'];
        }
    >;
    patient: Maybe<
      { __typename?: 'Patient' } & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<Organization, 'name'> & {
              idPatientOrg: Organization['_id'];
            }
        >;
      }
    >;
    authenticationInfo: Maybe<
      Array<
        { __typename?: 'AuthInfo' } & Pick<AuthInfo, 'authenticationSource'>
      >
    >;
    secondaryEmail: Maybe<
      Array<
        { __typename?: 'SecondaryEmailInfo' } & Pick<
          SecondaryEmailInfo,
          'email' | 'isVerified'
        >
      >
    >;
  };

export type TreatmentStageFragment = { __typename?: 'TreatmentStage' } & Pick<
  TreatmentStage,
  'name'
> & { stageId: TreatmentStage['_id'] } & {
    linkTo: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, 'name'> & {
          treatmentId: Treatment['_id'];
        }
    >;
  };

export type TreatmentStagesFragment = {
  __typename?: 'TreatmentStageEmbed';
} & Pick<TreatmentStageEmbed, 'name'> & {
    treatmentStage: Maybe<
      { __typename?: 'TreatmentStage' } & TreatmentStageFragment
    >;
  };

export type SubTreatmentDetailFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | 'name'
  | 'shortDescription'
  | 'description'
  | 'content'
  | 'thumbnail'
  | 'mainVideo'
  | 'createdAt'
  | 'mainImage'
  | 'order'
  | 'slug'
  | 'qrCode'
  | 'urlSharing'
  | 'smsMessage'
> & { subId: Treatment['_id'] } & {
    stageLinkTo: Maybe<
      { __typename?: 'TreatmentStage' } & Pick<TreatmentStage, '_id' | 'name'>
    >;
    owner: Maybe<{ __typename?: 'User' } & OwnerFragment>;
    survey: Maybe<Array<{ __typename?: 'Treatment' } & SurveyFragment>>;
    subSections: Maybe<
      { __typename?: 'User' } & Pick<
        Treatment,
        | 'name'
        | 'shortDescription'
        | 'description'
        | 'content'
        | 'thumbnail'
        | 'mainVideo'
        | 'createdAt'
        | 'mainImage'
        | 'order'
        | 'slug'
      >
    >;
  };

export type RelatedArticlesFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | '_id'
  | 'name'
  | 'label'
  | 'shortDescription'
  | 'description'
  | 'content'
  | 'thumbnail'
  | 'mainVideo'
  | 'mainImage'
  | 'slug'
  | 'isReference'
  | 'smsMessage'
  | 'survey'
  | 'qrCode'
  | 'urlSharing'
>;

export type TreatmentFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | 'isRoot'
  | 'parentId'
  | 'qrCode'
  | 'urlSharing'
  | 'subSectionIds'
  | 'version'
  | 'surveyData'
  | 'slug'
> & {
    subSections: Maybe<
      Array<{ __typename?: 'Treatment' } & SubTreatmentDetailFragment>
    >;
    survey: Maybe<Array<{ __typename?: 'Treatment' } & SurveyFragment>>;
    owner: Maybe<{ __typename?: 'User' } & OwnerFragment>;
    treatmentStages: Maybe<
      Array<{ __typename?: 'TreatmentStageEmbed' } & TreatmentStagesFragment>
    >;
    stageLinkTo: Maybe<
      { __typename?: 'TreatmentStage' } & {
        treatment: Maybe<
          { __typename?: 'Treatment' } & {
            treatmentStages: Maybe<
              Array<
                { __typename?: 'TreatmentStageEmbed' } & TreatmentStagesFragment
              >
            >;
          }
        >;
      }
    >;
    favorites: Maybe<Array<{ __typename?: 'User' } & Pick<User, '_id'>>>;
  } & TreatmentDefaultFragment;

export type SubTreatmentFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  'order' | 'slug' | 'qrCode' | 'urlSharing'
> & {
    owner: Maybe<{ __typename?: 'User' } & OwnerFragment>;
    survey: Maybe<Array<{ __typename?: 'Treatment' } & SurveyFragment>>;
  } & SubTreatmentDefaultFragment;

export type TreatmentDetailFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | 'content'
  | 'isRoot'
  | 'slug'
  | 'hideMainMedia'
  | 'parentId'
  | 'qrCode'
  | 'urlSharing'
  | 'contributorIds'
  | 'pdfLink'
> & {
    owner: Maybe<{ __typename?: 'User' } & OwnerFragment>;
    subSections: Maybe<
      Array<{ __typename?: 'Treatment' } & SubTreatmentDetailFragment>
    >;
    survey: Maybe<Array<{ __typename?: 'Treatment' } & SurveyFragment>>;
    treatmentStages: Maybe<
      Array<{ __typename?: 'TreatmentStageEmbed' } & TreatmentStagesFragment>
    >;
    organization: { __typename?: 'Organization' } & OrganizationFragment;
    relatedArticles: Array<RelatedArticlesFragment>;
  } & TreatmentDefaultFragment;

export type ResponseModifyTreatmentFragment = {
  __typename?: 'Treatment';
} & Pick<
  Treatment,
  | '_id'
  | 'name'
  | 'parentId'
  | 'ownerId'
  | 'version'
  | 'type'
  | 'order'
  | 'createdAt'
  | 'smsMessage'
> & {
    parent: Maybe<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        '_id' | 'name' | 'type'
      > & {
          survey: Maybe<
            Array<
              { __typename?: 'Treatment' } & Pick<
                Treatment,
                '_id' | 'name' | 'surveyData' | 'smsMessage'
              >
            >
          >;
        }
    >;
    checklist: Maybe<
      Array<{ __typename?: 'Treatment' } & { idChecklist: Treatment['_id'] }>
    >;
  };

export type TreatmentTreeFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | 'qrCode'
  | 'urlSharing'
  | 'isReference'
  | 'contributorIds'
  | 'isHideOnHomePage'
  | 'slug'
  | 'isDefault'
  | 'isPrivate'
> & {
    checklist: Maybe<
      Array<
        { __typename?: 'Treatment' } & Pick<
          Treatment,
          'name' | 'ownerId' | 'contributorIds'
        > & { idChecklist: Treatment['_id'] }
      >
    >;
    reference: Array<
      { __typename?: 'Treatment' } & { idReference: Treatment['_id'] }
    >;
    rootRef: Maybe<
      { __typename?: 'Treatment' } & { idRootRef: Treatment['_id'] }
    >;
    favorites: Maybe<Array<{ __typename?: 'User' } & { idUser: User['_id'] }>>;
    tags: Maybe<
      Array<
        { __typename?: 'UpdateTagTreatmentTreeEmbedded' } & Pick<
          UpdateTagTreatmentTreeEmbedded,
          'name' | 'isAssignTag'
        > & {
            tag: { __typename?: 'Tag' } & Pick<Tag, '_id'>;
          }
      >
    >;
    survey: Maybe<
      Array<
        { __typename?: 'Treatment' } & Pick<
          Treatment,
          '_id' | 'name' | 'surveyData' | 'smsMessage'
        >
      >
    >;
  } & ResponseModifyTreatmentFragment;

export type TreatmentForAddUpdateFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  '_id' | 'name' | 'type'
>;

export type ResponseRemoteLoginFragment = { __typename?: 'User' } & Pick<
  User,
  '_id' | 'email' | 'firstName' | 'lastName' | 'middleName' | 'role' | 'phones'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<Organization, 'name'> & {
          idOrg: Organization['_id'];
        } & {
          defaultTreatment: Maybe<
            { __typename?: 'Treatment' } & { idTreatment: Treatment['_id'] }
          >;
          timezone: Maybe<
            { __typename?: 'Timezone' } & Pick<
              Timezone,
              'value' | 'offset' | 'label'
            >
          >;
        }
    >;
    authenticationInfo: Maybe<
      Array<
        { __typename?: 'AuthInfo' } & Pick<AuthInfo, 'authenticationSource'>
      >
    >;
    secondaryEmail: Maybe<
      Array<
        { __typename?: 'SecondaryEmailInfo' } & Pick<
          SecondaryEmailInfo,
          'email' | 'isVerified'
        >
      >
    >;
    setting: Maybe<
      { __typename?: 'ConfigEmbedded' } & Pick<ConfigEmbedded, 'perPage'> & {
          patientDashboard: Maybe<
            { __typename?: 'PatientDashboardConfig' } & Pick<
              PatientDashboardConfig,
              'sort'
            >
          >;
          analyticDashboard: Maybe<
            { __typename?: 'AnalyticDashboardConfig' } & Pick<
              AnalyticDashboardConfig,
              'dateRange'
            >
          >;
          homePage: Maybe<
            { __typename?: 'HomePageTreatmentConfig' } & Pick<
              HomePageTreatmentConfig,
              'treatmentType'
            >
          >;
          leftDrawer: Array<
            Maybe<
              { __typename?: 'LeftDrawerConfig' } & Pick<
                LeftDrawerConfig,
                'treatmentType' | 'showInDrawer'
              >
            >
          >;
        }
    >;
  };

export type ResponseLoginFragment = { __typename?: 'MeRes' } & Pick<
  MeRes,
  '_id' | 'email' | 'firstName' | 'lastName' | 'middleName' | 'role' | 'phones'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<Organization, 'name'> & {
          idOrg: Organization['_id'];
        } & {
          defaultTreatment: Maybe<
            { __typename?: 'Treatment' } & { idTreatment: Treatment['_id'] }
          >;
          timezone: Maybe<
            { __typename?: 'Timezone' } & Pick<
              Timezone,
              'offset' | 'value' | 'label'
            >
          >;
        }
    >;
    authenticationInfo: Maybe<
      Array<
        { __typename?: 'AuthInfo' } & Pick<AuthInfo, 'authenticationSource'>
      >
    >;
    patient: Maybe<
      { __typename?: 'Patient' } & { idPatient: Patient['_id'] } & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<Organization, 'name'> & {
              idOrg: Organization['_id'];
            }
        >;
      }
    >;
    secondaryEmail: Maybe<
      Array<
        { __typename?: 'SecondaryEmailInfo' } & Pick<
          SecondaryEmailInfo,
          'email' | 'isVerified'
        >
      >
    >;
    setting: Maybe<
      { __typename?: 'ConfigEmbedded' } & Pick<ConfigEmbedded, 'perPage'> & {
          patientDashboard: Maybe<
            { __typename?: 'PatientDashboardConfig' } & Pick<
              PatientDashboardConfig,
              'sort'
            >
          >;
          analyticDashboard: Maybe<
            { __typename?: 'AnalyticDashboardConfig' } & Pick<
              AnalyticDashboardConfig,
              'dateRange'
            >
          >;
          homePage: Maybe<
            { __typename?: 'HomePageTreatmentConfig' } & Pick<
              HomePageTreatmentConfig,
              'treatmentType'
            >
          >;
          leftDrawer: Array<
            Maybe<
              { __typename?: 'LeftDrawerConfig' } & Pick<
                LeftDrawerConfig,
                'treatmentType' | 'showInDrawer'
              >
            >
          >;
        }
    >;
    remoteUser: Maybe<{ __typename?: 'User' } & ResponseRemoteLoginFragment>;
  };

export type DashboardFragment = { __typename?: 'Dashboard' } & Pick<
  Dashboard,
  '_id' | 'name'
> & {
    charts: Maybe<
      Array<
        { __typename?: 'ChartConfig' } & Pick<
          ChartConfig,
          'dataSource' | 'reportType' | 'chartConfig'
        > & { chartId: ChartConfig['_id'] }
      >
    >;
  };

export type DashboardChartOrderedFragment = {
  __typename?: 'ChartConfig';
} & Pick<
  ChartConfig,
  | 'dataSource'
  | 'reportType'
  | 'chartConfig'
  | 'order'
  | 'grid'
  | 'isCopy'
  | 'displayName'
  | 'surveyVersion'
> & { chartId: ChartConfig['_id'] };

export type SurveyVersionFragment = { __typename?: 'SurveyVersion' } & Pick<
  SurveyVersion,
  | '_id'
  | 'surveyData'
  | 'originSurvey'
  | 'version'
  | 'emrObx'
  | 'name'
  | 'smsMessage'
> & {
    checklist: Maybe<
      Array<
        { __typename?: 'Treatment' } & Pick<
          Treatment,
          '_id' | 'name' | 'ownerId'
        >
      >
    >;
  };

export type DashboardResFragment = { __typename?: 'DashboardRes' } & Pick<
  DashboardRes,
  '_id' | 'name' | 'isShow'
> & {
    chartsOrdered: Maybe<
      Array<{ __typename?: 'ChartConfig' } & DashboardChartOrderedFragment>
    >;
  };

export type TagFragment = { __typename?: 'Tag' } & Pick<
  Tag,
  '_id' | 'name' | 'color'
>;

export type TagInfoFragment = { __typename?: 'Tag' } & Pick<
  Tag,
  '_id' | 'name' | 'color' | 'createdAt'
>;

export type PatientTagFragment = { __typename?: 'AddTagRes' } & Pick<
  AddTagRes,
  'added' | 'isColorChanged'
> & {
    tag: { __typename?: 'Tag' } & TagFragment;
  };

export type TagEmbeddedOnPatientFragment = { __typename?: 'TagEmbedded' } & {
  tag: { __typename?: 'Tag' } & Pick<Tag, '_id'>;
};

export type PatientOwnerFragment = { __typename?: 'Patient' } & Pick<
  Patient,
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'email'
  | 'mrn'
  | 'dob'
  | 'phones'
  | 'gender'
  | 'race'
  | 'address'
  | 'status'
> & { idOwner: Patient['_id'] } & {
    emrProvider: Maybe<
      Array<
        { __typename?: 'ProviderSetting' } & Pick<
          ProviderSetting,
          '_id' | 'emrProvider'
        >
      >
    >;
    provider: Maybe<
      Array<
        { __typename?: 'ProviderSetting' } & Pick<
          ProviderSetting,
          '_id' | 'provider' | 'emrProvider'
        >
      >
    >;
    tags: Maybe<
      Array<
        Maybe<{ __typename?: 'TagEmbedded' } & TagEmbeddedOnPatientFragment>
      >
    >;
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<Organization, 'name'>
    >;
    patientConfig: Maybe<
      { __typename?: 'PatientConfig' } & {
        contactConfig: Maybe<
          { __typename?: 'ContactConfig' } & Pick<ContactConfig, 'method'>
        >;
      }
    >;
    user: Maybe<
      { __typename?: 'User' } & Pick<User, 'status'> & { idUser: User['_id'] }
    >;
  };

export type SubNoteFragment = { __typename?: 'NoteEmbedded' } & Pick<
  NoteEmbedded,
  'description' | 'notedAt' | 'status' | 'createdAt' | 'updatedAt' | 'color'
> & { idNote: NoteEmbedded['_id'] } & {
    type: Maybe<
      { __typename?: 'PatientNoteTypeSetting' } & Pick<
        PatientNoteTypeSetting,
        'color' | 'name'
      > & { idType: PatientNoteTypeSetting['_id'] }
    >;
  };

export type PatientNoteFragment = { __typename?: 'PatientNote' } & Pick<
  PatientNote,
  '_id' | 'createdAt'
> & {
    surveyResult: Maybe<
      { __typename?: 'SurveyResult' } & Pick<SurveyResult, 'createdAt'> & {
          surveyResultId: SurveyResult['_id'];
        }
    >;
    survey: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, 'name'> & {
          surveyId: Treatment['_id'];
        }
    >;
    notes: Maybe<Array<{ __typename?: 'NoteEmbedded' } & SubNoteFragment>>;
  };

export type PatientFollowUpFragment = { __typename?: 'PatientFollowUp' } & Pick<
  PatientFollowUp,
  '_id' | 'note' | 'status' | 'followUpDate' | 'createdAt' | 'updatedAt'
> & {
    type: Maybe<
      { __typename?: 'FollowUpTypeSetting' } & Pick<
        FollowUpTypeSetting,
        '_id' | 'description' | 'name' | 'color'
      >
    >;
  };

export type SurveyResultEmbeddedFragment = {
  __typename?: 'SurveyResultEmbedded';
} & Pick<
  SurveyResultEmbedded,
  | '_id'
  | 'createdAt'
  | 'updatedAt'
  | 'answerDateTime'
  | 'data'
  | 'workingStatus'
  | 'isArchive'
> & {
    updatedBy: Maybe<
      { __typename?: 'User' } & Pick<
        User,
        'firstName' | 'lastName' | 'middleName'
      >
    >;
    patientNote: Maybe<{ __typename?: 'PatientNote' } & PatientNoteFragment>;
    surveyDetail: Maybe<
      { __typename?: 'SurveyVersion' } & Pick<
        SurveyVersion,
        'name' | 'version' | 'surveyData' | 'originSurvey'
      > & { surveyId: SurveyVersion['_id'] }
    >;
  };

export type PatientSurveyFragment = {
  __typename?: 'SurveyResultGroupRes';
} & Pick<
  SurveyResultGroupRes,
  'smsMessage' | 'name' | 'version' | 'updatedAt'
> & {
    surveyResult: Maybe<
      Array<
        { __typename?: 'SurveyResultEmbedded' } & SurveyResultEmbeddedFragment
      >
    >;
  };

export type SurveyForNoteFragment = {
  __typename?: 'SurveyResultGroupRes';
} & Pick<SurveyResultGroupRes, 'name' | 'version'> & {
    idSurvey: SurveyResultGroupRes['_id'];
  } & {
    survey: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, 'name' | 'version'>
    >;
    surveyResult: Maybe<
      Array<
        { __typename?: 'SurveyResultEmbedded' } & Pick<
          SurveyResultEmbedded,
          'createdAt'
        > & { surveyResultId: SurveyResultEmbedded['_id'] } & {
            patientNote: Maybe<
              { __typename?: 'PatientNote' } & Pick<PatientNote, '_id'>
            >;
          }
      >
    >;
  };

export type SurveyPatientDashboardFragment = {
  __typename?: 'Treatment';
} & Pick<Treatment, '_id' | 'name' | 'parentId' | 'surveyData' | 'version'>;

export type SurveyResultOnPatientFragment = {
  __typename?: 'PatientDataRes';
} & Pick<
  PatientDataRes,
  '_id' | 'result' | 'survey' | 'createdAt' | 'updatedAt'
> & {
    updatedBy: Maybe<
      { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName'> & {
          updatedById: User['_id'];
        }
    >;
  };

export type CheckHightLightFragment = { __typename?: 'TreatmentStage' } & Pick<
  TreatmentStage,
  '_id'
> & {
    linkTo: Maybe<{ __typename?: 'Treatment' } & Pick<Treatment, '_id'>>;
  };

export type StageChangeLogFFragment = { __typename?: 'StageChangedLog' } & Pick<
  StageChangedLog,
  '_id' | 'startDate' | 'endDate' | 'createdAt' | 'status'
> & {
    root: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, 'name'> & {
          idRoot: Treatment['_id'];
        }
    >;
    treatment: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'> & {
          treatmentStages: Maybe<
            Array<
              { __typename?: 'TreatmentStageEmbed' } & TreatmentStagesFragment
            >
          >;
          checklist: Maybe<
            Array<
              { __typename?: 'Treatment' } & Pick<Treatment, 'name'> & {
                  checklistId: Treatment['_id'];
                }
            >
          >;
        }
    >;
    treatmentStageEmbed: Maybe<
      { __typename?: 'TreatmentStageEmbed' } & Pick<
        TreatmentStageEmbed,
        'name'
      > & {
          treatmentStage: Maybe<
            { __typename?: 'TreatmentStage' } & Pick<TreatmentStage, '_id'>
          >;
        }
    >;
  };

export type PatientChecklistSurveyFragment = {
  __typename?: 'PatientChecklistMono';
} & Pick<PatientChecklistMono, '_id'> & {
    patientChecklists: Maybe<
      { __typename?: 'PatientChecklistEmbedded' } & Pick<
        PatientChecklistEmbedded,
        'checklistResult'
      > & {
          checklist: Maybe<
            { __typename?: 'Treatment' } & Pick<Treatment, 'surveyData'>
          >;
          checklistAvailable: Maybe<
            { __typename?: 'Treatment' } & Pick<Treatment, 'surveyData'>
          >;
          resultChecklist: Maybe<
            Array<
              { __typename?: 'ResultChecklist' } & Pick<
                ResultChecklist,
                '_id' | 'status' | 'checklistResult' | 'updatedAt'
              >
            >
          >;
        }
    >;
  };

export type PatientChecklistEmbeddedFragment = {
  __typename?: 'PatientChecklistEmbedded';
} & { patientChecklistId: PatientChecklistEmbedded['_id'] } & {
  checklist: Maybe<
    { __typename?: 'Treatment' } & Pick<
      Treatment,
      '_id' | 'name' | 'surveyData'
    >
  >;
  checklistAvailable: Maybe<
    { __typename?: 'Treatment' } & Pick<
      Treatment,
      '_id' | 'name' | 'surveyData'
    >
  >;
  resultChecklist: Maybe<
    Array<
      { __typename?: 'ResultChecklist' } & Pick<
        ResultChecklist,
        '_id' | 'status' | 'checklistResult' | 'updatedAt' | 'createdAt'
      >
    >
  >;
};

export type PatientChecklistPdfFragment = {
  __typename?: 'PatientChecklistMono';
} & Pick<PatientChecklistMono, 'updatedAt'> & {
    idChecklist: PatientChecklistMono['_id'];
  } & {
    treatment: Maybe<{ __typename?: 'Treatment' } & Pick<Treatment, 'name'>>;
    survey: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>
    >;
    patientChecklists: Maybe<
      { __typename?: 'PatientChecklistEmbedded' } & Pick<
        PatientChecklistEmbedded,
        'status' | 'checklistResult'
      > & { patientChecklistId: PatientChecklistEmbedded['_id'] } & {
          checklist: Maybe<
            { __typename?: 'Treatment' } & Pick<
              Treatment,
              '_id' | 'name' | 'surveyData'
            >
          >;
          checklistAvailable: Maybe<
            { __typename?: 'Treatment' } & Pick<
              Treatment,
              '_id' | 'name' | 'surveyData'
            >
          >;
          resultChecklist: Maybe<
            Array<
              { __typename?: 'ResultChecklist' } & Pick<
                ResultChecklist,
                '_id' | 'status' | 'checklistResult' | 'updatedAt'
              >
            >
          >;
        }
    >;
  };

export type PatientChecklistFragment = {
  __typename?: 'PatientChecklistMono';
} & Pick<PatientChecklistMono, 'updatedAt'> & {
    patientChecklistMonoId: PatientChecklistMono['_id'];
  } & {
    treatment: Maybe<{ __typename?: 'Treatment' } & Pick<Treatment, 'name'>>;
    survey: Maybe<{ __typename?: 'Treatment' } & Pick<Treatment, 'name'>>;
    patientChecklists: Maybe<
      {
        __typename?: 'PatientChecklistEmbedded';
      } & PatientChecklistEmbeddedFragment
    >;
  };

export type PatientFragment = { __typename?: 'PatientRes' } & Pick<
  PatientRes,
  '_id'
> & {
    owner: Maybe<{ __typename?: 'Patient' } & PatientOwnerFragment>;
    data: Maybe<
      Array<{ __typename?: 'PatientDataRes' } & SurveyResultOnPatientFragment>
    >;
  };

export type PatientAssignFragment = { __typename?: 'PatientRes' } & Pick<
  PatientRes,
  '_id'
> & {
    owner: Maybe<
      { __typename?: 'Patient' } & Pick<
        Patient,
        'firstName' | 'lastName' | 'email' | 'phones' | 'status'
      > & { idOwner: Patient['_id'] }
    >;
  };

export type PatientDataFragment = { __typename?: 'Patient' } & Pick<
  Patient,
  '_id' | 'firstName' | 'lastName' | 'email' | 'mrn' | 'dob' | 'phones'
> & {
    tags: Maybe<
      Array<
        Maybe<{ __typename?: 'TagEmbedded' } & TagEmbeddedOnPatientFragment>
      >
    >;
    user: Maybe<
      { __typename?: 'User' } & Pick<User, 'isNoEmail'> & {
          userId: User['_id'];
        }
    >;
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<Organization, 'name'>
    >;
  };

export type CommentFragment = { __typename?: 'CommentExt' } & Pick<
  CommentExt,
  | '_id'
  | 'parentId'
  | 'message'
  | 'numberOfAnswer'
  | 'createdAt'
  | 'numberOfUnreadAnswer'
> & {
    owner: Maybe<
      { __typename?: 'User' } & Pick<
        User,
        'firstName' | 'lastName' | 'middleName' | 'status'
      > & { idOwner: User['_id'] }
    >;
    readBy: Maybe<Array<{ __typename?: 'User' } & { readById: User['_id'] }>>;
  };

export type CommentEdgeFragment = { __typename?: 'CommentEdge' } & {
  node: { __typename?: 'CommentExt' } & CommentFragment;
};

export type GroupCommentsFragment = { __typename?: 'CommentGroupRes' } & Pick<
  CommentGroupRes,
  '_id' | 'unreadCount' | 'lastCommentAt'
> & {
    treatment: Maybe<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        '_id' | 'name' | 'contributorIds' | 'ownerId'
      >
    >;
  };

export type CurrentStageFragment = { __typename?: 'CurrentStageRes' } & Pick<
  CurrentStageRes,
  '_id' | 'status'
> & {
    rootTreatment: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, 'name' | 'createdAt'> & {
          idRoot: Treatment['_id'];
        } & {
          treatmentStages: Maybe<
            Array<
              { __typename?: 'TreatmentStageEmbed' } & TreatmentStagesFragment
            >
          >;
        }
    >;
    currentTreatment: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id'>
    >;
    stage: Maybe<
      { __typename?: 'TreatmentStageEmbed' } & Pick<
        TreatmentStageEmbed,
        'name'
      > & {
          treatmentStage: Maybe<
            { __typename?: 'TreatmentStage' } & Pick<TreatmentStage, '_id'>
          >;
        }
    >;
    assignedTreatment: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, 'name'> & {
          idAssignTreatment: Treatment['_id'];
        }
    >;
  };

export type SurveyForAddSurveyFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  'name' | 'surveyData' | 'smsMessage'
> & { idSurvey: Treatment['_id'] };

export type TreatmentsForAddSurveyFragment = {
  __typename?: 'Treatment';
} & Pick<Treatment, 'name' | 'type'> & { idTreatment: Treatment['_id'] } & {
    survey: Maybe<
      Array<{ __typename?: 'Treatment' } & SurveyForAddSurveyFragment>
    >;
  };

export type CommonTreatmentFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  | 'createdAt'
  | '_id'
  | 'name'
  | 'smsMessage'
  | 'type'
  | 'mainImage'
  | 'description'
> & {
    survey: Maybe<
      Array<{ __typename?: 'Treatment' } & SurveyForAddSurveyFragment>
    >;
    parent: Maybe<{ __typename?: 'Treatment' } & Pick<Treatment, 'type'>>;
  };

export type GroupCommentByPatientFragment = {
  __typename?: 'CommentGroupRes';
} & Pick<CommentGroupRes, '_id' | 'unreadCount' | 'lastCommentAt'> & {
    user: Maybe<
      { __typename?: 'User' } & Pick<
        User,
        'firstName' | 'lastName' | 'status'
      > & { idUser: User['_id'] }
    >;
  };

export type ResponseShareTreatmentResFragment = {
  __typename?: 'ShareTreatmentRes';
} & Pick<ShareTreatmentRes, 'treatment' | 'result' | 'msg' | 'userError'> & {
    user: Maybe<
      { __typename?: 'SharedUserRes' } & Pick<
        SharedUserRes,
        'email' | 'id' | 'phones' | 'sendEmail' | 'sendSMS'
      >
    >;
  };

export type NotificationFragment = { __typename?: 'Notification' } & Pick<
  Notification,
  '_id' | 'seen' | 'msg' | 'createdAt'
> & {
    receiver: { __typename?: 'User' } & Pick<User, '_id' | 'fullName'>;
    treatment: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>
    >;
  };

export type NavigatorNotificationFragment = {
  __typename?: 'NavigatorNotification';
} & Pick<
  NavigatorNotification,
  '_id' | 'surveyName' | 'createdAt' | 'isSeen'
> & {
    owner: Maybe<
      { __typename?: 'User' } & Pick<User, '_id' | 'firstName' | 'lastName'>
    >;
  };

export type FollowUpTypeSettingFragment = {
  __typename?: 'FollowUpTypeSetting';
} & Pick<
  FollowUpTypeSetting,
  '_id' | 'name' | 'color' | 'createdAt' | 'description'
> & {
    treatment: Maybe<
      Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
    >;
    folder: Maybe<
      Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
    >;
    favorites: Maybe<Array<{ __typename?: 'User' } & { idUser: User['_id'] }>>;
  };

export type FollowUpTypeShortFragment = {
  __typename?: 'FollowUpTypeSetting';
} & Pick<FollowUpTypeSetting, '_id' | 'name' | 'description'>;

export type FollowUpTypeDescpShortFragment = {
  __typename?: 'FollowUpTypeSetting';
} & Pick<FollowUpTypeSetting, '_id' | 'description'>;

export type TreatmentFromDeleteFragment = {
  __typename?: 'TreatmentsAssignedRes';
} & Pick<TreatmentsAssignedRes, '_id' | 'numberOfPatient'> & {
    treatment: { __typename?: 'Treatment' } & Pick<Treatment, 'name'> & {
        idTreatment: Treatment['_id'];
      };
  };

export type PatientNoteTypeSettingFragment = {
  __typename?: 'PatientNoteTypeSetting';
} & Pick<
  PatientNoteTypeSetting,
  '_id' | 'name' | 'description' | 'createdAt' | 'color'
> & {
    treatments: Maybe<
      Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
    >;
    folder: Maybe<
      Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
    >;
  };

export type GroupTreatmentSettingFragment = {
  __typename?: 'GroupTreatmentSetting';
} & Pick<GroupTreatmentSetting, '_id' | 'name' | 'description'> & {
    treatment: Maybe<
      Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>
    >;
  };

export type GroupTreatmentSettingAssignFragment = {
  __typename?: 'GroupTreatmentSetting';
} & Pick<GroupTreatmentSetting, '_id' | 'name' | 'description'> & {
    treatment: Maybe<
      Array<
        { __typename?: 'Treatment' } & Pick<Treatment, 'createdAt'> &
          CommonTreatmentFragment
      >
    >;
  };

export type StageEmbeddedFragment = { __typename?: 'StageEmbedded' } & Pick<
  StageEmbedded,
  '_id' | 'name'
>;

export type ConditionSettingFragment = {
  __typename?: 'ConditionSetting';
} & Pick<ConditionSetting, '_id' | 'name' | 'createdAt'> & {
    stages: Maybe<
      Array<{ __typename?: 'StageEmbedded' } & StageEmbeddedFragment>
    >;
  };

export type ProviderSettingFragment = { __typename?: 'ProviderSetting' } & Pick<
  ProviderSetting,
  '_id' | 'provider' | 'emrProvider' | 'description'
>;

export type ConditionSettingForModifyFragment = {
  __typename?: 'ConditionSetting';
} & Pick<ConditionSetting, '_id' | 'name'>;

export type PatientCondStageFragment = {
  __typename?: 'PatientCondStage';
} & Pick<
  PatientCondStage,
  '_id' | 'color' | 'createdAt' | 'startDate' | 'endDate' | 'stageId'
> & {
    condition: Maybe<
      { __typename?: 'ConditionSetting' } & Pick<ConditionSetting, 'name'> & {
          conditionId: ConditionSetting['_id'];
        } & {
          stages: Maybe<
            Array<
              { __typename?: 'StageEmbedded' } & Pick<StageEmbedded, 'name'> & {
                  stageId: StageEmbedded['_id'];
                }
            >
          >;
        }
    >;
  };

export type FolderForTaskTypeFragment = { __typename?: 'Treatment' } & Pick<
  Treatment,
  'parentId'
> & {
    subSections: Maybe<
      Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'type'>>
    >;
  } & CommonTreatmentFragment;

export type PathToTreatmentFragment = {
  __typename?: 'TreatmentsPathRes';
} & Pick<
  TreatmentsPathRes,
  'name' | 'slug' | 'mainImage' | 'mainVideo' | 'shortDescription'
> & {
    idPath: TreatmentsPathRes['_id'];
  };

export type SamePatientFragment = { __typename?: 'User' } & Pick<
  User,
  'email' | 'firstName' | 'lastName' | 'mrn' | 'dob'
> & { idUser: User['_id'] };

export type SameNewPatientFragment = { __typename?: 'Patient' } & Pick<
  Patient,
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'gender'
  | 'dob'
  | 'phones'
> & { idUser: Patient['_id'] };

export type OrganizationFragment = { __typename?: 'Organization' } & Pick<
  Organization,
  '_id' | 'name' | 'description' | 'default' | 'inboundPath' | 'outboundPath'
> & {
    defaultTreatment: Maybe<
      { __typename?: 'Treatment' } & Pick<Treatment, 'name'>
    >;
    timezone: Maybe<
      { __typename?: 'Timezone' } & Pick<Timezone, 'offset' | 'value' | 'label'>
    >;
  };

export type PatientDashboardInfoFragment = {
  __typename?: 'PatientDashboardInfoRes';
} & Pick<
  PatientDashboardInfoRes,
  '_id' | 'name' | 'config' | 'condition' | 'isQuickView'
>;

export type PatientDashboardInfoQuickViewFragment = {
  __typename?: 'PatientDashboardInfoRes';
} & Pick<PatientDashboardInfoRes, '_id' | 'name' | 'numberOfPatient'>;

export type EmrLogFragment = { __typename?: 'EmrLog' } & Pick<
  EmrLog,
  | '_id'
  | 'fileName'
  | 'status'
  | 'fieldNote'
  | 'message'
  | 'createdAt'
  | 'patient'
  | 'newFileName'
>;

export type TwilioLogFragment = { __typename?: 'TwilioLog' } & Pick<
  TwilioLog,
  '_id' | 'status' | 'error' | 'body' | 'phoneNumber' | 'createdAt'
>;

export type ReleaseNoteSettingFragment = {
  __typename?: 'ReleaseNoteSetting';
} & Pick<ReleaseNoteSetting, '_id' | 'version' | 'releaseDate' | 'releaseNote'>;

export type AssignTreatmentResFragment = {
  __typename?: 'AssignTreatmentRes';
} & Pick<
  AssignTreatmentRes,
  'token' | 'slug' | 'organization' | 'orgName' | 'treatmentName'
> & { _id: AssignTreatmentRes['treatmentId'] };

export type ScheduleConfigEmbeddedFragment = {
  __typename?: 'ScheduleConfigEmbedded';
} & Pick<
  ScheduleConfigEmbedded,
  | 'freq'
  | 'dtstart'
  | 'until'
  | 'count'
  | 'interval'
  | 'byweekday'
  | 'bymonth'
  | 'bymonthday'
> & {
    byNweekDay: Maybe<
      { __typename?: 'WeekdayConfig' } & Pick<WeekdayConfig, 'weekday' | 'n'>
    >;
  };

export type CalendarConfigFragment = { __typename?: 'CalendarConfig' } & Pick<
  CalendarConfig,
  | '_id'
  | 'sendEmail'
  | 'sendSMS'
  | 'smsMessage'
  | 'taskDesc'
  | 'configType'
  | 'treatmentRegimenId'
  | 'regimenStart'
  | 'lastSendTime'
> & {
    patient: Maybe<
      { __typename?: 'Patient' } & Pick<Patient, '_id' | 'firstName'>
    >;
    task: Maybe<
      { __typename?: 'FollowUpTypeSetting' } & Pick<
        FollowUpTypeSetting,
        '_id' | 'name' | 'color' | 'description' | 'status' | 'createdAt'
      >
    >;
    survey: Maybe<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        '_id' | 'parentId' | 'name' | 'type'
      > & {
          parent: Maybe<
            { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>
          >;
        }
    >;
    scheduleConfig: {
      __typename?: 'ScheduleConfigEmbedded';
    } & ScheduleConfigEmbeddedFragment;
    time: Maybe<
      { __typename?: 'ConfigTimeEmbedded' } & Pick<
        ConfigTimeEmbedded,
        'hour' | 'minute'
      >
    >;
  };

export type RegimenConfigEmbeddedFragment = {
  __typename?: 'RegimenConfigEmbedded';
} & Pick<
  RegimenConfigEmbedded,
  'taskDesc' | 'specifiedTime' | 'treatmentType' | 'specifiedType'
> & {
    treatment: Maybe<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        '_id' | 'name' | 'type' | 'ownerId' | 'smsMessage'
      >
    >;
    task: Maybe<
      { __typename?: 'FollowUpTypeSetting' } & Pick<
        FollowUpTypeSetting,
        '_id' | 'name' | 'color'
      >
    >;
    updatedBy: Maybe<{ __typename?: 'User' } & Pick<User, '_id'>>;
    time: Maybe<
      { __typename?: 'RegimenConfigTimeEmbedded' } & Pick<
        RegimenConfigTimeEmbedded,
        'hour' | 'minute'
      >
    >;
    scheduleConfig: Maybe<
      { __typename?: 'ScheduleConfigEmbedded' } & ScheduleConfigEmbeddedFragment
    >;
  };

export type TreatmentRegimenFragment = {
  __typename?: 'TreatmentRegimen';
} & Pick<TreatmentRegimen, '_id' | 'regimenName'> & {
    regimenConfig: Array<
      { __typename?: 'RegimenConfigEmbedded' } & RegimenConfigEmbeddedFragment
    >;
    owner: { __typename?: 'User' } & UserFragment;
  };

export type TreatmentRegimenAddPatientFragment = {
  __typename?: 'TreatmentRegimen';
} & Pick<TreatmentRegimen, '_id'> & {
    name: TreatmentRegimen['regimenName'];
  } & {
    regimenConfig: Array<
      { __typename?: 'RegimenConfigEmbedded' } & Pick<
        RegimenConfigEmbedded,
        'treatmentType'
      > & {
          treatment: Maybe<
            { __typename?: 'Treatment' } & CommonTreatmentFragment
          >;
          task: Maybe<
            { __typename?: 'FollowUpTypeSetting' } & Pick<
              FollowUpTypeSetting,
              '_id' | 'name' | 'createdAt'
            >
          >;
        }
    >;
  };

export type UpdateTagTreatmentTreeEmbeddedFragment = {
  __typename?: 'UpdateTagTreatmentTreeEmbedded';
} & Pick<UpdateTagTreatmentTreeEmbedded, 'name' | 'isAssignTag'> & {
    tag: { __typename?: 'Tag' } & TagFragment;
  };

export type CptCodeFragment = { __typename?: 'CPTCode' } & Pick<
  CptCode,
  '_id' | 'name' | 'code' | 'desc'
> & {
    patientNoteTypes: Maybe<
      Array<
        {
          __typename?: 'PatientNoteTypeSetting';
        } & PatientNoteTypeSettingFragment
      >
    >;
  };

export type PatientCptCodeFragment = { __typename?: 'PatientCPTCode' } & Pick<
  PatientCptCode,
  '_id' | 'state' | 'startedAt' | 'stoppedAt'
> & {
    cptCode: { __typename?: 'CPTCode' } & CptCodeFragment;
    patient: { __typename?: 'Patient' } & Pick<Patient, '_id'>;
    owner: { __typename?: 'User' } & OwnerFragment;
  };

export type PatientTimeReportFragment = {
  __typename?: 'PatientTimeReportRes';
} & Pick<
  PatientTimeReportRes,
  '_id' | 'startedAt' | 'stoppedAt' | 'second' | 'status' | 'state'
> & {
    owner: { __typename?: 'User' } & Pick<User, 'role'> & OwnerFragment;
    patientNoteTypes: Maybe<
      Array<
        Maybe<
          { __typename?: 'PatientNoteTypeEmbedded' } & Pick<
            PatientNoteTypeEmbedded,
            'description'
          > & {
              id: { __typename?: 'PatientNoteTypeSetting' } & Pick<
                PatientNoteTypeSetting,
                '_id'
              >;
            }
        >
      >
    >;
    patientNoteTypeSettings: Maybe<
      Array<
        { __typename?: 'PatientNoteTypeSetting' } & Pick<
          PatientNoteTypeSetting,
          '_id' | 'name' | 'color'
        >
      >
    >;
  };

export type PatientTimeRecordFragment = {
  __typename?: 'PatientTimeRecordRes';
} & Pick<
  PatientTimeRecordRes,
  | '_id'
  | 'patient'
  | 'code'
  | 'desc'
  | 'totalSecond'
  | 'lastStartedAt'
  | 'lastStoppedAt'
> & {
    timeReport: Maybe<
      Array<{ __typename?: 'PatientTimeReportRes' } & PatientTimeReportFragment>
    >;
  };

export type SaveRecordingFragment = { __typename?: 'TSaveRecording' } & Pick<
  TSaveRecording,
  '_id' | 'startedAt' | 'stoppedAt' | 'idPatient' | 'idCptCode'
>;

export type GetTreatmentInterfaceQueryVariables = {
  params: GetTreatmentInput;
};

export type GetTreatmentInterfaceQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<{ __typename?: 'Treatment' } & TreatmentFragment>;
};

export type ShowSubTreatmentsSideBarQueryVariables = {};

export type ShowSubTreatmentsSideBarQuery = { __typename?: 'Query' } & Pick<
  Query,
  'showSubTreatmentsSideBar'
>;

export type ToogleShowSubTreatmentsSideBarMutationVariables = {
  input?: Maybe<StatusShowSubSideBar>;
};

export type ToogleShowSubTreatmentsSideBarMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'toogleShowSubTreatmentsSideBar'>;

export type ShowStatusHasingRecordQueryVariables = {};

export type ShowStatusHasingRecordQuery = { __typename?: 'Query' } & Pick<
  Query,
  'showStatusHasingRecord'
>;

export type ToogleStatusHasingRecordMutationVariables = {
  input?: Maybe<StatusRecord>;
};

export type ToogleStatusHasingRecordMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'toogleStatusHasingRecord'>;

export type ShowSaveRecordingQueryVariables = {};

export type ShowSaveRecordingQuery = { __typename?: 'Query' } & {
  showSaveRecording: Maybe<
    { __typename?: 'TSaveRecording' } & SaveRecordingFragment
  >;
};

export type ToogleSaveRecordingMutationVariables = {
  input?: Maybe<InputRecording>;
};

export type ToogleSaveRecordingMutation = { __typename?: 'Mutation' } & {
  toogleSaveRecording: Maybe<
    { __typename?: 'TSaveRecording' } & SaveRecordingFragment
  >;
};

export type ShowingTreatmentsSidebarQueryVariables = {};

export type ShowingTreatmentsSidebarQuery = { __typename?: 'Query' } & Pick<
  Query,
  'showingTreatmentsSidebar'
>;

export type AddShowingTreatmentSidebarMutationVariables = {
  input?: Maybe<Scalars['String']>;
};

export type AddShowingTreatmentSidebarMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'addShowingTreatmentSidebar'>;

export type ShowingCommentFromQueryVariables = {};

export type ShowingCommentFromQuery = { __typename?: 'Query' } & Pick<
  Query,
  'showingCommentFrom'
>;

export type ToogleShowCommentFromMutationVariables = {
  input?: Maybe<Scalars['Boolean']>;
};

export type ToogleShowCommentFromMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'toogleShowCommentFrom'
>;

export type ShowingMenuAdministrationQueryVariables = {};

export type ShowingMenuAdministrationQuery = { __typename?: 'Query' } & Pick<
  Query,
  'showingMenuAdministration'
>;

export type ToogleShowMenuAdministrationMutationVariables = {
  input?: Maybe<Scalars['Boolean']>;
};

export type ToogleShowMenuAdministrationMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'toogleShowMenuAdministration'>;

export type ShowingMenuSettingQueryVariables = {};

export type ShowingMenuSettingQuery = { __typename?: 'Query' } & Pick<
  Query,
  'showingMenuSetting'
>;

export type ToogleShowMenuSettingMutationVariables = {
  input?: Maybe<Scalars['Boolean']>;
};

export type ToogleShowMenuSettingMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'toogleShowMenuSetting'
>;

export type ShowingQuickViewQueryVariables = {};

export type ShowingQuickViewQuery = { __typename?: 'Query' } & Pick<
  Query,
  'showingQuickView'
>;

export type ToogleShowQuickViewMutationVariables = {
  input?: Maybe<Scalars['Boolean']>;
};

export type ToogleShowQuickViewMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'toogleShowQuickView'
>;

export type IsOpenDialogStopTimeRecordQueryVariables = {};

export type IsOpenDialogStopTimeRecordQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isOpenDialogStopTimeRecord'
>;

export type ToogleIsOpenDialogStopTimeRecordMutationVariables = {
  input?: Maybe<InputOpenDialogStopTimeRecord>;
};

export type ToogleIsOpenDialogStopTimeRecordMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'toogleIsOpenDialogStopTimeRecord'>;

export type UploadFileMutationVariables = {
  file: Scalars['Upload'];
  params: UploadInput;
};

export type UploadFileMutation = { __typename?: 'Mutation' } & {
  uploadFile: Maybe<{ __typename?: 'Media' } & Pick<Media, '_id' | 'name'>>;
};

export type GetListMediaQueryVariables = {
  params?: Maybe<GetListMediaInput>;
};

export type GetListMediaQuery = { __typename?: 'Query' } & {
  getListMedias: { __typename?: 'MediaRes' } & {
    nodes: Array<
      { __typename?: 'Media' } & Pick<
        Media,
        '_id' | 'name' | 'srcUrl' | 'createdAt' | 'mimeType' | 'thumbNailsUrls'
      > & {
          owner: Maybe<
            { __typename?: 'User' } & Pick<
              User,
              'firstName' | 'lastName' | 'middleName'
            > & { idOwner: User['_id'] }
          >;
        }
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type DeleteMediaMutationVariables = {
  params: IdInputType;
};

export type DeleteMediaMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteMedia'
>;

export type GetPatientNoteTypesQueryVariables = {
  params: GetPatientNoteTypesInput;
};

export type GetPatientNoteTypesQuery = { __typename?: 'Query' } & {
  getPatientNoteTypes: Maybe<
    { __typename?: 'PatientNoteTypeRes' } & {
      nodes: Array<
        {
          __typename?: 'PatientNoteTypeSetting';
        } & PatientNoteTypeSettingFragment
      >;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type CreatePatientNoteTypeMutationVariables = {
  params: CreatePatientNoteTypeInput;
};

export type CreatePatientNoteTypeMutation = { __typename?: 'Mutation' } & {
  createPatientNoteType: { __typename?: 'PatientNoteTypeSetting' } & {
    idNote: PatientNoteTypeSetting['_id'];
  };
};

export type UpdatePatientNoteTypeMutationVariables = {
  params: UpdatePatientNoteTypeInput;
};

export type UpdatePatientNoteTypeMutation = { __typename?: 'Mutation' } & {
  updatePatientNoteType: {
    __typename?: 'PatientNoteTypeSetting';
  } & PatientNoteTypeSettingFragment;
};

export type DeletePatientNoteTypeMutationVariables = {
  params: DeletePatientNoteTypeInput;
};

export type DeletePatientNoteTypeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePatientNoteType'
>;

export type AddMoreTreatmentNoteTypeMutationVariables = {
  params: AddMoreTreatmentNoteTypeInput;
};

export type AddMoreTreatmentNoteTypeMutation = { __typename?: 'Mutation' } & {
  addMoreTreatmentNoteType: { __typename?: 'PatientNoteTypeSetting' } & Pick<
    PatientNoteTypeSetting,
    '_id'
  > & {
      treatments: Maybe<
        Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
      >;
      folder: Maybe<
        Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
      >;
    };
};

export type RemoveOneTreatmentNoteTypeMutationVariables = {
  params: RemoveOneTreatmentNoteTypeInput;
};

export type RemoveOneTreatmentNoteTypeMutation = { __typename?: 'Mutation' } & {
  removeOneTreatmentNoteType: Maybe<
    { __typename?: 'PatientNoteTypeSetting' } & Pick<
      PatientNoteTypeSetting,
      '_id'
    > & {
        treatments: Maybe<
          Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
        >;
        folder: Maybe<
          Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>>
        >;
      }
  >;
};

export type GetNotificationsQueryVariables = {
  params: GetNotificationInput;
};

export type GetNotificationsQuery = { __typename?: 'Query' } & {
  getNotifications: { __typename?: 'NotificationRes' } & {
    nodes: Array<{ __typename?: 'Notification' } & NotificationFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetUnseenSurveyNotificationsQueryVariables = {
  params: GetUnSeenSurveyNotificationInput;
};

export type GetUnseenSurveyNotifications = { __typename?: 'Query' } & {
  getUnseenSurveyNotifications: { __typename?: 'NotificationRes' } & {
    nodes: Array<
      { __typename?: 'NavigatorNotification' } & NavigatorNotificationFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetNumberOfNotificationQueryVariables = {};

export type GetNumberOfNotificationQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getNumberOfNotification'
>;

export type GetUnseenNotificationsCountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getUnseenNotificationsCount'
>;

export type GetUnseenNotificationsCountQueryVariables = {};

export type SeenNotificationMutationVariables = {
  params: SeenNotificationInput;
};

export type SeenNotificationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'seenNotification'
>;

export type SurveyNotificationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'surveyNotification'
>;

export type SurveyNotificationMutationVariables = {
  params: MarkNotificationSeenInput;
};

export type GetOrganizationsQueryVariables = {
  params: GetOrganizationsInput;
};

export type GetOrganizationsQuery = { __typename?: 'Query' } & {
  getOrganizations: Maybe<
    { __typename?: 'OrganizationRes' } & {
      nodes: Array<{ __typename?: 'Organization' } & OrganizationFragment>;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type GetBasicOrganizationsQueryVariables = {
  params: GetOrganizationsInput;
};

export type GetBasicOrganizationsQuery = { __typename?: 'Query' } & {
  getOrganizations: Maybe<
    { __typename?: 'OrganizationRes' } & {
      nodes: Array<
        { __typename?: 'Organization' } & Pick<
          Organization,
          '_id' | 'name' | 'default'
        >
      >;
    }
  >;
};

export type GetOrganizationQueryVariables = {
  params: IdInputType;
};

export type GetOrganizationQuery = { __typename?: 'Query' } & {
  getOrganization: Maybe<
    { __typename?: 'Organization' } & OrganizationFragment
  >;
};

export type CreateOrganizationMutationVariables = {
  params: CreateOrganizationInput;
};

export type CreateOrganizationMutation = { __typename?: 'Mutation' } & {
  createOrganization: { __typename?: 'CreateOrgRes' } & Pick<
    CreateOrgRes,
    'name'
  > & {
      orgAdmin: { __typename?: 'User' } & Pick<User, 'email'>;
    };
};

export type UpdateOrganizationInfoMutationVariables = {
  params: UpdateOrganizationInput;
};

export type UpdateOrganizationInfoMutation = { __typename?: 'Mutation' } & {
  updateOrganizationInfo: {
    __typename?: 'Organization';
  } & OrganizationFragment;
};

export type DeleteOrganizationMutationVariables = {
  params: DeleteOrganizationInput;
};

export type DeleteOrganizationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteOrganization'
>;

export type HandleRepresentativeInfoMutationVariables = {
  params: RepresentativeInput;
};

export type HandleRepresentativeInfoMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'handleRepresentativeInfo'>;

export type GetOrganizationByUserQueryVariables = {};

export type GetOrganizationByUserQuery = { __typename?: 'Query' } & {
  getOrganizationByUser: { __typename?: 'Organization' } & Pick<
    Organization,
    '_id' | 'name' | 'sftpUser' | 'createdAt'
  >;
};

export type GetLinkDownloadKeyPairQueryVariables = {};

export type GetLinkDownloadKeyPairQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getLinkDownloadKeyPair'
>;

export type GetPatientDashboardsQueryVariables = {
  params: GetPatientDashboardInput;
};

export type GetPatientDashboardsQuery = { __typename?: 'Query' } & {
  getPatientDashboards: Array<
    { __typename?: 'PatientDashboardInfoRes' } & PatientDashboardInfoFragment
  >;
};

export type GetCommonTreatmentQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetCommonTreatmentQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type GetCommonTreatmentFavoritesQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetCommonTreatmentFavoritesQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type GetAssignSurveysQueryVariables = {
  params?: GetTreatmentsInput;
};

export type GetAssignSurveysQuery = { __typename?: 'Query' } & {
  getAssignSurveys: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type GetTreatmentAddSurveyQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetTreatmentAddSurveyQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & TreatmentsForAddSurveyFragment>;
  };
};

export type GetTreatmentAddPatientQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetTreatmentAddPatientQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type CreatePatientDashboardMutationVariables = {
  params: CreatePatientDashboardInput;
};

export type CreatePatientDashboardMutation = { __typename?: 'Mutation' } & {
  createPatientDashboard: { __typename?: 'PatientDashboardInfoRes' } & Pick<
    PatientDashboardInfoRes,
    '_id' | 'name'
  >;
};

export type UpdatePatientDashboardMutationVariables = {
  params: UpdatePatientDashboardInput;
};

export type UpdatePatientDashboardMutation = { __typename?: 'Mutation' } & {
  updatePatientDashboard: Maybe<
    { __typename?: 'PatientDashboardInfoRes' } & PatientDashboardInfoFragment
  >;
};

export type DeletePatientDashboardMutationVariables = {
  params: IdInputType;
};

export type DeletePatientDashboardMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePatientDashboard'
>;

export type GetPatientsQueryVariables = {
  params: GetPatientsInput;
};

export type GetPatientsQuery = { __typename?: 'Query' } & {
  getPatients: { __typename?: 'PatientPaginationRes' } & {
    nodes: Array<{ __typename?: 'PatientRes' } & PatientFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetPatientsAssignQueryVariables = {
  params: GetPatientsInput;
};

export type GetPatientsAssignQuery = { __typename?: 'Query' } & {
  getPatients: { __typename?: 'PatientPaginationRes' } & {
    nodes: Array<{ __typename?: 'PatientRes' } & PatientAssignFragment>;
  };
};

export type CreatePatientNoteMutationVariables = {
  params: CreatePatientNoteInput;
};

export type CreatePatientNoteMutation = { __typename?: 'Mutation' } & {
  createPatientNote: { __typename?: 'PatientNote' } & PatientNoteFragment;
};

export type AddMorePatientNoteMutationVariables = {
  params: AddMorePatientNoteInput;
};

export type AddMorePatientNoteMutation = { __typename?: 'Mutation' } & {
  addMorePatientNote: Maybe<
    { __typename?: 'PatientNote' } & PatientNoteFragment
  >;
};

export type UpdatePatientNoteMutationVariables = {
  params: UpdatePatientNoteInput;
};

export type UpdatePatientNoteMutation = { __typename?: 'Mutation' } & {
  updatePatientNote: Maybe<
    { __typename?: 'PatientNote' } & PatientNoteFragment
  >;
};

export type CreatePatientFollowUpMutationVariables = {
  params: CreatePatientFollowUpInput;
};

export type CreatePatientFollowUpMutation = { __typename?: 'Mutation' } & {
  createPatientFollowUp: {
    __typename?: 'PatientFollowUp';
  } & PatientFollowUpFragment;
};

export type UpdatePatientFollowUpMutationVariables = {
  params: UpdatePatientFollowUpInput;
};

export type UpdatePatientFollowUpMutation = { __typename?: 'Mutation' } & {
  updatePatientFollowUp: Maybe<
    { __typename?: 'PatientFollowUp' } & PatientFollowUpFragment
  >;
};

export type UpdatePatientInfoMutationVariables = {
  params: UpdatePatientInfoInput;
};

export type UpdatePatientInfoMutation = { __typename?: 'Mutation' } & {
  updatePatientInfo: { __typename?: 'Patient' } & PatientOwnerFragment;
};

export type DeletePatientFollowUpMutationVariables = {
  params: DeletePatientFollowInput;
};

export type DeletePatientFollowUpMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePatientFollowUp'
>;

export type GetSurveyDataByPatientQueryVariables = {
  params: GetSurveyDataByPatientInput;
};

export type GetSurveyDataByPatientQuery = { __typename?: 'Query' } & {
  getSurveyDataByPatient: { __typename?: 'SurveyResultPaginationRes' } & {
    nodes: Array<
      { __typename?: 'SurveyResultEmbedded' } & SurveyResultEmbeddedFragment
    >;
  };
};

export type GetDetailSurveyDataQueryVariables = {
  params: GetTreatmentInput;
};

export type GetDetailSurveyDataQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'surveyData'>
  >;
};

export type UpdateSurveyResultMutationVariables = {
  params: UpdateSurveyResultInput;
};

export type UpdateSurveyResultMutation = { __typename?: 'Mutation' } & {
  updateSurveyResult: Maybe<
    { __typename?: 'SurveyResult' } & Pick<
      SurveyResult,
      'updatedAt' | 'workingStatus'
    > & {
        updatedBy: Maybe<
          { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName'>
        >;
      }
  >;
};

export type UpdateStageChangedLogMutationVariables = {
  params: UpdateStageChangedLogInput;
};

export type UpdateStageChangedLogMutation = { __typename?: 'Mutation' } & {
  updateStageChangedLog: Maybe<
    { __typename?: 'StageChangedLog' } & StageChangeLogFFragment
  >;
};

export type UpdateStageChangedLogTimeMutationVariables = {
  params: UpdateStageChangedLogTimeInput;
};

export type UpdateStageChangedLogTimeMutation = { __typename?: 'Mutation' } & {
  updateStageChangedLogTime: Maybe<
    { __typename?: 'StageChangedLog' } & StageChangeLogFFragment
  >;
};

export type GetRootTreatmentFromPatientQueryVariables = {
  params: GetRootTreatmentInputFromPatient;
};

export type GetRootTreatmentFromPatientQuery = { __typename?: 'Query' } & {
  getRootTreatmentFromPatient: Array<
    { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'> & {
        treatmentStages: Maybe<
          Array<
            { __typename?: 'TreatmentStageEmbed' } & TreatmentStagesFragment
          >
        >;
      }
  >;
};

export type GetCurrentStageQueryVariables = {
  params: GetCurrentStageInput;
};

export type GetCurrentStageQuery = { __typename?: 'Query' } & {
  getCurrentStage: Array<
    { __typename?: 'CurrentStageRes' } & CurrentStageFragment
  >;
};

export type GetTreatmentsNotYetAssignedQueryVariables = {
  params: GetTreatmentsNotYetAssignedInput;
};

export type GetTreatmentsNotYetAssignedQuery = { __typename?: 'Query' } & {
  getTreatmentsNotYetAssigned: Array<
    { __typename?: 'Treatment' } & CommonTreatmentFragment
  >;
};

export type ShareMultiTreatmentToPatientMutationVariables = {
  params: SharedMultiTreatmentInput;
};

export type ShareMultiTreatmentToPatientMutation = {
  __typename?: 'Mutation';
} & {
  shareMultiTreatmentToPatient: Array<
    { __typename?: 'ShareTreatmentRes' } & ResponseShareTreatmentResFragment
  >;
};

export type CreateNewPatientMutationVariables = {
  params: CreateNewPatientInput;
};

export type CreateNewPatientMutation = { __typename?: 'Mutation' } & {
  createNewPatient: { __typename?: 'CreatePatientRes' } & Pick<
    CreatePatientRes,
    'result'
  > & {
      patient: Maybe<
        { __typename?: 'Patient' } & Pick<Patient, '_id' | 'email' | 'phones'>
      >;
      data: Maybe<Array<{ __typename?: 'Patient' } & SameNewPatientFragment>>;
      userExists: Maybe<Array<{ __typename?: 'User' } & DuplicateUserFragment>>;
    };
};

export type SignedPatientNoteMutationVariables = {
  params: UpdateStatusPatientNoteInput;
};

export type SignedPatientNoteMutation = { __typename?: 'Mutation' } & {
  signedPatientNote: Maybe<
    { __typename?: 'PatientNote' } & Pick<PatientNote, '_id'>
  >;
};

export type PublishedPatientNoteMutationVariables = {
  params: UpdateStatusPatientNoteInput;
};

export type PublishedPatientNoteMutation = { __typename?: 'Mutation' } & {
  publishedPatientNote: Maybe<
    { __typename?: 'PatientNote' } & Pick<PatientNote, '_id'>
  >;
};

export type DeletePatientNoteMutationVariables = {
  params: DeletePatientNoteInput;
};

export type DeletePatientNoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePatientNote'
>;

export type RemovePatientNoteMutationVariables = {
  params: DeletePatientNoteInput;
};

export type RemovePatientNoteMutation = { __typename?: 'Mutation' } & {
  removePatientNote: Maybe<
    { __typename?: 'PatientNote' } & PatientNoteFragment
  >;
};

export type ChangeSurveyResultOfNoteMutationVariables = {
  params: ChangeSurveyResultInput;
};

export type ChangeSurveyResultOfNoteMutation = { __typename?: 'Mutation' } & {
  changeSurveyResultOfNote: Maybe<
    { __typename?: 'PatientNote' } & PatientNoteFragment
  >;
};

export type SearchPatientsQueryVariables = {
  params: AssistantInputType;
};

export type SearchPatientsQuery = { __typename?: 'Query' } & {
  searchPatients_v2: { __typename?: 'SearchPatients' };
};

export type GetPatientTagsQueryVariables = {
  params: GetPatientTagsInput;
};

export type GetPatientTagsQuery = { __typename?: 'Query' } & {
  getPatientTags: { __typename?: 'TagsRes' } & {
    nodes: Array<{ __typename?: 'Tag' } & TagFragment>;
  };
};

export type CreatePatientTagMutationVariables = {
  params: CreateTagForPatientInput;
};

export type CreatePatientTagMutation = { __typename?: 'Mutation' } & {
  createPatientTag: { __typename?: 'AddTagRes' } & PatientTagFragment;
};

export type UnTagOnPatientMutationVariables = {
  params: UnTagOnPatientInput;
};

export type UnTagOnPatientMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'unTagOnPatient'
>;

export type GetPatientTagsByIdsQueryVariables = {
  params: GetTagsByPatientInput;
};

export type GetPatientTagsByIdsQuery = { __typename?: 'Query' } & {
  getPatientTagsByIds: Array<{ __typename?: 'Tag' } & TagFragment>;
};

export type ImportPatientMutationVariables = {
  file: Scalars['Upload'];
  params: ImportPatientInput;
};

export type ImportPatientMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'importPatient'
>;

export type GetPatientNotesQueryVariables = {
  params: GetPatientNotesInput;
};

export type GetPatientNotesQuery = { __typename?: 'Query' } & {
  getPatientNotes: Maybe<
    { __typename?: 'PatientNoteRes' } & {
      nodes: Array<{ __typename?: 'PatientNote' } & PatientNoteFragment>;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type GetPatientFollowUpsQueryVariables = {
  params: GetPatientFollowUpsInput;
};

export type GetPatientFollowUpsQuery = { __typename?: 'Query' } & {
  getPatientFollowUps: Maybe<
    { __typename?: 'PatientFollowUpRes' } & {
      nodes: Array<
        { __typename?: 'PatientFollowUp' } & PatientFollowUpFragment
      >;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type GetStageChangedLogsQueryVariables = {
  params: GetStageChangedLogsInput;
};

export type GetStageChangedLogsQuery = { __typename?: 'Query' } & {
  getStageChangedLogs: { __typename?: 'StageChangedLogsRes' } & {
    nodes: Array<{ __typename?: 'StageChangedLog' } & StageChangeLogFFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetSurveyResultGroupQueryVariables = {
  params: GetSurveyResultGroupInput;
};

export type GetSurveyResultGroupQuery = { __typename?: 'Query' } & {
  getSurveyResultGroup: { __typename?: 'SurveyResultGroupPaginationRes' } & {
    nodes: Array<
      { __typename?: 'SurveyResultGroupRes' } & PatientSurveyFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetDataForNoteQueryVariables = {
  paramsNote: GetSurveyResultGroupInput;
  paramsType: GetPatientNoteTypesInput;
};

export type GetDataForNoteQuery = { __typename?: 'Query' } & {
  getSurveyResultGroup: { __typename?: 'SurveyResultGroupPaginationRes' } & {
    nodes: Array<
      { __typename?: 'SurveyResultGroupRes' } & SurveyForNoteFragment
    >;
  };
  getPatientNoteTypes: Maybe<
    { __typename?: 'PatientNoteTypeRes' } & {
      nodes: Array<
        { __typename?: 'PatientNoteTypeSetting' } & Pick<
          PatientNoteTypeSetting,
          'name' | 'description'
        > & { idType: PatientNoteTypeSetting['_id'] }
      >;
    }
  >;
};

export type CreateSurveyResultByNavigatorMutationVariables = {
  params: NavigatorCreateSurveyResultInput;
};

export type CreateSurveyResultByNavigatorMutation = {
  __typename?: 'Mutation';
} & {
  createSurveyResultByNavigator: { __typename?: 'SurveyResult' } & {
    surveyResult: SurveyResult['_id'];
  };
};

export type UnReferenceTreatmentMutationVariables = {
  params: UnReferenceTreatmentInput;
};

export type UnReferenceTreatmentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'unReferenceTreatment'
>;

export type SendTreatmentReminderMutationVariables = {
  params: TreatmentReminderInput;
};

export type SendTreatmentReminderMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendTreatmentReminder'
>;

export type GetGroupDetailPatientQueryVariables = {
  paramsFollowUp: GetPatientFollowUpsInput;
  paramsNote: GetPatientNotesInput;
  paramsSurvey: GetSurveyDataByPatientInput;
  paramsChecklist: GetPatientChecklistInput;
  paramsCondition: GetPatientStageInput;
  paramsTimeRecord: GetPatientTimeRecordInput;
};

export type GetGroupDetailPatientQuery = { __typename?: 'Query' } & {
  getPatientFollowUps: Maybe<
    { __typename?: 'PatientFollowUpRes' } & {
      nodes: Array<
        { __typename?: 'PatientFollowUp' } & PatientFollowUpFragment
      >;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
  getPatientNotes: Maybe<
    { __typename?: 'PatientNoteRes' } & {
      nodes: Array<{ __typename?: 'PatientNote' } & PatientNoteFragment>;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
  getSurveyDataByPatient: { __typename?: 'SurveyResultPaginationRes' } & {
    nodes: Array<
      { __typename?: 'SurveyResultEmbedded' } & SurveyResultEmbeddedFragment
    >;
  };
  getPatientChecklists: { __typename?: 'PatientChecklistRes' } & {
    nodes: Array<
      { __typename?: 'PatientChecklistMono' } & PatientChecklistFragment
    >;
  };
  getListPatientStages: { __typename?: 'PatientCondStageRes' } & {
    nodes: Array<
      { __typename?: 'PatientCondStage' } & PatientCondStageFragment
    >;
  };
  getPatientTimeRecord: { __typename?: 'PatientTimeRecordPaginationRes' } & {
    nodes: Array<
      { __typename?: 'PatientTimeRecordRes' } & PatientTimeRecordFragment
    >;
  };
};

export type GetNoteTagQueryVariables = {
  params: GetPatientNotesInput;
};

export type GetNoteTagQuery = { __typename?: 'Query' } & {
  getPatientNotes: Maybe<
    { __typename?: 'PatientNoteRes' } & {
      nodes: Array<{ __typename?: 'PatientNote' } & PatientNoteFragment>;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type GetDataForFilterPatientQueryVariables = {
  paramsTreatment?: GetTreatmentsInput;
  paramsSurvey?: GetTreatmentsInput;
  paramsChecklist?: GetTreatmentsInput;
  paramsFollowUpType?: GetFollowUpTypesInput;
  //paramsfollowUpTypeDescp?: GetFollowUpTypesInput;
  paramsNavigator?: GetUsersInput;
  paramsModule?: GetCptCodeInput;
};

export type GetDataForFilterPatientQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'> & {
          treatmentStages: Maybe<
            Array<
              { __typename?: 'TreatmentStageEmbed' } & Pick<
                TreatmentStageEmbed,
                'name'
              > & {
                  treatmentStage: Maybe<
                    { __typename?: 'TreatmentStage' } & {
                      stageId: TreatmentStage['_id'];
                    }
                  >;
                }
            >
          >;
        }
    >;
  };
  getSurveys: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & SurveyPatientDashboardFragment>;
  };
  getChecklists: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & SurveyPatientDashboardFragment>;
  };
  getFollowUpTypes: Maybe<
    { __typename?: 'FollowUpTypeRes' } & {
      nodes: Array<
        { __typename?: 'FollowUpTypeSetting' } & FollowUpTypeShortFragment
      >;
    }
  >;
  getFollowUpTypesDescp: Maybe<
    { __typename?: 'FollowUpTypeRes' } & {
      nodes: Array<
        { __typename?: 'FollowUpTypeSetting' } & FollowUpTypeDescpShortFragment
      >;
    }
  >;
  getNavigators: { __typename?: 'UsersRes' } & {
    nodes: Array<
      { __typename?: 'User' } & Pick<
        User,
        '_id' | 'firstName' | 'lastName' | 'middleName'
      >
    >;
  };
  getCPTCode: { __typename?: 'CPTCodeRes' } & {
    nodes: Array<
      { __typename?: 'CPTCode' } & Pick<CptCode, '_id' | 'code' | 'name'>
    >;
  };
};

export type GetCommonChecklistQueryVariables = {
  params?: GetTreatmentsInput;
};

export type GetCommonChecklistQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type GetGroupSettingTaskTypeQueryVariables = {
  paramsGroupSetting: GetTreatmentRegimenInput;
  paramsTaskType: GetFollowUpTypesInput;
};

export type GetGroupSettingTaskTypeQuery = { __typename?: 'Query' } & {
  getTreatmentRegimen: { __typename?: 'TreatmentRegimenRes' } & {
    nodes: Array<
      { __typename?: 'TreatmentRegimen' } & Pick<TreatmentRegimen, '_id'> & {
          name: TreatmentRegimen['regimenName'];
        }
    >;
  };
  getFollowUpTypes: Maybe<
    { __typename?: 'FollowUpTypeRes' } & {
      nodes: Array<
        { __typename?: 'FollowUpTypeSetting' } & Pick<
          FollowUpTypeSetting,
          '_id' | 'name'
        > & {
            folder: Maybe<
              Array<{ __typename?: 'Treatment' } & Pick<Treatment, '_id'>>
            >;
          }
      >;
    }
  >;
};

export type GetCommonDataQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetCommonDataQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type GetDataTaskTypeQueryVariables = {
  paramsTreatment: GetTreatmentsInput;
  paramsFolder: GetTreatmentsInput;
};

export type GetDataTaskTypeQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
  getFolder: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & FolderForTaskTypeFragment>;
  };
};

export type GetFolderTaskTypeQueryVariables = {
  params?: GetTreatmentsInput;
};

export type GetFolderTaskTypeQuery = { __typename?: 'Query' } & {
  getFolder: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & FolderForTaskTypeFragment>;
  };
};

export type GetSurveyResultTaskQueryVariables = {
  paramsSurveyVersion: GetSurveyVersionInput;
  paramsTask: GetPatientFollowUpsInput;
  paramsChecklist: GetPatientChecklistInput;
  paramsPatientStage: GetPatientStageInput;
  paramsTimeRecord: GetPatientTimeRecordInput;
};

export type GetSurveyResultTaskQuery = { __typename?: 'Query' } & {
  getSurveyVersion: Maybe<
    { __typename?: 'SurveyVersionRes' } & {
      nodes: Array<
        { __typename?: 'SurveyVersion' } & Pick<
          SurveyVersion,
          'name' | 'surveyData'
        >
      >;
    }
  >;
  getPatientFollowUps: Maybe<
    { __typename?: 'PatientFollowUpRes' } & {
      nodes: Array<
        { __typename?: 'PatientFollowUp' } & Pick<
          PatientFollowUp,
          '_id' | 'note' | 'followUpDate'
        >
      >;
    }
  >;
  getPatientChecklists: { __typename?: 'PatientChecklistRes' } & {
    nodes: Array<
      { __typename?: 'PatientChecklistMono' } & PatientChecklistPdfFragment
    >;
  };
  getListPatientStages: { __typename?: 'PatientCondStageRes' } & {
    nodes: Array<
      { __typename?: 'PatientCondStage' } & PatientCondStageFragment
    >;
  };
  getPatientTimeRecord: { __typename?: 'PatientTimeRecordPaginationRes' } & {
    nodes: Array<
      { __typename?: 'PatientTimeRecordRes' } & PatientTimeRecordFragment
    >;
  };
};

export type ArchiveSurveyResultMutationVariables = {
  params: HideSurveyResultInput;
};

export type ArchiveSurveyResultMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'archiveSurveyResult'
>;

export type ImportPatientDashboardMutationVariables = {
  params: IdInputType;
};

export type ImportPatientDashboardMutation = { __typename?: 'Mutation' } & {
  importPatientDashboard: Maybe<
    { __typename?: 'PatientDashboard' } & Pick<PatientDashboard, '_id'>
  >;
};

export type GetPd4QuickViewQueryVariables = {};

export type GetPd4QuickViewQuery = { __typename?: 'Query' } & {
  getPD4QuickView: Array<
    {
      __typename?: 'PatientDashboardInfoRes';
    } & PatientDashboardInfoQuickViewFragment
  >;
};

export type GetLinkDownloadFileSampleQueryVariables = {};

export type GetLinkDownloadFileSampleQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getLinkDownloadFileSample'
>;

export type DeletePatientMutationVariables = {
  params: DeletePatientInput;
};

export type DeletePatientMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePatient'
>;

export type DeleteSurveyResultMutationVariables = {
  params: DeleteSurveyResultInput;
};

export type DeleteSurveyResultMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteSurveyResult'
>;

export type GetDataFilterByNavigatorQueryVariables = {
  paramsTreatment: GetTreatmentsInput;
  paramsSurvey: GetTreatmentsInput;
};

export type GetDataFilterByNavigatorQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>
    >;
  };
  getSurvey: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'>
    >;
  };
};

export type GetProviderQueryVariables = {
  params: GetProviderInput;
};

export type GetProviderQuery = { __typename?: 'Query' } & {
  getProvider: Maybe<
    { __typename?: 'ProviderRes' } & {
      nodes: Array<
        { __typename?: 'ProviderSetting' } & ProviderSettingFragment
      >;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type CreateProviderMutationVariables = {
  params: CreateProviderInput;
};

export type CreateProviderMutation = { __typename?: 'Mutation' } & {
  createProvider: { __typename?: 'ProviderSetting' } & Pick<
    ProviderSetting,
    '_id'
  >;
};

export type UpdateProviderMutationVariables = {
  params: UpdateProviderInput;
};

export type UpdateProviderMutation = { __typename?: 'Mutation' } & {
  updateProvider: { __typename?: 'ProviderSetting' } & ProviderSettingFragment;
};

export type DeleteProviderMutationVariables = {
  params: DeleteProviderInput;
};

export type DeleteProviderMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteProvider'
>;

export type GetReleaseNoteQueryVariables = {
  params: GetReleaseNoteInput;
};

export type GetReleaseNoteQuery = { __typename?: 'Query' } & {
  getReleaseNote: Maybe<
    { __typename?: 'ReleaseNoteRes' } & {
      nodes: Array<
        { __typename?: 'ReleaseNoteSetting' } & ReleaseNoteSettingFragment
      >;
      meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
    }
  >;
};

export type GetDataTreatmentQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetDataTreatmentQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type CreateTreatmentRegimenMutationVariables = {
  params: CreateTreatmentRegimenInput;
};

export type CreateTreatmentRegimenMutation = { __typename?: 'Mutation' } & {
  createTreatmentRegimen: {
    __typename?: 'TreatmentRegimen';
  } & TreatmentRegimenFragment;
};

export type UpdateTreatmentRegimenMutationVariables = {
  params: UpdateTreatmentRegimenInput;
};

export type UpdateTreatmentRegimenMutation = { __typename?: 'Mutation' } & {
  updateTreatmentRegimen: {
    __typename?: 'TreatmentRegimen';
  } & TreatmentRegimenFragment;
};

export type DeleteTreatmentRegimenMutationVariables = {
  params: DeleteTreatmentRegimenInput;
};

export type DeleteTreatmentRegimenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteTreatmentRegimen'
>;

export type CreateCalendarConfigByRegimenMutationVariables = {
  params: CreateCalendarConfigByRegimenInput;
};

export type CreateCalendarConfigByRegimenMutation = {
  __typename?: 'Mutation';
} & {
  createCalendarConfigByRegimen: Array<
    { __typename?: 'ShareTreatmentRes' } & ResponseShareTreatmentResFragment
  >;
};

export type GetTreatmentRegimenAddPatientQueryVariables = {
  params: GetTreatmentRegimenInput;
};

export type GetTreatmentRegimenAddPatientQuery = { __typename?: 'Query' } & {
  getTreatmentRegimen: { __typename?: 'TreatmentRegimenRes' } & {
    nodes: Array<
      { __typename?: 'TreatmentRegimen' } & TreatmentRegimenFragment
    >;
  };
};

export type GetTreatmentRegimenQueryVariables = {
  params: GetTreatmentRegimenInput;
};

export type GetTreatmentRegimenQuery = { __typename?: 'Query' } & {
  getTreatmentRegimen: { __typename?: 'TreatmentRegimenRes' } & {
    nodes: Array<
      { __typename?: 'TreatmentRegimen' } & TreatmentRegimenFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetShortLinkQueryVariables = {
  params: GetShortLinkInput;
};

export type GetShortLinkQuery = { __typename?: 'Query' } & {
  getShortLink: { __typename?: 'ShortLink' } & Pick<
    ShortLink,
    '_id' | 'link' | 'key' | 'expiredDate'
  >;
};

export type GetSurveyWithTokenQueryVariables = {
  params: GetSurveyInput;
};

export type GetSurveyWithTokenQuery = { __typename?: 'Query' } & {
  getSurveyWithToken: { __typename?: 'SurveyWithTokenRes' } & Pick<
    SurveyWithTokenRes,
    'firstName'
  > & {
      treatment: { __typename?: 'SurveyVersion' } & SurveyVersionFragment;
    };
};

export type OnTreatmentCommentSubscriptionVariables = {
  topic: Scalars['String'];
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type OnTreatmentCommentSubscription = { __typename?: 'Subscription' } & {
  treatmentCommentSubscription: { __typename?: 'CommentExt' } & CommentFragment;
};

export type onNewSurveyResultNotificationSubscription = {
  __typename?: 'Subscription';
} & {
  newSurveyResultNotification: {
    __typename?: 'NavigatorNotification';
  } & NavigatorNotificationFragment;
};

export type OnUnreadCommentsSubscriptionVariables = {
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type OnUnreadCommentsSubscription = { __typename?: 'Subscription' } & {
  unreadCommentsSubscription: { __typename?: 'CommentGroupsCursorRes' } & {
    edges: Array<
      { __typename?: 'CommentGroupEdge' } & {
        node: { __typename?: 'CommentGroupRes' } & GroupCommentsFragment;
      }
    >;
  };
};

export type OnUnreadAllCommentsSubscriptionVariables = {};

export type OnUnreadAllCommentsSubscription = {
  __typename?: 'Subscription';
} & {
  unreadAllCommentsSubscription: Array<
    { __typename?: 'CommentGroupRes' } & Pick<
      CommentGroupRes,
      '_id' | 'unreadCount'
    >
  >;
};

export type OnUnreadCommentsPatientListSubscriptionVariables = {
  patientId?: Maybe<Scalars['ObjectId']>;
};

export type OnUnreadCommentsPatientListSubscription = {
  __typename?: 'Subscription';
} & {
  unreadCommentsPatientListSubscription: {
    __typename?: 'CommentGroupsCursorRes';
  } & {
    edges: Array<
      { __typename?: 'CommentGroupEdge' } & {
        node: {
          __typename?: 'CommentGroupRes';
        } & GroupCommentByPatientFragment;
      }
    >;
  };
};

export type OnUnreadAllCommentsByPatientsSubscriptionVariables = {};

export type OnUnreadAllCommentsByPatientsSubscription = {
  __typename?: 'Subscription';
} & {
  unreadAllCommentsByPatientsSubscription: {
    __typename?: 'CommentGroupsCursorRes';
  } & {
    edges: Array<
      { __typename?: 'CommentGroupEdge' } & {
        node: { __typename?: 'CommentGroupRes' } & Pick<
          CommentGroupRes,
          '_id' | 'unreadCount' | 'lastCommentAt'
        >;
      }
    >;
  };
};

export type LoginOtherDeviceSubscriptionVariables = {};

export type LoginOtherDeviceSubscription = {
  __typename?: 'Subscription';
} & Pick<Subscription, 'loginOtherDevice'>;

export type CreateSurveyMutationVariables = {
  params: CreateSurveyInput;
};

export type CreateSurveyMutation = { __typename?: 'Mutation' } & {
  createSurvey: { __typename?: 'Treatment' } & ResponseModifyTreatmentFragment;
};

export type UpdateSurveyInfoMutationVariables = {
  params: UpdateSurveyInfoInput;
};

export type UpdateSurveyInfoMutation = { __typename?: 'Mutation' } & {
  updateSurveyInfo: {
    __typename?: 'Treatment';
  } & ResponseModifyTreatmentFragment;
};

export type CreateSurveyResultMutationVariables = {
  params: CreateSurveyResultInput;
};

export type CreateSurveyResultMutation = { __typename?: 'Mutation' } & {
  createSurveyResult: { __typename?: 'SurveyResult' } & Pick<
    SurveyResult,
    '_id'
  >;
};

export type CreateSurveyWithTokenMutationVariables = {
  params: CreateSurveyResultWithTokenInput;
};

export type CreateSurveyWithTokenMutation = { __typename?: 'Mutation' } & {
  createSurveyWithToken: Maybe<
    { __typename?: 'SurveyResult' } & Pick<SurveyResult, '_id'>
  >;
};

export type GetListSurveyQueryVariables = {
  params?: GetTreatmentsInput;
};

export type GetListSurveyQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name' | 'version'>
    >;
  };
};

export type GetSurveyVersionQueryVariables = {
  params: GetSurveyVersionInput;
};

export type GetSurveyVersionQuery = { __typename?: 'Query' } & {
  getSurveyVersion: Maybe<
    { __typename?: 'SurveyVersionRes' } & {
      nodes: Array<{ __typename?: 'SurveyVersion' } & SurveyVersionFragment>;
    }
  >;
};

export type GetListSurveyDataQueryVariables = {
  params?: GetTreatmentsInput;
};

export type GetListSurveyDataQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        '_id' | 'name' | 'surveyData'
      >
    >;
  };
};

export type GetDetailSurveyQueryVariables = {
  paramsSurvey: GetSurveyVersionInput;
  paramsResultsSurvey: GetSurveyResultsInput;
};

export type GetDetailSurveyQuery = { __typename?: 'Query' } & {
  getSurveyVersion: Maybe<
    { __typename?: 'SurveyVersionRes' } & {
      nodes: Array<
        { __typename?: 'SurveyVersion' } & Pick<
          SurveyVersion,
          '_id' | 'name' | 'surveyData' | 'version' | 'originSurvey'
        >
      >;
    }
  >;
  getSurveyResults: Array<
    { __typename?: 'SurveyResult' } & Pick<
      SurveyResult,
      '_id' | 'data' | 'createdAt' | 'updatedAt'
    > & {
        owner: Maybe<
          { __typename?: 'Patient' } & Pick<
            Patient,
            'firstName' | 'lastName' | 'middleName'
          >
        >;
      }
  >;
};

export type GetAnonymousSurveyQueryVariables = {
  paramsResultsSurvey: GetSurveyResultsInput;
};

export type GetAnonymousSurveyQuery = { __typename?: 'Query' } & {
  getAnonymousSurveyResults: Array<
    { __typename?: 'SurveyResult' } & { surveyName: Scalars['String'] } & Pick<
        SurveyResult,
        '_id' | 'data' | 'createdAt' | 'updatedAt'
      > & {
        owner: Maybe<
          { __typename?: 'Patient' } & Pick<
            Patient,
            'firstName' | 'lastName' | 'middleName'
          >
        >;
      } & {
        survey: Maybe<Pick<Treatment, '_id'>>;
      }
  >;
};

export type GetSurveyById = { __typename?: 'Query' } & {
  getSurveyById: { __typename?: 'Treatment' } & Pick<
    Treatment,
    '_id' | 'surveyData'
  >;
};

export type GetSurveyByIdQueryVariables = {
  params: GetTreatmentInput;
};

export type GetDetailSurveyFromConfigQueryVariables = {
  paramsSurvey: GetSurveyResultsFromChartConfigInput;
  paramsResultsSurvey: GetSurveyResultsFromChartConfigInput;
};

export type GetDetailSurveyFromConfigQuery = { __typename?: 'Query' } & {
  getSurveyFromChartConfig: { __typename?: 'SurveyVersion' } & Pick<
    SurveyVersion,
    '_id' | 'surveyData' | 'name' | 'originSurvey'
  >;
  getSurveyResultFromChartConfig: Array<
    { __typename?: 'SurveyResult' } & Pick<
      SurveyResult,
      '_id' | 'data' | 'createdAt'
    > & {
        owner: Maybe<
          { __typename?: 'Patient' } & Pick<
            Patient,
            'firstName' | 'lastName' | 'middleName'
          >
        >;
      }
  >;
};

export type GetQuestionsQueryVariables = {
  params: GetSurveyVersionInput;
};

export type GetQuestionsQuery = { __typename?: 'Query' } & {
  getSurveyVersion: Maybe<
    { __typename?: 'SurveyVersionRes' } & {
      nodes: Array<
        { __typename?: 'SurveyVersion' } & Pick<
          SurveyVersion,
          '_id' | 'surveyData'
        >
      >;
    }
  >;
};

export type ResendSurveyMutationVariables = {
  params: SurveyResenderInput;
};

export type ResendSurveyMutation = { __typename?: 'Mutation' } & {
  resendSurvey: Array<
    { __typename?: 'ShareTreatmentRes' } & ResponseShareTreatmentResFragment
  >;
};

export type ExportEmrInfoMutationVariables = {
  params: GetEmrInfoInput;
  file: Scalars['Upload'];
};

export type ExportEmrInfoMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'exportEmrInfo'
>;

export type GetTagInfoQueryVariables = {
  params: GetTagInput;
};

export type GetTagInfoQuery = { __typename?: 'Query' } & {
  getTagInfo: { __typename?: 'TagsRes' } & {
    nodes: Array<{ __typename?: 'Tag' } & TagInfoFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type CreateTagMutationVariables = {
  params: CreateTagInput;
};

export type CreateTagMutation = { __typename?: 'Mutation' } & {
  createTag: { __typename?: 'Tag' } & TagInfoFragment;
};

export type DeleteTagMutationVariables = {
  params: DeleteTagInput;
};

export type DeleteTagMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteTag'
>;

export type UpdateTagInfoMutationVariables = {
  params: UpdateTagInput;
};

export type UpdateTagInfoMutation = { __typename?: 'Mutation' } & {
  updateTagInfo: { __typename?: 'Tag' } & TagInfoFragment;
};

export type ReplaceTagMutationVariables = {
  params: ReplaceTagInput;
};

export type ReplaceTagMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'replaceTag'
>;

export type GetPublicTreatmentsQueryVariables = {
  params: GetPublicTreatment;
};

export type GetPublicTreatmentsQuery = { __typename?: 'Query' } & {
  getPublicTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & TreatmentFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetTreatmentsHomePageQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetTreatmentsHomePageQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & TreatmentFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetAssignTreatmentsQueryVariables = {
  params: GetAssignTreatmentInput;
};

export type GetAssignTreatmentsQuery = { __typename?: 'Query' } & {
  getAssignTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & TreatmentFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetDataDetailTreatmentQueryVariables = {
  paramsDetail: GetTreatmentInput;
};

export type GetDataDetailTreatmentQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<{ __typename?: 'Treatment' } & TreatmentDetailFragment>;
  checkHightLight: Array<
    { __typename?: 'TreatmentStage' } & CheckHightLightFragment
  >;
};

export type GetPathToTreatmentQueryVariables = {
  params: IdInputType;
};

export type GetPathToTreatmentQuery = { __typename?: 'Query' } & {
  getPathToTreatment: Array<
    { __typename?: 'TreatmentsPathRes' } & PathToTreatmentFragment & {
        subSections: Array<
          { __typename?: 'Treatment' } & SubTreatmentDetailFragment
        >;
      }
  >;
};

export type GetTreatmentPDFQueryVariables = {
  params: TreatmentPDF;
};

export type GetTreatmentPDFQuery = { __typename?: 'Query' } & {
  getTreatmentPDF: Scalars['String'];
};

export type GetSubTreatmentQueryVariables = {
  params: GetTreatmentInput;
};

export type GetSubTreatmentQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<Treatment, '_id' | 'name'> & {
        subSections: Maybe<
          Array<{ __typename?: 'Treatment' } & SubTreatmentFragment>
        >;
      }
  >;
};

export type GetTreatmentParentQueryVariables = {
  params: GetTreatmentsForUpdateInput;
};

export type GetTreatmentParentQuery = { __typename?: 'Query' } & {
  getTreatmentsForUpdate: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        '_id' | 'name' | 'type'
      > & {
          owner: Maybe<{ __typename?: 'User' } & { idOwner: User['_id'] }>;
        }
    >;
  };
};

export type CreateTreatmentMutationVariables = {
  params: CreateTreatmentInput;
};

export type CreateTreatmentMutation = { __typename?: 'Mutation' } & {
  createTreatment: { __typename?: 'Treatment' } & Pick<
    Treatment,
    'qrCode' | 'urlSharing' | 'isDefault'
  > &
    ResponseModifyTreatmentFragment;
};

export type UpdateTreatmentInfoMutationVariables = {
  params: UpdateTreatmentInfoInput;
};

export type UpdateTreatmentInfoMutation = { __typename?: 'Mutation' } & {
  updateTreatmentInfo: { __typename?: 'Treatment' } & Pick<
    Treatment,
    'qrCode' | 'urlSharing' | 'isDefault'
  > &
    ResponseModifyTreatmentFragment;
};

export type DeleteTreatmentMutationVariables = {
  params: IdInputType;
};

export type DeleteTreatmentMutation = { __typename?: 'Mutation' } & {
  deleteTreatment: Maybe<
    { __typename?: 'DeleteTreatmentRes' } & Pick<
      DeleteTreatmentRes,
      'status'
    > & {
        data: Maybe<
          Array<
            {
              __typename?: 'TreatmentsAssignedRes';
            } & TreatmentFromDeleteFragment
          >
        >;
      }
  >;
};

export type GetSubTreatmentForDeleteQueryVariables = {
  params: GetTreatmentInput;
};

export type GetSubTreatmentForDeleteQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<Treatment, '_id'> & {
        subSections: Maybe<
          Array<
            { __typename?: 'Treatment' } & Pick<
              Treatment,
              '_id' | 'name' | 'type'
            >
          >
        >;
        survey: Maybe<
          Array<
            { __typename?: 'Treatment' } & Pick<Treatment, 'name' | 'type'> & {
                idSurvey: Treatment['_id'];
              }
          >
        >;
      }
  >;
};

export type BulkUpdateTreatmentMutationVariables = {
  params: Array<UpdateTreatmentTreeInput>;
};

export type BulkUpdateTreatmentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'bulkUpdateTreatmentTree'
>;

export type TreatmentAssignSyncMutationVariables = {
  params: Array<AssignTreatmentInput>;
};

export type TreatmentAssignSyncMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'syncAssignTreatment'
>;

export type AssignTreatmentMutationVariables = {
  params: AssignInput;
};

export type AssignTreatmentMutation = { __typename?: 'Mutation' } & {
  assignTreatment: Maybe<
    { __typename?: 'AssignTreatmentRes' } & AssignTreatmentResFragment
  >;
};

export type QrcodeCreateMutationVariables = {
  params: IdInputType;
};

export type QrcodeCreateMutation = { __typename?: 'Mutation' } & {
  createQRCodeTreatment: Maybe<
    { __typename?: 'Treatment' } & TreatmentTreeFragment
  >;
};

export type InviteContributorMutationVariables = {
  params: InviteContributorInput;
};

export type InviteContributorMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'inviteContributor'
>;

export type GetContributorsQueryVariables = {
  params: GetTreatmentInput;
};

export type GetContributorsQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<Treatment, '_id'> & {
        contributors: Maybe<
          Array<
            { __typename?: 'User' } & Pick<
              User,
              'firstName' | 'lastName' | 'middleName' | 'email'
            > & { idUser: User['_id'] }
          >
        >;
      }
  >;
};

export type GetAnalyzeContentQueryVariables = {
  params: GetAnalyzerInput;
};

export type GetAnalyzeContentQuery = { __typename?: 'Query' } & {
  analyzeContent: { __typename?: 'AnalyzeContent' } & AnalyzerOutput;
};

export type GetAnalyzeWithoutTreatmentQueryVariables = {
  params: GetAnalyzerInputFromTreatmentDesigner;
};

export type GetAnalyzeWithoutTreatmentQuery = { __typename?: 'Query' } & {
  analyzewithoutTreatment: {
    __typename: 'AnalyzeWithoutTreatment';
  } & AnalyzerOutput;
};

export type GetNavigatorsToInviteQueryVariables = {
  params: GetNavigatorsInput;
};

export type GetNavigatorsToInviteQuery = { __typename?: 'Query' } & {
  getNavigatorsToInvite: { __typename?: 'UsersRes' } & {
    nodes: Array<
      { __typename?: 'User' } & Pick<
        User,
        | '_id'
        | 'email'
        | 'firstName'
        | 'middleName'
        | 'lastName'
        | 'status'
        | 'role'
      >
    >;
  };
};

export type SetOwnershipToContributorMutationVariables = {
  params: TakeOwnershipInput;
};

export type SetOwnershipToContributorMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'takeOwnership'>;

export type RemoveContributorMutationVariables = {
  params: TakeOwnershipInput;
};

export type RemoveContributorMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'removeContributor'
>;

export type CheckHightLightQueryVariables = {};

export type CheckHightLightQuery = { __typename?: 'Query' } & {
  checkHightLight: Array<
    { __typename?: 'TreatmentStage' } & CheckHightLightFragment
  >;
};

export type GetAssignTreatmentsSideBarQueryVariables = {
  params?: GetAssignTreatmentInput;
};

export type GetAssignTreatmentsSideBarQuery = { __typename?: 'Query' } & {
  getAssignTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<
        Treatment,
        | '_id'
        | 'name'
        | 'label'
        | 'subSectionIds'
        | 'order'
        | 'createdAt'
        | 'slug'
      > & {
          subSections: Maybe<
            Array<
              { __typename?: 'Treatment' } & Pick<
                Treatment,
                | '_id'
                | 'name'
                | 'label'
                | 'subSectionIds'
                | 'order'
                | 'createdAt'
                | 'slug'
                | 'parentId'
              >
            >
          >;
        }
    >;
  };
};

export type GetSubTreatmentSideBarQueryVariables = {
  params: GetTreatmentInput;
};

export type GetSubTreatmentSideBarQuery = { __typename?: 'Query' } & {
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<Treatment, '_id'> & {
        subSections: Maybe<
          Array<
            { __typename?: 'Treatment' } & Pick<
              Treatment,
              | '_id'
              | 'name'
              | 'label'
              | 'subSectionIds'
              | 'order'
              | 'parentId'
              | 'createdAt'
            >
          >
        >;
      }
  >;
};

export type GetGroupTreatmentsQueryVariables = {
  params: GetGroupTreatmentInput;
};

export type GetGroupTreatmentsQuery = { __typename?: 'Query' } & {
  getGroupTreatments: { __typename?: 'GroupTreatmentSettingRes' } & {
    nodes: Array<
      { __typename?: 'GroupTreatmentSetting' } & GroupTreatmentSettingFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type CreateGroupTreatmentMutationVariables = {
  params: CreateGroupTreatmentInput;
};

export type CreateGroupTreatmentMutation = { __typename?: 'Mutation' } & {
  createGroupTreatment: { __typename?: 'GroupTreatmentSetting' } & {
    idFollowUp: GroupTreatmentSetting['_id'];
  };
};

export type UpdateGroupTreatmentMutationVariables = {
  params: UpdateGroupTreatmentInput;
};

export type UpdateGroupTreatmentMutation = { __typename?: 'Mutation' } & {
  updateGroupTreatment: {
    __typename?: 'GroupTreatmentSetting';
  } & GroupTreatmentSettingFragment;
};

export type DeleteGroupTreatmentMutationVariables = {
  params: DeleteGroupTreatmentInput;
};

export type DeleteGroupTreatmentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteGroupTreatment'
>;

export type GetGroupTreatmentAssignQueryVariables = {
  params: PaginateInputType;
};

export type GetGroupTreatmentAssignQuery = { __typename?: 'Query' } & {
  getGroupTreatments4Assign: { __typename?: 'GroupTreatmentSettingRes' } & {
    nodes: Array<
      {
        __typename?: 'GroupTreatmentSetting';
      } & GroupTreatmentSettingAssignFragment
    >;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetTreatmentsTreeQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetTreatmentsTreeQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & TreatmentTreeFragment>;
  };
};

export type GetTreatmentsForAddTreeQueryVariables = {
  params: GetTreatmentsInput;
};

export type GetTreatmentsForAddTreeQuery = { __typename?: 'Query' } & {
  getTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, 'isPrivate'> &
        TreatmentForAddUpdateFragment
    >;
  };
};

export type UpdateTreatmentTreeMutationVariables = {
  params: UpdateTreatmentTreeInput;
};

export type UpdateTreatmentTreeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateTreatmentTree'
>;

export type CopyTreatment4SuperAdminMutationVariables = {
  params: CopyTreatmentInput;
};

export type CopyTreatment4SuperAdminMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'copyTreatment4SuperAdmin'>;

export type BulkUpdateTreatmentOrderMutationVariables = {
  params: Array<UpdateTreatmentOrderInput>;
};

export type BulkUpdateTreatmentOrderMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'bulkUpdateTreatmentOrder'>;

export type GetDataForUpdateTreeQueryVariables = {
  paramsTreatment: GetTreatmentsForUpdateInput;
  paramsDetail: GetTreatmentInput;
  paramsGroup: GetTreatmentRegimenInput;
  paramsTaskType: GetFollowUpTypesInput;
  getDataForTreatment: Scalars['Boolean'];
};

export type GetDataForUpdateTreeQuery = { __typename?: 'Query' } & {
  getTreatmentRegimen: { __typename?: 'TreatmentRegimenRes' } & {
    nodes: Array<
      { __typename?: 'TreatmentRegimen' } & Pick<TreatmentRegimen, '_id'> & {
          name: TreatmentRegimen['regimenName'];
        }
    >;
  };
  getFollowUpTypes: Maybe<
    { __typename?: 'FollowUpTypeRes' } & {
      nodes: Array<
        { __typename?: 'FollowUpTypeSetting' } & Pick<
          FollowUpTypeSetting,
          '_id' | 'name'
        >
      >;
    }
  >;
  getTreatmentsForUpdate: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<
      { __typename?: 'Treatment' } & Pick<Treatment, 'isPrivate'> &
        TreatmentForAddUpdateFragment
    >;
  };
  getTreatment: Maybe<
    { __typename?: 'Treatment' } & Pick<
      Treatment,
      | 'content'
      | 'isPrivate'
      | 'parentId'
      | 'surveyData'
      | 'label'
      | 'isReference'
      | 'isHideOnHomePage'
      | 'hideMainMedia'
      | 'slug'
      | 'isDefault'
      | 'emrObx'
      | 'smsMessage'
    > & {
        checklist: Maybe<
          Array<
            { __typename?: 'Treatment' } & Pick<
              Treatment,
              'name' | 'ownerId'
            > & { idChecklist: Treatment['_id'] }
          >
        >;
        parent: Maybe<
          { __typename?: 'Treatment' } & TreatmentForAddUpdateFragment
        >;
        treatmentStages: Maybe<
          Array<
            { __typename?: 'TreatmentStageEmbed' } & TreatmentStagesFragment
          >
        >;
        tags: Maybe<
          Array<
            { __typename?: 'UpdateTagTreatmentTreeEmbedded' } & Pick<
              UpdateTagTreatmentTreeEmbedded,
              'name' | 'isAssignTag'
            > & {
                tag: { __typename?: 'Tag' } & Pick<Tag, '_id'>;
              }
          >
        >;
      } & TreatmentDefaultFragment
  >;
};

export type DeleteTreatmentStageMutationVariables = {
  params: DeleteTreatmentStageInput;
};

export type DeleteTreatmentStageMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteTreatmentStage'
>;

export type UpdateTreatmentStageMutationVariables = {
  params: UpdateTreatmentStageInput;
};

export type UpdateTreatmentStageMutation = { __typename?: 'Mutation' } & {
  updateTreatmentStage: {
    __typename?: 'TreatmentStage';
  } & TreatmentStageFragment;
};

export type CreateTreatmentStagesMutationVariables = {
  params: CreateTreatmentStagesInput;
};

export type CreateTreatmentStagesMutation = { __typename?: 'Mutation' } & {
  createTreatmentStages: Array<
    { __typename?: 'TreatmentStage' } & TreatmentStageFragment
  >;
};

export type UpdateTreatmentStagesEmbedMutationVariables = {
  params: UpdateTreatmentStagesEmbedInput;
};

export type UpdateTreatmentStagesEmbedMutation = { __typename?: 'Mutation' } & {
  updateTreatmentStagesEmbed: Maybe<
    { __typename?: 'Treatment' } & Pick<Treatment, '_id'>
  >;
};

export type ShareAssignTreatmentMutationVariables = {
  params: ShareTreatmentInput;
};

export type ShareAssignTreatmentMutation = { __typename?: 'Mutation' } & {
  shareAssignTreatment: Array<
    { __typename?: 'ShareTreatmentRes' } & ResponseShareTreatmentResFragment
  >;
};

export type LinkTreatmentMutationVariables = {
  params: LinkTreatmentInput;
};

export type LinkTreatmentMutation = { __typename?: 'Mutation' } & {
  linkTreatment: { __typename?: 'TreatmentStage' } & TreatmentStageFragment;
};

export type UnLinkTreatmentMutationVariables = {
  params: UnlinkTreatmentInput;
};

export type UnLinkTreatmentMutation = { __typename?: 'Mutation' } & {
  unlinkTreatment: { __typename?: 'TreatmentStage' } & TreatmentStageFragment;
};

export type UploadSurveyImageMutationVariables = {
  file: Scalars['Upload'];
};

export type UploadSurveyImageMutation = { __typename?: 'Mutation' } & {
  uploadSurveyImage: Maybe<
    { __typename?: 'SurveyImageRes' } & Pick<
      SurveyImageRes,
      '_id' | 'name' | 'thumbNailsUrls'
    >
  >;
};

export type GetTreatmentForReferenceQueryVariables = {
  params: GetTreatmentsForUpdateInput;
};

export type GetTreatmentForReferenceQuery = { __typename?: 'Query' } & {
  getTreatmentsForUpdate: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & TreatmentForAddUpdateFragment>;
  };
};

export type ReplaceTreatmentMutationVariables = {
  params: ReplaceTreatmentInput;
};

export type ReplaceTreatmentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'replaceTreatment'
>;

export type GetUndeletedTreatmentsQueryVariables = {
  params: GetTreatmentsForUpdateInput;
};

export type GetUndeletedTreatmentsQuery = { __typename?: 'Query' } & {
  getUndeletedTreatments: { __typename?: 'TreatmentsRes' } & {
    nodes: Array<{ __typename?: 'Treatment' } & CommonTreatmentFragment>;
  };
};

export type HandleFavoriteTreatmentMutationVariables = {
  params: UpdateToFavoriteInput;
};

export type HandleFavoriteTreatmentMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'handleFavoriteTreatment'>;

export type SetDefaultTreatmentMutationVariables = {
  treatmentId: Scalars['ObjectId'];
};

export type SetDefaultTreatmentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setDefaultTreatment'
>;

export type UpdateTagForTreatmentTreeMutationVariables = {
  params: UpdateTagForTreatmentTreeInput;
};

export type UpdateTagForTreatmentTreeMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'updateTagForTreatmentTree'>;

export type GetTwilioLogsQueryVariables = {
  params: GetTwilioLogInput;
};

export type GetTwilioLogsQuery = { __typename?: 'Query' } & {
  getTwilioLogs: { __typename?: 'TwilioLogRes' } & {
    nodes: Array<{ __typename?: 'TwilioLog' } & TwilioLogFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type UserListQueryVariables = {
  params: GetUsersInput;
};

export type UserListQuery = { __typename?: 'Query' } & {
  getUsers: { __typename?: 'GetUsersRes' } & {
    nodes: Array<{ __typename?: 'MeRes' } & UserListFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetUserListBasicQueryVariables = {
  params: GetUsersInput;
};

export type GetUserListBasicQuery = { __typename?: 'Query' } & {
  getUsers: { __typename?: 'GetUsersRes' } & {
    nodes: Array<
      { __typename?: 'MeRes' } & Pick<
        MeRes,
        '_id' | 'firstName' | 'lastName' | 'middleName' | 'email'
      >
    >;
  };
};

export type GetUsersForNavigatorQueryVariables = {
  params: GetUsersForNavigatorInput;
};

export type GetUsersForNavigatorQuery = { __typename?: 'Query' } & {
  getUsersForNavigator: { __typename?: 'UsersRes' } & {
    nodes: Array<{ __typename?: 'User' } & UserFragment>;
  };
};

export type UserAddMutationVariables = {
  params: AddUserInput;
};

export type UserAddMutation = { __typename?: 'Mutation' } & {
  addUser: { __typename?: 'User' } & Pick<User, '_id'>;
};

export type InactiveUserMutationVariables = {
  params: InactiveUserInput;
};

export type InactiveUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'inactiveUser'
>;

export type DeleteUserMutationVariables = {
  params: IdInputType;
};

export type DeleteUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteUser'
>;

export type UserDetailQueryVariables = {
  params: IdInputType;
};

export type UserDetailQuery = { __typename?: 'Query' } & {
  getUser: Maybe<{ __typename?: 'User' } & UserFragment>;
};

export type UserUpdateMutationVariables = {
  params: UpdateUserInput;
};

export type UserUpdateMutation = { __typename?: 'Mutation' } & {
  updateUserProfile: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type UserUpdateInfoMutationVariables = {
  params: UpdateUserInfoInput;
};

export type UserUpdateInfoMutation = { __typename?: 'Mutation' } & {
  updateUserInfo: Maybe<
    { __typename?: 'MeRes' } & Pick<
      MeRes,
      'firstName' | 'middleName' | 'lastName' | 'status' | 'phones'
    > & { idUser: MeRes['_id'] }
  >;
};

export type SetNavigatorMutationVariables = {
  params: SetRoleInput;
};

export type SetNavigatorMutation = { __typename?: 'Mutation' } & {
  setNavigator: Maybe<
    { __typename?: 'User' } & Pick<User, 'role'> & {
        idNavigator: User['_id'];
      } & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<Organization, 'name'> & {
              idOrg: Organization['_id'];
            }
        >;
      }
  >;
};

export type AddSecondaryEmailMutationVariables = {
  params: AddSecondaryEmailInput;
};

export type AddSecondaryEmailMutation = { __typename?: 'Mutation' } & {
  addSecondaryEmail: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type RemoveSecondaryEmailMutationVariables = {
  params: RemoveSecondaryEmailInput;
};

export type RemoveSecondaryEmailMutation = { __typename?: 'Mutation' } & {
  removeSecondaryEmail: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type SetPrimaryEmailMutationVariables = {
  params: SetPrimaryEmailInput;
};

export type SetPrimaryEmailMutation = { __typename?: 'Mutation' } & {
  setPrimaryEmail: { __typename?: 'MeRes' } & ResponseLoginFragment;
};

export type AddUserSecondaryEmailMutationVariables = {
  params: AddUserSecondaryEmailInput;
};

export type AddUserSecondaryEmailMutation = { __typename?: 'Mutation' } & {
  addUserSecondaryEmail: { __typename?: 'User' } & Pick<User, '_id'> & {
      secondaryEmail: Maybe<
        Array<
          { __typename?: 'SecondaryEmailInfo' } & Pick<
            SecondaryEmailInfo,
            'email' | 'isVerified'
          >
        >
      >;
    };
};

export type RemoveUserSecondaryEmailMutationVariables = {
  params: RemoveUserSecondaryEmailInput;
};

export type RemoveUserSecondaryEmailMutation = { __typename?: 'Mutation' } & {
  removeUserSecondaryEmail: { __typename?: 'MeRes' } & Pick<MeRes, '_id'> & {
      secondaryEmail: Maybe<
        Array<
          { __typename?: 'SecondaryEmailInfo' } & Pick<
            SecondaryEmailInfo,
            'email' | 'isVerified'
          >
        >
      >;
    };
};

export type SetUserPrimaryEmailMutationVariables = {
  params: SetUserPrimaryEmailInput;
};

export type SetUserPrimaryEmailMutation = { __typename?: 'Mutation' } & {
  setUserPrimaryEmail: { __typename?: 'MeRes' } & Pick<
    MeRes,
    'email' | '_id'
  > & {
      secondaryEmail: Maybe<
        Array<
          { __typename?: 'SecondaryEmailInfo' } & Pick<
            SecondaryEmailInfo,
            'email' | 'isVerified'
          >
        >
      >;
    };
};

export type InactivateNavigatorMutationVariables = {
  params: UpdateUserInfoInput;
};

export type InactivateNavigatorMutation = { __typename?: 'Mutation' } & {
  updateUserInfo: Maybe<{ __typename?: 'MeRes' } & UserListFragment>;
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;

export type CreateDefaultOrganizationMutationVariables = {
  params: CreateDefaultOrganizationInput;
};

export type CreateDefaultOrganizationMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'createDefaultOrganization'>;

export type GetHomepageOrganizationQueryVariables = {
  params: CreateDefaultOrganizationInput;
};

export type GetHomepageConfigurationQuery = {
  __typename?: 'Query';
} & Pick<Query, 'getHomepageConfiguration'>;

export type ChatBotRes = {
  __typename?: 'ChatBotRes';
  responseId: Scalars['ObjectId'];
  answer: Scalars['String'];
  relatedtreatments: Array<Treatment>;
};

export type ChatMessageGQL = {
  __typename?: 'ChatBotHistoryRes';
  responseId?: Scalars['ObjectId'];
  role: Scalars['String'];
  content: Scalars['String'];
  relatedtreatments?: Array<Treatment>;
};

export type TreatmentAssignment = {
  _id: Scalars['ObjectId'];
  token: Scalars['String'];
  slug: Scalars['String'];
  organization: Scalars['String'];
  orgName: Scalars['String'];
  treatmentName: Scalars['String'];
  email: Scalars['String'];
};

export type QueryChatBotArgs = {
  params: ChatBotQueryInput;
};

export type ChatBotQueryInput = {
  query: Scalars['String'];
  treatmentAssignmentInput?: Array<TreatmentAssignment>;
  nologkey?: Maybe<Scalars['String']>;
  organization?: Scalars['ObjectId'];
  contextTreatments?: Maybe<Array<Scalars['String']>>;
  contextRequired?: Maybe<Scalars['Boolean']>;
  formRequired?: Maybe<Scalars['Boolean']>;
  isOut?: Maybe<Scalars['Boolean']>;
};

export type QueryRetrieveInput = {
  nologkey: Scalars['String'];
};

export type QueryChatBotHistoryArgs = {
  params: QueryRetrieveInput;
};

export type ChatBotQuery = { __typename?: 'Query' } & {
  chatbot: { __typename?: 'ChatBotRes' } & { responseId: 'ObjectId' } & {
    answer: 'String';
  } & {
    relatedtreatments: Array<{
      survey: Array<Treatment>;
      __typename?: 'Treatment';
    }>;
  } & {
    contexts: Array<Scalars['String']>;
    formName: 'String';
    isForm: 'Boolean'
  };
};

export type GetChatBotFormQueryVariables = {
  params: ChatBotQueryInput;
};

export type GetChatBotFormQuery = { __typename?: 'Query' } & {
  chatbotform: Scalars['String']
};

export type ChatBotHistoryQuery = { __typename?: 'Query' } & {
  history: Array<
    { __typename?: 'ChatBotHistoryRes' } & { responseId?: 'ObjectId' } & {
      role: 'String';
    } & {
      content: 'String';
    } & { like: 'Boolean' } & { comment: 'String' } & {
      relatedArticles: Array<{
        survey: Array<Treatment>;
        __typename?: 'Treatment';
      }>;
    }
  >;
};

export type ChatBotHistoryKeyQuery = { __typename?: 'Query' } & {
  getHistoryKey: 'String';
};

export type RawGPTQuery = { __typename?: 'Query' } & {
  rawgpt: { rawgpt: Scalars['String'] };
};

export type ImageType = 'banner' | 'main';

export type SurveyResultFragment = {
  __typename?: 'SurveyResult';
} & Pick<SurveyResult, '_id' | 'data' | 'createdAt' | 'updatedAt'> & {
    owner: Maybe<
      { __typename?: 'Patient' } & Pick<
        Patient,
        'firstName' | 'lastName' | 'middleName'
      >
    >;
  } & {
    survey: Maybe<Pick<Treatment, '_id'>>;
  };

export type ChatBotFeedbackArgs = {
  params: ChatBotFeedbackInput;
};

export type ChatBotFeedbackInput = {
  messageId: Scalars['ObjectId'];
  like?: Scalars['Boolean'];
  comment: Maybe<Scalars['String']>;
};

export type ChatbotFeedbackMutation = { __typename?: 'Mutation' } & {
  chatbotFeedback: { __typename?: 'ChatbotFeedbackRes' } & {
    addLikeOrDislike: Scalars['Boolean'];
  };
};
export type CreateQrCodeSettingsMutationVariables = {
  params: QRCodeSettingsInput;
};

export type CreateQrCodeSettingsMutation = { __typename?: 'Mutation' } & {
  _id: Scalars['ObjectId'];
} & { createQrCodeSettings: Options };

export type GetQrCodeSettingsQuery = { __typename?: 'Mutation' } & {
  getQrCodeSettings: Options;
};

export type UpdateChatbotPromptArgs = {
  params: CreateChatbotConfigInput;
};

export type CreateChatbotConfigInput = {
  organization: Scalars['ObjectId'];
  prompt: Maybe<Scalars['String']>;
};

export type UpdateChatbotPromptMutation = {
  __typename?: 'Mutation';
} & {
  updatePrompt: { __typename?: 'UpdatePromptRes' } & {
    prompt: Scalars['String'];
  };
};

export type GetChatbotConfigInputArgs = {
  params: GetChatbotConfigInput;
};

export type GetChatbotConfigInput = {
  organization: Scalars['ObjectId'];
};

export type ChatBotPromptQuery = { __typename?: 'Query' } & {
  chatbotPrompt: { __typename?: 'GetPromptRes' } & {
    prompt: Scalars['String'];
  };
};

export type GetChatbotPDFInput = {
  query: Scalars['String'];
  text: Scalars['String'];
  targetLanguage: Maybe<Scalars['String']>;
  logoURL?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['ObjectId']>;
};

export type GetChatbotPDFQueryVariables = {
  params: GetChatbotPDFInput;
};

export type GetChatbotPDFQuery = { __typename?: 'Query' } & {
  getChatbotPDF: Scalars['Buffer'];
};

export type FlowInput = {
  name: Scalars['String'];
  references: Array<Scalars['ObjectId']>;
  user: Scalars['ObjectId'];
};

export type GetAssignFlowInput = {
  _id: Scalars['ObjectId'];
  token: Scalars['String'];
};

export type GetPaginatedFlowInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByOrder?: Maybe<Scalars['JSONObject']>;
};

export type FlowFragment = {
  __typename?: 'Flow';
} & Pick<
  Treatment,
  '_id' | 'name' | 'slug' | 'organization' | 'urlSharing' | 'qrCode'
> & {
    references: Array<
      Pick<
        Treatment,
        | '_id'
        | 'name'
        | 'description'
        | 'type'
        | 'hideMainMedia'
        | 'mainVideo'
        | 'mainImage'
        | 'content'
        | 'surveyData'
      > & {
        survey: Maybe<Array<SurveyFragment>>;
      }
    >;
    user: User;
  };

export type GetAllFlowsQueryVariables = {
  params: GetPaginatedFlowInput;
};

export type GetAllFlowsQuery = { __typename?: 'Query' } & {
  getAllFlows: { __typename?: 'FlowRes' } & {
    nodes: Array<{ __typename?: 'Flow' } & FlowFragment>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type CreateFlowMutationVariables = {
  params: FlowInput;
};

export type CreateFlowMutation = { __typename?: 'Mutation' } & {
  createFlow: {
    __typename?: 'Flow';
  } & FlowFragment;
};

export type UpdateFlowMutationVariables = {
  params: FlowInput & { _id: Scalars['ObjectId'] };
};

export type UpdateFlowMutation = { __typename?: 'Mutation' } & {
  updateFlow: {
    __typename?: 'Flow';
  } & FlowFragment;
};

export type DeleteFlowMutationVariables = {
  params: { _id: Scalars['ObjectId'] };
};

export type DeleteFlowMutation = { __typename?: 'Mutation' } & {
  deleteFlow: Scalars['Boolean'];
};

export type CreateQRCodeFlowMutationVariables = {
  params: IdInputType;
};

export type CreateQRCodeFlowMutation = { __typename?: 'Mutation' } & {
  createQRCodeFlow: {
    __typename?: 'Flow';
  } & FlowFragment;
};

export type AssignFlowMutationVariables = {
  params: { token: Scalars['String'] };
};

export type AssignFlowMutation = { __typename?: 'Mutation' } & {
  assignFlow: {
    __typename?: 'AssignFlowRes';
  } & AssignFlowRes;
};

export type GetFlowDetailQueryVariables = {
  params: GetAssignFlowInput;
};

export type GetFlowDetailQuery = { __typename?: 'Query' } & {
  getFlowDetail: { __typename?: 'Flow' } & FlowFragment;
};

export type CheckUserInSystemQueryVariables = {
  params: CheckEmailOrPhoneNumber;
};

export type CheckUserInSystemQuery = { __typename?: 'Query' } & {
  checkUserInSystem: {
    isValid: Scalars['Boolean'];
    userLoggedIn: Scalars['Boolean'];
  };
};

export type GenerateAndSendOTPForUserMutationVariables = {
  params: CheckEmailOrPhoneNumber;
};

export type GenerateAndSendOTPForUserMutation = { __typename?: 'Mutation' } & {
  generateAndSendOTPForUser: { success: Scalars['Boolean'] };
};

export type GenerateAndSendOTPForNonUserMutationVariables = {
  params: CheckEmailOrPhoneNumber;
};

export type GenerateAndSendOTPForNonUserMutation = {
  __typename?: 'Mutation';
} & {
  generateAndSendOTPForNonUser: { success: Scalars['Boolean'] };
};

export type VerifyOtpForUserMutationVariables = {
  params: ActiveUserOTPInputFlow;
};

export type VerifyOtpForUserMutation = { __typename?: 'Mutation' } & {
  verifyOtpForUser: { success: Scalars['Boolean'] };
};

export type VerifyOtpForNonUserMutationVariables = {
  params: OTPForNonUserFlow;
};

export type VerifyOtpForNonUserMutation = { __typename?: 'Mutation' } & {
  verifyOtpForNonUser: { success: Scalars['Boolean'] };
};

export type SubmitFlowDataMutationVariables = {
  params: SubmitFlowData;
};

export type SubmitFlowDataMutation = { __typename?: 'Mutation' } & {
  submitFlowData: Scalars['Boolean'];
};

export type GetAllConvertedPdfsQueryVariables = {
  params: GetPdfsInput;
};

export type GetAllConvertedPdfsQuery = { __typename?: 'Query' } & {
  getAllConvertedPdfs: { __typename?: 'ConvertedPDFsRes' } & {
    nodes: Array<{ __typename?: 'UploadedPDF' } & UploadedPDF>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetConvertedPdfbyIdQueryVariables = {
  params: { _id: Scalars['ObjectId'] };
};

export type GetConvertedPdfbyIdQuery = { __typename?: 'Query' } & {
  getConvertedPdfbyId: UploadedPDF;
};

export type ConvertPdfToMarkdownMutationVariables = {
  params: UploadedPDFInput;
  file: Scalars['Upload'];
};

export type ConvertPdfToMarkdownMutation = { __typename?: 'Mutation' } & {
  convertPdfToMarkdown: UploadedPDF;
};

export type UpdatePageContentMutationVariables = {
  params: UpdatePdfInput;
};

export type UpdatePageContentMutation = { __typename?: 'Mutation' } & {
  updatePageContent: UploadedPDF;
};

export type SubmitFormDataMutationVariables = {
  params: CreateDynamicFormDataInput;
};

export type SubmitFormDataMutation = { __typename?: 'Mutation' } & {
  dynamicFormData: { __typename?: 'DynamicFormDataRes' } & DynamicFormData;
};

export type GetAllFormsDataQueryVariables = {
  params: GetDynamicFormsInput;
};

export type GetAllFormsDataQuery = { __typename?: 'Mutation' } & {
  getAllFormsData: { __typename?: 'FormsDataRes' } & {
    nodes: Array<{ __typename?: 'DynamicFormData' } & DynamicFormData>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type DeleteDynamicFormDataMutationVariables = {
  params: { formId: Scalars['String'] };
};

export type DeleteDynamicFormDataMutation = { __typename?: 'Mutation' } & {
  deleteDynamicFormData: {};
};

export type GetAllChatAnalysisRequestsQueryVariables = {
  params: GetChatAnalysisRequestsInput;
};

export type GetAllChatAnalysisRequestsQuery = { __typename?: 'Query' } & {
  getAllChatAnalysisRequests: { __typename?: 'ChatAnalysisRes' } & {
    nodes: Array<{ __typename?: 'ChatRes' } & ChatAnalysisRequest>;
    meta: Maybe<{ __typename?: 'MetaRes' } & MetaFragment>;
  };
};

export type GetChatAnalysisRequestQueryVariables = {
  params: IdChatAnalysisRequestInput;
};

export type GetChatAnalysisRequestQuery = { __typename?: 'Query' } & {
  getChatAnalysisRequest: Scalars['JSON'];
};

export type CreateChatAnalysisRequestMutationVariables = {
  params: CreateChatAnalysisRequestInput;
};

export type CreateChatAnalysisRequestMutation = { __typename?: 'Mutation' } & {
  createChatAnalysisRequest: { __typename?: 'ChatAnalysisRequest' } & ChatAnalysisRequest;
};

export type CreateChatAnalysisRequestMutationV2Variables = {
  params: CreateChatAnalysisRequestInput_V2;
};

export type CreateChatAnalysisRequestV2Mutation = {
  __typename?: 'Mutation';
} & {
  createChatAnalysisRequest_v2: { __typename?: 'ChatAnalysisRequest' } & Array<
    ProcessedData
  >;
};