import React from 'react';
import { DEFAULT_COLOR_TAG, KEY_LOCAL_ASSIGN_FLOW, KEY_LOCAL_ASSIGN_TREATMENT } from 'CONST';
import {
  AssignFlowMutation,
  AssignFlowRes,
  AssignTreatmentMutation,
  AssignTreatmentResFragment,
  ByNWeekDay,
  CommentEdgeFragment,
  CompletedStatus,
  ContactMethod,
  PatientFollowUpFragment,
  PatientOwnerFragment,
  PatientTimeReportFragment,
  RegimenConfigEmbeddedFragment,
  ScheduleConfigEmbedded,
  SpecifiedType,
  SubNoteFragment,
  SurveyResultEmbeddedFragment,
  TreatmentRegimenFragment,
  TreatmentType,
  UserFragment,
  UserStatus,
} from 'types.d';
import {
  DataFilterDate,
  ElementsPageInterface,
} from 'modules/dashboard/interfaces';
import subWeeks from 'date-fns/subWeeks';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import {
  Box,
  Checkbox,
  Chip,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChipStyled, TypographyBold } from 'share/component_css';
import { Rule } from 'modules/patients/interfaces';
import { ChipStyled as ChipStatusStyled } from 'modules/user/styles';
import {
  addHours,
  differenceInSeconds,
  addMinutes,
  endOfDay,
  isWithinInterval,
  startOfDay,
  subDays,
  isBefore,
  isAfter,
} from 'date-fns';
import { green, red, grey, purple } from '@material-ui/core/colors';
import { RRule } from 'rrule';
import { DataCalendar } from 'modules/patients/components/Survey/DialogSendSurvey';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Phone } from 'hooks';
import { Regimen } from 'modules/setting_groups/components/ActionTreatmentRegimen';
import { EventClickArg } from '@fullcalendar/react';
import { RowTreatment } from 'modules/setting_groups/components/DialogAddGroups';
import { FlowTreatment } from 'modules/treatment_flows/components/DialogAddFlow';

//handle error from server
export const handleError = (error: any, hasKey?: boolean) => {
  let arrError: string[] = [];
  let arrKeyErr: string[] = [];
  if (error && error.extensions) {
    const errs: {
      constraints: { [key: string]: string };
    }[] = error.extensions.exception?.validationErrors;
    arrError = errs
      ? errs.flatMap(item => {
        return Object.values(item.constraints);
      })
      : [`${error?.message}`];
    arrKeyErr = errs
      ? errs.flatMap(item => {
        return Object.keys(item.constraints);
      })
      : [`${error?.extensions.code}`];
  }
  return hasKey ? arrKeyErr : arrError;
};

export const formatDate = (
  date: string,
  isShowTime?: boolean,
  isAMPM?: boolean,
) => {
  return format(
    new Date(date),
    isShowTime
      ? isAMPM
        ? 'MM/dd/yyyy hh:mm a'
        : 'MM/dd/yyyy HH:mm'
      : 'MM/dd/yyyy',
  );
};

export const formatPhoneNumber = (phoneNumberString = '') => {
  const input = phoneNumberString.replace(/\D/g, '');
  if (phoneNumberString[0] === '1' || phoneNumberString[0] === '+') {
    return `+${input.substring(0, 1)} (${input.substring(
      1,
      4,
    )}) ${input.substring(4, 7)}${input.substring(7) !== '' ? `-${input.substring(7)}` : ''
      }`;
  }
  return `+${input.substring(0, 2)} ${input.substring(2, 5)} ${input.substring(
    5,
    8,
  )} ${input.substring(8, 11)} ${input.substring(11, 14)} ${input.substring(
    14,
  )} 
  `;
};
//Session storage
export const checkHasSessionStorage = (key: string) => {
  return !!sessionStorage.getItem(key);
};
export const setDataSessionStorage = (key: string, stringData: string) => {
  sessionStorage.setItem(key, stringData);
};

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

//Local storage
export const checkHasLocalStorage = (key: string) => {
  return !!localStorage.getItem(key);
};

export const setDataLocalStorage = (key: string, stringData: string) => {
  localStorage.setItem(key, stringData);
};

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const checkHasLocalAssignTreatment = () => {
  return checkHasLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT);
};

export const getLocalAssignTreatment: () => (AssignTreatmentResFragment & {
  email: string;
})[] = () => {
  return checkHasLocalAssignTreatment()
    ? JSON.parse(localStorage.getItem(KEY_LOCAL_ASSIGN_TREATMENT) as string)
    : [];
};

export const checkHasLocalAssignFlow = () => {
  return checkHasLocalStorage(KEY_LOCAL_ASSIGN_FLOW);
};

export const getLocalAssignFlow: () => (AssignFlowRes & {
  email: string;
})[] = () => {
  return checkHasLocalAssignFlow()
    ? JSON.parse(localStorage.getItem(KEY_LOCAL_ASSIGN_FLOW) as string)
    : [];
};

export const removeLocalAssignTreatment = () => {
  removeLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT);
};

export const addDataLocalAssignTreatment = (
  data: AssignTreatmentMutation['assignTreatment'],
  email: string | null,
) => {
  delete data!.__typename;
  const checkItemInLocal = getLocalAssignTreatment().find(item =>
    Object.is(item!._id, data!._id),
  );
  const dataAddLocal = checkHasLocalAssignTreatment()
    ? [...getLocalAssignTreatment(), { ...data, email }]
    : [{ ...data, email }];

  if (!checkItemInLocal) {
    setDataLocalStorage(
      KEY_LOCAL_ASSIGN_TREATMENT,
      JSON.stringify(dataAddLocal),
    );
  }
};

export const addDataLocalAssignFlow = (
  data: AssignFlowMutation['assignFlow'],
  name: string | null,
) => {
  delete data!.__typename;
  const checkItemInLocal = getLocalAssignFlow().find(item =>
    Object.is(item!.flowId, data!.flowId),
  );
  const dataAddLocal = checkHasLocalAssignFlow()
    ? [{ ...data, name }]
    : [{ ...data, name }];


  if (!checkItemInLocal) {
    setDataLocalStorage(
      KEY_LOCAL_ASSIGN_FLOW,
      JSON.stringify(dataAddLocal),
    );
  }
};

export const debounce = (delay: number, fn: (...args: any[]) => void) => {
  let timerId: number;
  return (...args: any[]) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = 0;
    }, delay);
  };
};

interface SortInterface {
  order?: number | null;
  createdAt?: any | null;
  name?: string | null;
}

export const sortTreatment = <T extends SortInterface>(
  data?: T[] | null,
): T[] => {
  if (data) {
    return data.sort((a, b) => {
      const orderA = a?.order ?? 0;
      const orderB = b?.order ?? 0;
      return (
        orderA - orderB ||
        new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
      );
    });
  }
  return [];
};

export const sortSubTreatment = <T extends SortInterface>(
  data?: T[] | null,
): T[] => {
  if (data) {
    return data.sort((a, b) => {
      const orderA = a?.order ?? 0;
      const orderB = b?.order ?? 0;
      return (
        orderA - orderB ||
        new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime()
      );
    });
  }
  return [];
};

export const sortObject = <T extends SortInterface>(data?: T[] | null): T[] => {
  if (data) {
    return data.sort((a, b) => {
      const orderA = a?.name?.toLocaleLowerCase() ?? '';
      const orderB = b?.name?.toLocaleLowerCase() ?? '';
      return orderA > orderB ? 1 : -1;
    });
  }
  return [];
};

export const renderTitleAnswer = (
  answers: { [key: string]: any },
  item?: ElementsPageInterface,
) => {
  if (item && answers) {
    const answer = answers[item.name];
    if (item.type === 'boolean') {
      if (answer === true) {
        return item.labelTrue || 'Yes';
      }
      if (answer === false) {
        return item.labelFalse || 'No';
      }
      return undefined;
    }

    if (['rating'].includes(item.type)) {
      const ratesValues: { [key: string]: any } = item.rateValues
        ? convertArrayToObject(
          item.rateValues as {
            value: string;
            text: string;
          }[],
          'value',
        )
        : {};
      return ratesValues[answer]?.text || answer;
    }

    if (
      ['checkbox', 'radiogroup', 'dropdown', 'imagepicker'].includes(item.type)
    ) {
      const choices: { [key: string]: any } = convertArrayToObject(
        item.choices as {
          value: string;
          text: string;
        }[],
        'value',
      );

      if (typeof answer === 'string') {
        return item.hasOther && answer === 'other'
          ? `Other`
          : choices[answer]?.text || answer;
      }
      if (Array.isArray(answer) && answer.length !== 0) {
        return answer
          ? (answer as string[]).map(itemAnswer => {
            return item.hasOther && itemAnswer === 'other'
              ? `Other`
              : choices[itemAnswer]?.text || itemAnswer;
          })
          : '';
      }
      return '';
    }
    if (item.type === 'multipletext') {
      const items: { [key: string]: any } = convertArrayToObject(
        item.items as {
          title: string;
          name: string;
        }[],
        'name',
      );
      if (answer && typeof answer === 'object' && answer.length !== 0) {
        return Object.keys(answer).map(eachAnswer => {
          const question = items[eachAnswer];
          return [
            question?.title ? question?.title : question?.name,
            (answer as { [key: string]: any })[eachAnswer],
          ];
        });
      }
      return [];
    }

    return answer;
  }
  return '';
};

export const getStartAndEndWeek = (date: Date) => {
  const startWeek = startOfWeek(date, { weekStartsOn: 1 });
  const endWeek = endOfWeek(date, { weekStartsOn: 1 });
  return {
    start: new Date(startWeek).toISOString(),
    end: new Date(endWeek).toISOString(),
  };
};

export const getStartAndEndDay = (date: Date, numberOfDays: number) => {
  const startDay = startOfDay(subDays(new Date(date), numberOfDays));
  const endDay = endOfDay(date);
  return {
    start: new Date(startDay).toISOString(),
    end: new Date(endDay).toISOString(),
  };
};

export const beforeMonth = (dayBefore?: number) => {
  const dayBeforeMonth = subMonths(new Date(), 1);
  const startMonth = startOfMonth(
    dayBefore ? subMonths(new Date(), dayBefore) : dayBeforeMonth,
  );
  const endMonth = endOfMonth(dayBeforeMonth);
  return {
    start: new Date(startMonth).toISOString(),
    end: new Date(endMonth).toISOString(),
  };
};

const getQuestionFromPanel = (
  panel: ElementsPageInterface,
): ElementsPageInterface[] => {
  const objPanel: { [key: string]: ElementsPageInterface[] | undefined } = {
    panel: panel.elements,
    paneldynamic: panel.templateElements,
  };
  if (objPanel[panel?.type as string]) {
    return (objPanel[panel?.type as string] as ElementsPageInterface[]).flatMap(
      item => {
        if (item.type === panel.type) {
          return getQuestionFromPanel(item);
        } else {
          return item;
        }
      },
    );
  }
  return [];
};

export const getAllQuestion = (config: string): ElementsPageInterface[] => {
  const pages: {
    pages: {
      elements: ElementsPageInterface[];
    }[];
  } = JSON.parse(config);
  const allQuestions = pages.pages
    .flatMap(question => {
      return question.elements;
    })
    .filter(Boolean);
  if (allQuestions.length === 0) {
    return [];
  }
  return (
    allQuestions
      .map(item => {
        if (['paneldynamic', 'panel'].includes(item?.type)) {
          return getQuestionFromPanel(item);
        } else {
          return item;
        }
      })
      ?.flat() || []
  );
};

export const convertArrayToObject = (
  array: any[],
  key: string,
): { [key: string]: any } => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key] || item]: item,
    };
  }, initialValue);
};

interface TrimObjectInterface {
  [key: string]: any;
}

export const trimObjectValue = <T extends TrimObjectInterface>(data: T): T => {
  const dataTrim = Object.keys(data).reduce((obj, item) => {
    return {
      ...obj,
      [item]:
        typeof data[item] === 'string'
          ? (data[item] as string).trim()
          : data[item],
    };
  }, {});
  return dataTrim as T;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const listToTree = (
  list: { [key: string]: any }[],
  keyId?: string,
  keyParent?: string,
) => {
  const hashTable = Object.create(null);
  list.forEach(
    aData => (hashTable[aData[keyId || 'id']] = { ...aData, children: [] }),
  );
  const dataTree: { [key: string]: any }[] = [];
  list.forEach(aData => {
    if (aData[keyParent || 'parent'])
      hashTable[aData[keyParent || 'parent']]?.children.push(
        hashTable[aData[keyId || 'id']],
      );
    else dataTree.push(hashTable[aData[keyId || 'id']]);
  });
  return dataTree;
};
export const commentToTree = (list: CommentEdgeFragment[]) => {
  const hashTable = Object.create(null);
  list.forEach(
    aData => (hashTable[aData['node']['_id']] = { ...aData, children: [] }),
  );
  const dataTree: { [key: string]: any }[] = [];
  list.forEach(aData => {
    if (aData['node']['parentId'])
      hashTable[aData['node']['parentId']]?.children.push(
        hashTable[aData['node']['_id']],
      );
    else dataTree.push(hashTable[aData['node']['_id']]);
  });
  return dataTree;
};

export const randomString = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const checkHasInstallApp = () => {
  return (
    ('standalone' in window.navigator && window.navigator['standalone']) ||
    window.matchMedia('(display-mode: standalone)').matches
  );
};

export const isIOS = (): boolean => {
  // @ts-ignore
  if (navigator.standalone) {
    //user has already installed the app
    return false;
  }

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
export const isAndroid = () => {
  return /(android)/i.test(navigator.userAgent);
};

// eslint-disable-next-line no-useless-escape
const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const renderMedia = (url?: string | null) => {
  if (url) {
    return regex.test(url)
      ? url
      : `${process.env.REACT_APP_LINK_S3}${process.env.REACT_APP_MEDIA_BUCKET}${url}`;
  }
  return '';
};

export const renderPDFLink = (fileName: string) => {
  return `${process.env.REACT_APP_LINK_S3}${process.env.REACT_APP_MEDIA_BUCKET}/${fileName}`;
};

export const formatDoB = (date: string) => {
  const dt = new Date(date);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
  );
  return format(dtDateOnly, 'MM/dd/yyyy');
};

export const convertDateWithoutUTC = (date: string) => {
  const dateDob = new Date(date);
  const utcDob = new Date(
    Date.UTC(
      dateDob.getFullYear(),
      dateDob.getMonth(),
      dateDob.getDate(),
      0,
      0,
      0,
    ),
  );
  return utcDob.toUTCString();
};
export const convertDate = (dateAnswer?: string) => {
  let objDate: { startDate: string | null; endDate: string | null } = {
    startDate: null,
    endDate: null,
  };
  if (dateAnswer) {
    switch (dateAnswer) {
      case 'lastWeek':
        const dateWeek = getStartAndEndWeek(subWeeks(new Date(), 1));
        objDate = {
          startDate: dateWeek.start,
          endDate: dateWeek.end,
        };
        break;
      case 'lastMonth':
        const dateMonth = beforeMonth();
        objDate = {
          startDate: dateMonth.start,
          endDate: dateMonth.end,
        };
        break;
      case 'last6Months':
        const date6Month = beforeMonth(6);
        objDate = {
          startDate: date6Month.start,
          endDate: date6Month.end,
        };
        break;
      case 'thisWeek':
        objDate = {
          startDate: startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
          endDate: endOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
        };
        break;
      case 'thisMonth':
        objDate = {
          startDate: startOfMonth(new Date()).toISOString(),
          endDate: endOfMonth(new Date()).toISOString(),
        };
        break;
      case 'nextWeek':
        const nextWeek = getStartAndEndWeek(addWeeks(new Date(), 1));
        objDate = {
          startDate: nextWeek.start,
          endDate: nextWeek.end,
        };
        break;
      case 'nextMonth':
        const nextMonth = addMonths(new Date(), 1);
        objDate = {
          startDate: startOfMonth(nextMonth).toISOString(),
          endDate: endOfMonth(nextMonth).toISOString(),
        };
        break;
      case 'allDates':
        objDate = {
          startDate: null,
          endDate: null,
        };
        break;
      case 'today':
        objDate = {
          startDate: new Date().toISOString(),
          endDate: new Date().toISOString(),
        };
        break;
      case 'next7Days':
        objDate = {
          startDate: new Date(addDays(new Date(), 1)).toISOString(),
          endDate: new Date(addDays(new Date(), 7)).toISOString(),
        };
        break;
      case 'next30Days':
        objDate = {
          startDate: new Date(addDays(new Date(), 1)).toISOString(),
          endDate: new Date(addDays(new Date(), 30)).toISOString(),
        };
        break;
      default:
        objDate = {
          startDate: null,
          endDate: null,
        };
        break;
    }

    return {
      startDate: objDate.startDate
        ? new Date(
          new Date(objDate.startDate).setHours(0, 0, 0, 0),
        ).toISOString()
        : objDate.startDate,
      endDate: objDate.endDate
        ? new Date(
          new Date(objDate.endDate).setHours(23, 59, 59, 0),
        ).toISOString()
        : objDate.endDate,
    };
  }
  return objDate;
};

export const getStatusByAnswer = (survey: any) => {
  const allQuestions = survey.getAllQuestions();
  const numberOfQuestions = allQuestions.length;
  const hasRequiredQuestions = allQuestions.some((item: any) => {
    return item.isRequired;
  });
  const numberOfAnswers = allQuestions.reduce((total: any, item: any) => {
    return !item.isEmpty() ? (total += 1) : total;
  }, 0);
  if (hasRequiredQuestions) {
    return CompletedStatus.Totally;
  }
  return numberOfQuestions === numberOfAnswers
    ? CompletedStatus.Totally
    : CompletedStatus.Partial;
};

export const generateLabelNote = (notes: SubNoteFragment[] | null) => {
  if (notes && notes.length !== 0) {
    return notes.sort((a, b) => {
      return (
        new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime()
      );
    })[0] as SubNoteFragment;
  }
  return null;
};

export const isJsonString = (str: string | null) => {
  if (!str) {
    return false;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const generateInformation = (
  information: {
    name: string;
    info: string;
  }[],
) => {
  return information.map(item => {
    return (
      <Box key={item.name}>
        <TypographyBold display="inline" variant="subtitle2">
          {item.name}
        </TypographyBold>{' '}
        <Typography display="inline" variant="subtitle2">
          {item.info}
        </Typography>
      </Box>
    );
  });
};

export const slugify = (text: string, separatedBy = '-') => {
  return text
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, separatedBy) // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '') // trim - from end of text
    .replace(/--+/g, separatedBy); // Replace multiple - with single -
};

export const getError = (error: any, getCode: boolean) => {
  return error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN'
    ? 'FORBIDDEN'
    : handleError(error?.graphQLErrors[0], getCode)[0];
};

export const exportCSV = (name: string, data: string[][]) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  data.forEach(function (rowArray) {
    let row = rowArray.join(',');
    csvContent += row + '\r\n';
  });
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export const countryCode = () => {
  if (process.env.REACT_APP_HAS_VN_CODE === 'true') {
    return {
      pattern: {
        value: /[1,84]/,
        message: 'Phone number must start with 1 or 84',
      },
      countryCodes: ['us', 'vn'],
    };
  }
  return {
    pattern: {
      value: /[1]/,
      message: 'Phone number must start with 1',
    },
    countryCodes: ['us'],
  };
};

export const containsAnyLetters = (value: string) => {
  return /[a-zA-Z`~!@#$%^&*()_|+\-=?^<>()[\]\\.,;:\s@"]/.test(value);
};

export const defaultChecked = (checked: string[]) => {
  if (
    checked?.includes(ContactMethod.Sms) &&
    checked?.includes(ContactMethod.Email)
  ) {
    return ['sendEmail', 'sendSMS'];
  }
  if (checked?.includes(ContactMethod.Email)) {
    return ['sendEmail'];
  }
  if (checked?.includes(ContactMethod.Sms)) {
    return ['sendSMS'];
  }
  return [];
};

export const isBotMail = (mail?: string) => {
  return mail ? mail.includes('@bot') : false;
};

export const checkFilterFollowUp = (
  followUp: PatientFollowUpFragment,
  idFollowUpFilter: string | undefined,
  followUpDate: DataFilterDate,
) => {
  if (followUpDate && followUpDate.endDate && followUpDate.startDate) {
    const checkByDate =
      new Date(followUp?.followUpDate).getTime() >=
      new Date(followUpDate.startDate).getTime() &&
      new Date(followUp?.followUpDate).getTime() <=
      new Date(followUpDate.endDate).getTime();
    if (!idFollowUpFilter || idFollowUpFilter.length === 0) {
      return checkByDate;
    }
    return checkByDate && followUp?.type?._id === idFollowUpFilter;
  }

  return followUp?.type?._id === idFollowUpFilter;
};

export const sortTaskType = (
  value: PatientFollowUpFragment[],
  followUpsFilter?: Rule,
) => {
  if (
    value?.find(item =>
      checkFilterFollowUp(
        item,
        followUpsFilter?.followUpType,
        followUpsFilter?.followUpDate as DataFilterDate,
      ),
    )
  ) {
    const top = value
      ?.slice(
        0,
        value?.filter(item =>
          checkFilterFollowUp(
            item,
            followUpsFilter?.followUpType,
            followUpsFilter?.followUpDate as DataFilterDate,
          ),
        )?.length || 0,
      )
      .sort((a, b) => {
        return (
          new Date(b?.followUpDate).getTime() -
          new Date(a?.followUpDate).getTime()
        );
      });
    const bottom = value?.slice(
      value?.filter(item =>
        checkFilterFollowUp(
          item,
          followUpsFilter?.followUpType,
          followUpsFilter?.followUpDate as DataFilterDate,
        ),
      )?.length,
    );
    return [...top, ...bottom];
  }
  return value;
};

export const convertFilterPatient = (value: string, operator: string) => {
  switch (operator) {
    case 'equal':
      return value;
    case 'contain':
      return {
        $regex: `/.*${value}.*/`,
      };
    case 'startWith':
      return {
        $regex: `/^${value}/`,
      };
    case 'endWith':
      return {
        $regex: `/${value}$/`,
      };
  }
};

export const renderStatusUser = (value: string) => {
  switch (value) {
    case UserStatus.Inactive:
      return <Chip variant="outlined" label="Inactive" color="default" />;
    case UserStatus.Active:
      return <Chip variant="outlined" label="Active" color="primary" />;
    case UserStatus.Pending:
      return (
        <ChipStatusStyled variant="outlined" label="Pending" color="default" />
      );
  }
};

export const getStartOfDay = (value: string | Date) => {
  let date = new Date(value);
  date.setUTCHours(24, 0, 0, 0);
  return date.toISOString();
};

export const getFilters = (valueFilter: any) => {
  let filters = {};
  if (valueFilter) {
    filters = Object.keys(valueFilter).reduce((acc, key) => {
      const values: { [key: string]: any } = acc;
      if (valueFilter[key]) {
        values[key] = valueFilter[key];
      }
      return values;
    }, {});
  }
  return filters;
};

export const dataCheckingCreatedAt = (item: SurveyResultEmbeddedFragment) => {
  if (item.answerDateTime) {
    if (
      isBefore(new Date(addDays(new Date(item.answerDateTime), 7)), new Date())
    ) {
      return 'filledAfter7Days';
    }
    return 'filled';
  } else {
    if (isAfter(new Date(subDays(new Date(), 7)), new Date(item.createdAt))) {
      return 'notFilledOn7Days';
    }
    if (isAfter(new Date(subDays(new Date(), 3)), new Date(item.createdAt))) {
      return 'notFilledOn3Days';
    }
    if (item?.data && Object.keys(item?.data).length !== 0) {
      return 'navigatorFilled';
    }
    return 'notFilled';
  }
};

export const isOnLastWeek = (item: SurveyResultEmbeddedFragment) => {
  const dayOfLastWeek = subDays(new Date(), 7);
  return (
    item?.data &&
    Object.keys(item?.data).length !== 0 &&
    isWithinInterval(new Date(item.updatedAt), {
      start: new Date(startOfWeek(dayOfLastWeek)),
      end: new Date(endOfWeek(dayOfLastWeek)),
    })
  );
};

export const surveyColorTag = (item: SurveyResultEmbeddedFragment) => {
  const nameOfChecking = dataCheckingCreatedAt(item);
  if (nameOfChecking === 'filledAfter7Days') {
    return red[700];
  }
  if (['filled', 'navigatorFilled'].includes(nameOfChecking)) {
    return green[700];
  }
  if (nameOfChecking === 'notFilledOn3Days') {
    return grey[700];
  }
  if (nameOfChecking === 'notFilledOn7Days') {
    return purple[700];
  }

  return grey[700];
};

export const renderOutline = (item: SurveyResultEmbeddedFragment) => {
  const nameOfChecking = dataCheckingCreatedAt(item);
  if (nameOfChecking === 'notFilledOn3Days') {
    return 'border-purple';
  }
  if (nameOfChecking === 'navigatorFilled') {
    return 'border-red';
  }
  return '';
};

export const getCurrentDay = (date: string | null) => {
  return (date ? new Date(date) : new Date()).toLocaleDateString('en-US', {
    weekday: 'long',
  });
};

export const getOrderDay = (day: number, date: string | null) => {
  const dateCompare = date ? new Date(date) : new Date();
  if (day <= 7) {
    return 'first';
  } else if (day <= 14) {
    return 'second';
  } else if (day <= 21) {
    return 'third';
  } else if (day <= 28) {
    return day + 7 <= dateCompare.getDate() ? 'fourth' : 'last';
  } else {
    return 'last';
  }
};

export const getOptionTypeRepeat = (date: string | null) => {
  const dateSelect =
    date && !isNaN(new Date(date).getTime()) ? new Date(date) : new Date();
  return [
    { value: 'notRepeat', title: 'Does not repeat' },
    { value: 'daily', title: 'Daily' },
    {
      value: 'weekly',
      title: `Weekly on ${getCurrentDay(dateSelect.toISOString())}`,
    },
    {
      value: 'monthly',
      title: `Monthly on ${getOrderDay(
        dateSelect.getDate(),
        date,
      )} ${getCurrentDay(dateSelect.toISOString())}`,
    },
    {
      value: 'annually',
      title: `Annually on ${new Intl.DateTimeFormat('en-US', {
        month: 'long',
      }).format(dateSelect)} ${dateSelect.getDate()}`,
    },
    { value: 'allWeek', title: 'Every weekday (Monday to Friday)' },
    { value: 'custom', title: 'Custom' },
  ];
};

export const getOptionMonth = (date: string | null) => {
  return [
    {
      value: `daily`,
      title: `Monthly on day ${(date ? new Date(date) : new Date()).getDate()}`,
      toText: `day ${(date ? new Date(date) : new Date()).getDate()}`,
    },
    {
      value: `monthly`,
      title: `Monthly on ${getOrderDay(
        (date ? new Date(date) : new Date()).getDate(),
        date,
      )} ${getCurrentDay(date)}`,
      toText: `${getOrderDay(
        (date ? new Date(date) : new Date()).getDate(),
        date,
      )} ${getCurrentDay(date)}`,
    },
  ];
};

export const getByNWeekDay = (day: string) => {
  switch (day) {
    case ByNWeekDay.Mo:
      return RRule.MO;
    case ByNWeekDay.Tu:
      return RRule.TU;
    case ByNWeekDay.We:
      return RRule.WE;
    case ByNWeekDay.Th:
      return RRule.TH;
    case ByNWeekDay.Fr:
      return RRule.FR;
    case ByNWeekDay.Sa:
      return RRule.SA;
    default:
      return RRule.SU;
  }
};

export const convertDateFromOption = (
  option: string,
  isAddingFormat?: boolean,
) => {
  const date: { [key: string]: Date } = {
    today: new Date(),
    nextWeek: addWeeks(new Date(), 1),
    nextMonth: addMonths(new Date(), 1),
  };

  return isAddingFormat ? format(date[option], 'MM-dd-yyyy') : date[option];
};

export const convertDateOptionToDate = (
  selectedDate: string,
  isGettingKey?: boolean,
) => {
  if (isValidDate(selectedDate)) {
    const dates: [{ key: string; title: string }, Date][] = [
      [{ key: 'today', title: 'Today' }, new Date()],
      [{ key: 'nextWeek', title: 'Next Week' }, addWeeks(new Date(), 1)],
      [{ key: 'nextMonth', title: 'Next Month' }, addMonths(new Date(), 1)],
    ];
    const selectedOption = dates.find(item => {
      return (
        format(new Date(selectedDate), 'MM-dd-yyyy') ===
        format(new Date(item[1] as Date), 'MM-dd-yyyy')
      );
    });
    if (selectedOption) {
      return isGettingKey ? selectedOption[0].key : selectedOption[0].title;
    }
  }
  return isGettingKey ? '' : 'Custom';
};

export const isValidDate = (stringDate: string) => {
  return isValid(parse(stringDate, 'MM/dd/yyyy', new Date()));
};

export const optionWeekly = (repeatWeek: string) => {
  switch (repeatWeek) {
    case 'Monday':
      return RRule.MO.weekday;
    case 'Tuesday':
      return RRule.TU.weekday;
    case 'Wednesday':
      return RRule.WE.weekday;
    case 'Thursday':
      return RRule.TH.weekday;
    case 'Friday':
      return RRule.FR.weekday;
    case 'Saturday':
      return RRule.SA.weekday;
    case 'Sunday':
      return RRule.SU.weekday;
    default:
      return RRule.MO.weekday;
  }
};

export const optionDay = (date: string | null) => {
  switch ((date ? new Date(date) : new Date()).getDay()) {
    case 1:
      return ByNWeekDay.Mo;
    case 2:
      return ByNWeekDay.Tu;
    case 3:
      return ByNWeekDay.We;
    case 4:
      return ByNWeekDay.Th;
    case 5:
      return ByNWeekDay.Fr;
    case 6:
      return ByNWeekDay.Sa;
    default:
      return ByNWeekDay.Su;
  }
};

export const optionByNWeekday = (date: string | null) => {
  switch (getOrderDay((date ? new Date(date) : new Date()).getDate(), date)) {
    case 'first':
      return 1;
    case 'second':
      return 2;
    case 'third':
      return 3;
    case 'fourth':
      return 4;
    default:
      return -1;
  }
};

export const getRRuleToText = (
  dataCalendar: DataCalendar,
  optionFreq: any,
  sendingDate: string | null,
  optionByWeekDayForHint: number[],
) => {
  if (dataCalendar) {
    let newRrule = {
      freq: optionFreq || 0,
      interval: dataCalendar?.amount ? +dataCalendar?.amount : 1,
      dtstart:
        sendingDate && !isNaN(new Date(sendingDate).getTime())
          ? new Date(sendingDate)
          : new Date(),
    } as ScheduleConfigEmbedded;
    if (
      dataCalendar?.endDate &&
      !isNaN(new Date(dataCalendar?.endDate).getTime()) &&
      !dataCalendar?.endDate.includes('_')
    ) {
      newRrule.until = new Date(convertDateWithoutUTC(dataCalendar.endDate));
    }
    if (dataCalendar.freq === 'week' || dataCalendar.freq === 'month') {
      newRrule.byweekday = optionByWeekDayForHint;
    }

    if (dataCalendar?.after) {
      newRrule.count = dataCalendar.after;
    }
    return capitalizeFirstLetter(new RRule(newRrule).toText());
  }
};

export const renderSearchItem = (
  option: PatientOwnerFragment | UserFragment,
  selected?: boolean,
) => {
  return (
    <>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        className="mr-8"
        checked={!!selected}
      />
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" gutterBottom>
          {`${option?.firstName || '--'} ${option?.middleName ||
            ''} ${option?.lastName || '--'}`}
          {option?.status === UserStatus.Inactive && <>(Inactive)</>}
        </Typography>
        {option?.phones && option?.phones?.length !== 0 && (
          <Typography color="primary" variant="subtitle1">
            {formatPhoneNumber(option?.phones[0]?.phone)}
          </Typography>
        )}
        {!isBotMail(option?.email) && (
          <Typography color="primary" variant="subtitle1">
            {option?.email}
          </Typography>
        )}
      </Box>
    </>
  );
};

export const renderGroupSendDate = (
  time: { hour: number; minute: number } | null,
  type: string,
  date: string,
  hours?: number,
) => {
  if (time) {
    switch (type) {
      case 'nextWeek':
        return addMinutes(
          addHours(addWeeks(startOfDay(new Date(date)), 1), time.hour),
          time.minute,
        );
      case 'nextMonth':
        return addMinutes(
          addHours(addMonths(startOfDay(new Date(date)), 1), time.hour),
          time.minute,
        );
      case 'nextHours':
        return addHours(new Date(date), hours || 1);
      case 'immediate':
        return new Date(date);
    }
  }
  switch (type) {
    case 'nextWeek':
      return addWeeks(new Date(date), 1);
    case 'nextMonth':
      return addMonths(new Date(date), 1);
    case 'nextHours':
      return addHours(new Date(date), hours || 1);
    case 'immediate':
      return new Date(date);
  }
};

const renderSpecifiedType = (value: SpecifiedType) => {
  if (value === SpecifiedType.NextWeek) {
    return 7;
  }
  if (value === SpecifiedType.NextMonths) {
    return 30;
  }
  return 0;
};

export const sortRegimen = (item: TreatmentRegimenFragment) => {
  return item?.regimenConfig
    ?.map(item => {
      return {
        ...item,
        days: item?.specifiedType
          ? renderSpecifiedType(item?.specifiedType)
          : item?.specifiedTime,
      } as Regimen;
    })
    ?.sort((a, b) => a.days - b.days);
};

export const renderOptionByWeekDay = (
  recurrenceDay: string,
  dataCalendar?: DataCalendar | null,
  sendingDate?: string | null,
) => {
  if (recurrenceDay === 'weekly') {
    const numberOfDay = (sendingDate
      ? new Date(sendingDate)
      : new Date()
    ).getDay();
    return [numberOfDay === 0 ? numberOfDay + 6 : numberOfDay - 1];
  }
  if (recurrenceDay === 'allWeek') {
    return [0, 1, 2, 3, 4];
  }
  if (dataCalendar && dataCalendar.freq === 'week') {
    return [optionWeekly(dataCalendar.repeatWeek)];
  }
  return [0];
};

export const renderHintText = (item: ScheduleConfigEmbedded) => {
  const { byNweekDay, ...restValue } = item;
  let newRrule = {
    freq: restValue?.freq || 0,
    interval: restValue?.interval ? +restValue?.interval : 1,
    dtstart: new Date(),
  } as ScheduleConfigEmbedded;
  if (restValue?.until && !isNaN(new Date(restValue?.until).getTime())) {
    newRrule.until = new Date(convertDateWithoutUTC(restValue?.until));
  }
  if (restValue.freq === 2 || restValue.freq === 1) {
    newRrule.byweekday = restValue?.byweekday;
  }
  if (restValue?.count) {
    newRrule.count = restValue?.count;
  }
  return new RRule(newRrule).toText();
};

export const renderPhone = (phones?: Phone[] | null) => {
  const registerPhone = (phones as Phone[])?.find(item => item.default);
  if (!phones || phones?.length === 0) {
    return 'N/A';
  }
  if (registerPhone) {
    return registerPhone?.phone?.substring(1);
  }
  return phones[0].phone;
};

export const renderSendingTime = (item: RegimenConfigEmbeddedFragment) => {
  const time = item?.time;
  if (time) {
    const timeString = `${(time.hour ? time.hour : 0) >= 10 ? time.hour : `0${time.hour}`
      }:${(time.minute ? time.minute : 0) >= 10 ? time.minute : `0${time.minute}`
      }`;
    return format(new Date(`2000-01-01T${timeString}`), 'hh:mm a');
  }
  return '';
};

export const getGMTTime = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? '+' : '-';
  const offsetHours = Math.floor(offset / 60)
    .toString()
    .padStart(2);
  return { offsetOperator, offsetHours };
};

export const renderDefaultAssign = (currentEvent?: EventClickArg) => {
  if (!currentEvent) {
    return 'survey';
  }
  if (currentEvent?.event?.extendedProps?.treatmentRegimenId) {
    return 'group';
  }
  if (
    currentEvent?.event?.extendedProps?.survey.type === TreatmentType.Treatment
  ) {
    return 'treatment';
  }
  return 'survey';
};

export const renderListGroup = (list: RowTreatment[]) => {
  return list.filter(
    (elem, index, self) =>
      index === self.findIndex(item => item._id === elem._id),
  );
};

export const renderListPathway = (list: FlowTreatment[]) => {
  return list.filter(
    (elem, index, self) =>
      index === self.findIndex(item => item._id === elem._id),
  );
};

export const renderCheckedItem = (item: UserFragment) => {
  if (item?.email) {
    return item?.email;
  }
  if (item?.phones && item?.phones?.length > 0) {
    return item?.phones[0]?.phone;
  }
};

export const getDuration = (startDate: string, stopDate: string) => {
  const duration = differenceInSeconds(
    new Date(startDate).setMilliseconds(0),
    new Date(stopDate).setMilliseconds(0),
  );
  return duration;
};

export const renderIsPlur = (number: number) => {
  return number < 10 ? '0' + number : number;
};

export const convertTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return { hours, minutes, seconds };
};
export const renderTime = (totalSeconds: number) => {
  const { hours, minutes, seconds } = convertTime(totalSeconds);
  return `${renderIsPlur(hours)}:${renderIsPlur(minutes)}:${renderIsPlur(
    seconds,
  )}`;
};

export const totalBilledTime = (report: PatientTimeReportFragment[] | null) => {
  return report
    ?.filter(item => item?.status)
    .reduce((acc, curr) => acc + (curr.second ? curr?.second : 0), 0);
};

export const chipNoteTypes = (noteTypes: PatientTimeReportFragment) => {
  return noteTypes?.patientNoteTypes && noteTypes?.patientNoteTypes?.length > 0
    ? noteTypes?.patientNoteTypes?.map(item => {
      const noteSelected = noteTypes?.patientNoteTypeSettings?.find(
        note => note?._id === item?.id?._id,
      );
      return (
        <Tooltip
          title={
            <Box display="flex" flexDirection="column">
              {generateInformation([
                {
                  name: 'Note Type',
                  info: noteSelected?.name || '',
                },
                {
                  name: 'Description',
                  info: item?.description || 'N/A',
                },
              ])}
            </Box>
          }
          key={item?.id?._id}
        >
          <ChipStyled
            key={item?.id?._id}
            className="m-4"
            label={noteSelected?.name}
            backgroundcolor={noteSelected?.color || DEFAULT_COLOR_TAG}
          />
        </Tooltip>
      );
    })
    : 'N/A';
};

export const dataByMonth = (data: PatientTimeReportFragment[]) => {
  const listByMonth: {
    [key: string]: PatientTimeReportFragment[];
  } = data.reduce(
    (report: { [key: string]: PatientTimeReportFragment[] }, item) => {
      const month = item.startedAt.substring(0, 7);
      if (!report[month]) {
        report[month] = [];
      }
      report[month].push(item);
      return report;
    },
    {},
  );
  return Object.values(listByMonth);
};

export const renderTotalRow = (
  month: string,
  data: PatientTimeReportFragment[],
  isBilling?: boolean,
) => {
  return (
    <TableRow style={{ backgroundColor: '#fafafa' }}>
      <TableCell></TableCell>
      <TableCell colSpan={isBilling ? 1 : 2}>
        <TypographyBold variant="subtitle2">{`Total ${month}`}</TypographyBold>
      </TableCell>
      <TableCell colSpan={5} align="left">
        <TypographyBold variant="subtitle2">
          {renderTime(
            data.reduce(
              (acc, curr) => acc + (curr.second ? curr?.second : 0),
              0,
            ),
          )}
        </TypographyBold>
      </TableCell>
    </TableRow>
  );
};
export const renderDataTotal = (
  data: PatientTimeReportFragment[],
  month: string,
) => {
  return {
    _id: month,
    totalLabel: `Total ${month}`,
    timeSpend: renderTime(
      data.reduce((acc, curr) => acc + (curr.second ? curr?.second : 0), 0),
    ),
  };
};

export const isTimeFormatValid = (time: string) => {
  const [hours, minutes] = time.split(':');
  const isValidTime =
    !isNaN(Number(hours)) &&
    Number(hours) <= 23 &&
    !isNaN(Number(minutes)) &&
    Number(minutes) <= 59;
  return isValidTime;
};

export const redirectAfterLogin = () => {
  if (Object.is(window.location.pathname, '/survey/')) {
    if (getSessionStorage('slug')) {
      return `/treatment/${getSessionStorage('slug')}`;
    }
    if (getSessionStorage('_id')) {
      return `/home/treatment/${getSessionStorage('_id')}`;
    }
    return '/';
  }
  return '/';
};

export const handleScrollClick = () =>
  window.scrollTo({ top: 0, behavior: 'smooth' });

export const chunkText = (str: any, size: any) => {
  const words = str.split(/\s+/);
  let chunks = [];
  for (let i = 0; i < words.length; i += size) {
    chunks.push(words.slice(i, i + size).join(' '));
  }
  return chunks;
};

export const cleanObject = (obj: any, removeKey: string): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => cleanObject(item, removeKey));
  } else if (obj && typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc: any, key: string) => {
      if (key !== removeKey) {
        acc[key] = cleanObject(obj[key], removeKey);
      }
      return acc;
    }, {});
  }
  return obj;
};

export const processContexts = (inputArray: string[]): any[] => {
  return inputArray.map(doc => {
    // Extracting the static data
    const titleMatch = doc.match(/'title':\s*'(.*?)'/);
    const title = titleMatch ? titleMatch[1] : '';

    // Extracting the last heading or section in the title
    const titleSections = title.split('-->');
    let lastTitleSection =
      titleSections.length > 0
        ? titleSections[titleSections.length - 1].trim()
        : '';

    // Removing markdown signs like #
    lastTitleSection = lastTitleSection.replace(/[#]/g, '').trim();

    const contentMatch = doc.match(
      /'content'\s*:\s*'<start:(\d+)>([\s\S]*?)<end:(\d+)>/,
    );
    const content = contentMatch ? contentMatch[2].trim() : '';
    const start = contentMatch ? contentMatch[1] : '';
    const end = contentMatch ? contentMatch[3] : '';

    const relevancyMatch = doc.match(/<{'relevancyScore':'([^']*)'}/);
    const relevancyScore = relevancyMatch ? relevancyMatch[1] : '';

    const slugMatch = doc.match(/<{'slug':'([^']*)'}/);
    const slug = slugMatch ? slugMatch[1] : '';

    return {
      title: lastTitleSection,
      content,
      start,
      end,
      relevancyScore,
      slug,
    };
  });
};
