import React, { useCallback, useRef, useState } from 'react';
import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import rrulePlugin from '@fullcalendar/rrule';
import listPlugin from '@fullcalendar/list';
import { RRule } from 'rrule';
import { GET_CALENDAR_CONFIG_INFO } from 'gql';
import {
  CalendarConfigFragment,
  ConfigType,
  GetCalendarConfigInfoQuery,
  GetCalendarConfigInfoQueryVariables,
  PatientFragment,
  ScheduleConfigEmbedded,
  TreatmentType,
} from 'types.d';
import {
  formatDate,
  generateInformation,
  getByNWeekDay,
  getGMTTime,
} from 'share/utils';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Popover,
  Tooltip,
} from '@material-ui/core';
import { ButtonLoading, DialogButton, DialogTitleClose } from 'components';
import { styled } from '@material-ui/core/styles';
import { useToogleDialog } from 'hooks';
import DialogSendSurvey from '../Survey/DialogSendSurvey';
import { TypoThreeDot } from 'share/component_css';
import format from 'date-fns/format';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

type Props = {
  openDialog: boolean;
  toogleDialog: (status: boolean) => void;
  patientDetail?: PatientFragment;
};

type CalendarConfig = {
  title: string;
  rrule: string;
};

const BoxStyled = styled(Box)(({ theme }) => ({
  '& .fc .fc-button': {
    backgroundColor: theme.palette.primary.light,
    border: 'none',
    '&:focus': {
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      border: 'none',
    },
  },
  '& .fc .fc-timegrid-event': {
    border: 'none',
  },
  '& .fc .fc-daygrid-event': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: 'none',
    margin: '2px',
  },
  '& .fc .fc-timegrid-slot-minor': {
    borderTopStyle: 'none',
  },
  '& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events': {
    minHeight: '5em',
  },
  '& .fc .fc-daygrid-body-natural .fc-daygrid-day-events': {
    minHeight: '1em !important',
  },
  '& .fc .fc-daygrid-more-link': {
    fontWeight: 'bold',
  },
  '& .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active': {
    background: theme.palette.primary.main,
    boxShadow: 'none',
  },
  '& .fc .fc-button-primary:disabled': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const BoxContenStyled = styled(Box)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

export const DialogCalendar: React.FC<Props> = ({
  openDialog,
  toogleDialog,
  patientDetail,
}) => {
  const [listData, setListData] = useState<CalendarConfig[]>([]);

  const event = useRef<EventClickArg | undefined>(undefined);

  const selectedDateCalendar = useRef<{ start?: Date; end?: Date }>({
    start: undefined,
    end: undefined,
  });

  const dateSelect = useRef<DateClickArg | undefined>(undefined);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const calendarRef = useRef<FullCalendar>(null!);

  const [isOpenUpdate, toogleDialogUpdate] = useToogleDialog();

  const tooglePopover = (ev: any) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderColorEvent = (item: CalendarConfigFragment) => {
    if (item?.task?.color) {
      return item?.task?.color;
    }
    if (item?.configType === ConfigType.Auto || item?.treatmentRegimenId) {
      return item?.survey?.type === TreatmentType.Treatment
        ? '#8BC6F3'
        : '#a3d4a5';
    }
    return item?.survey?.type === TreatmentType.Treatment
      ? '#1976d2'
      : '#4CAF50';
  };

  const renderStartDate = (data: CalendarConfigFragment) => {
    return new Date(data?.scheduleConfig?.dtstart);
  };

  const [getCalendarConfig] = useLazyQuery<
    GetCalendarConfigInfoQuery,
    GetCalendarConfigInfoQueryVariables
  >(GET_CALENDAR_CONFIG_INFO, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const list = data?.getCalendarConfigInfo?.nodes?.map(item => {
        const { count, bymonth, bymonthday } = item?.scheduleConfig;
        let defaultRule = {
          freq: item?.scheduleConfig?.freq || 0,
          interval: item?.scheduleConfig?.interval || 0,
          dtstart: renderStartDate(item),
          byweekday: item?.scheduleConfig?.byNweekDay
            ? [
                getByNWeekDay(
                  item?.scheduleConfig?.byNweekDay?.weekday || '',
                ).nth(item?.scheduleConfig?.byNweekDay?.n || 0),
              ]
            : item?.scheduleConfig?.byweekday,
          count,
          bymonthday,
          bymonth,
        } as ScheduleConfigEmbedded;
        if (item?.scheduleConfig?.until) {
          defaultRule.until = item?.scheduleConfig?.until;
        }
        const eachRule = new RRule(defaultRule);
        return {
          title: `${item?.survey?.name || item?.task?.name}` || '',
          id: item?.survey?._id,
          rrule: eachRule.toString(),
          textColor: RRule.fromString(eachRule.toString()).toText(),
          backgroundColor: renderColorEvent(item),
          extendedProps: item,
          display: 'block',
        };
      });
      setListData(list);
    },
  });

  const renderContentEvent = (eventInfo: any) => {
    const configDate = eventInfo?.event?.extendedProps?.lastSendTime;
    return (
      <BoxContenStyled className="cursor-pointer ml-4">
        {eventInfo.view.type === 'dayGridMonth' ? (
          <Box display="flex" alignItems="center">
            {configDate && <CheckCircleOutlineIcon fontSize="small" />}
            <TypoThreeDot variant="caption" style={{ marginTop: '2px' }}>
              {`${format(eventInfo.event.start, 'hh:mm a')}, ${
                eventInfo.event.title
              }`}
            </TypoThreeDot>
          </Box>
        ) : (
          <>
            <Box display="flex">
              {configDate && <CheckCircleOutlineIcon fontSize="small" />}
              <TypoThreeDot
                style={{ marginTop: '2px', marginLeft: '2px' }}
                variant="caption"
              >{`${eventInfo.event.title}`}</TypoThreeDot>
            </Box>
            <TypoThreeDot variant="caption">
              {formatDate(eventInfo.event.start, true, true)}
            </TypoThreeDot>
          </>
        )}
      </BoxContenStyled>
    );
  };

  const renderEventContent = (eventInfo: any) => {
    const task = eventInfo?.event?.extendedProps?.task;
    return task ? (
      <Tooltip
        title={
          <Box display="flex" flexDirection="column">
            {generateInformation([
              {
                name: 'Description',
                info: eventInfo?.event?.extendedProps?.taskDesc || 'N/A',
              },
              {
                name: 'Task Type',
                info: task.name || 'N/A',
              },
              {
                name: 'Task Date',
                info: eventInfo?.event?.start
                  ? formatDate(eventInfo?.event?.start)
                  : 'N/A',
              },
              {
                name: 'Created Date',
                info: eventInfo?.event?.start
                  ? formatDate(eventInfo?.event?.start)
                  : 'N/A',
              },
              {
                name: 'Status',
                info: 'Open',
              },
            ])}
          </Box>
        }
      >
        {renderContentEvent(eventInfo)}
      </Tooltip>
    ) : (
      renderContentEvent(eventInfo)
    );
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    event.current = clickInfo;
    dateSelect.current = undefined;
    toogleDialogUpdate(true);
  };

  const handleDateClick = (date: DateClickArg) => {
    dateSelect.current = date;
    event.current = undefined;
    toogleDialogUpdate(true);
  };

  const getGMTTimeText = () => {
    return `GMT${getGMTTime().offsetOperator}${getGMTTime().offsetHours}`;
  };

  const getCalendarConfigs = () => {
    getCalendarConfig({
      variables: {
        params: {
          patientId: patientDetail?._id,
          startDate: selectedDateCalendar.current.start as Date,
          endDate: selectedDateCalendar.current.end as Date,
        },
      },
    });
  };

  const changeView = (view: string) => {
    const { current: calendarDom } = calendarRef;
    calendarDom.getApi().changeView(view);
    handleClosePopover();
  };

  return (
    <>
      {isOpenUpdate && (
        <DialogSendSurvey
          open={isOpenUpdate}
          toogleDialog={toogleDialogUpdate}
          currentEvent={event.current}
          dateSelect={dateSelect.current}
          getCalendarConfigs={getCalendarConfigs}
        />
      )}
      <Popover
        id={id}
        open={open}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display="grid" gridRowGap={4} m={1}>
          <ButtonLoading
            flexStart
            callbackClick={() => changeView('listWeek')}
            color="primary"
            text="In Week"
            variant="outlined"
          />
          <ButtonLoading
            flexStart
            callbackClick={() => changeView('listMonth')}
            color="primary"
            text="In Month"
            variant="outlined"
          />
          <ButtonLoading
            flexStart
            callbackClick={() => changeView('listYear')}
            color="primary"
            text="In Year"
            variant="outlined"
          />
        </Box>
      </Popover>
      <Dialog open={openDialog} maxWidth="lg" fullWidth>
        <DialogTitleClose
          title="Calendar Management"
          onClose={() => toogleDialog(false)}
        />
        <DialogContent>
          <BoxStyled>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                rrulePlugin,
                listPlugin,
              ]}
              customButtons={{
                listDataButton: {
                  text: 'List',
                  click: e => tooglePopover(e),
                },
              }}
              dayMaxEvents={3}
              initialView="timeGridWeek"
              themeSystem="bootstrap"
              weekends={true}
              events={listData}
              eventContent={renderEventContent}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay listDataButton',
              }}
              buttonText={{
                today: 'Today',
                week: 'Week',
                day: 'Day',
                month: 'Month',
              }}
              allDayText={getGMTTimeText()}
              datesSet={({ start, end }) => {
                getCalendarConfig({
                  variables: {
                    params: {
                      patientId: patientDetail?._id,
                      startDate: start,
                      endDate: end,
                    },
                  },
                });
                selectedDateCalendar.current = { start, end };
              }}
              eventClick={handleEventClick}
              ref={calendarRef}
              dateClick={handleDateClick}
            />
          </BoxStyled>
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={() => toogleDialog(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogCalendar);
